/* eslint-disable */
const DEFS = {
  "pageDataKey": "interventionCodes",
  "pIndex": "38",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "interventionCodes.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "interventionCodes.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "interventionCodes.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "interventionCodes.time"
    },
    {
      "elementKey": "intervention",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "interventionCodes.intervention"
    },
    {
      "elementKey": "status",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "interventionCodes.status"
    },
    {
      "elementKey": "laterally",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "interventionCodes.laterally"
    },
    {
      "elementKey": "extent",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "interventionCodes.extent"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "interventionCodes",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Intervention codes",
        "addButtonText": "Add an intervention code",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "interventionCodes.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup38-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "interventionCodes.cGroup38-2",
            "gIndex": "2",
            "gChildren": [
              "intervention",
              "status",
              "laterally",
              "extent"
            ]
          },
          {
            "elementKey": "label",
            "formCss": "grid-left-to-right-3",
            "fqn": "interventionCodes.label",
            "gIndex": "3",
            "gChildren": []
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "intervention": "",
          "status": "",
          "laterally": "",
          "extent": ""
        }
      },
      "fqn": "interventionCodes.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "intervention",
        "status",
        "laterally",
        "extent",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS