/* eslint-disable */
const DEFS = {
  "pageDataKey": "newBornFeeding",
  "pIndex": "63",
  "hasGridTable": true,
  "featureFlag": "ffNB",
  "pageChildren": [
    {
      "elementKey": "neoFeed_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "142",
      "recHeader": true,
      "fqn": "newBornFeeding.name"
    },
    {
      "elementKey": "neoFeed_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "neoFeed_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "142",
      "recHeader": true,
      "fqn": "newBornFeeding.profession"
    },
    {
      "elementKey": "neoFeed_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "142",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newBornFeeding.day"
    },
    {
      "elementKey": "neoFeed_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "142",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newBornFeeding.time"
    },
    {
      "elementKey": "neoFeedGoals",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "exclusive",
          "text": "Exclusive breast/chest milk"
        },
        {
          "key": "mixed",
          "text": "Mixed feeding"
        },
        {
          "key": "formula",
          "text": "Formula"
        }
      ],
      "tableColumn": "144",
      "fqn": "newBornFeeding.neoFeedGoals"
    },
    {
      "elementKey": "neoFeedCues",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "awake",
          "text": "Awake independently"
        },
        {
          "key": "sucking",
          "text": "Sucking"
        },
        {
          "key": "woken",
          "text": "Woken for feed"
        },
        {
          "key": "rooting",
          "text": "Rooting"
        },
        {
          "key": "sleepy",
          "text": "Sleepy/sleeping"
        },
        {
          "key": "no cues",
          "text": "No feeding cues"
        },
        {
          "key": "stirring",
          "text": "Stirring"
        },
        {
          "key": "licking",
          "text": "Licking"
        },
        {
          "key": "crying",
          "text": "Crying"
        },
        {
          "key": "fussing",
          "text": "Fussing"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "146",
      "fqn": "newBornFeeding.neoFeedCues"
    },
    {
      "elementKey": "neoFeedCuesOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "147",
      "fqn": "newBornFeeding.neoFeedCuesOther"
    },
    {
      "elementKey": "neoFeedPosition",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "skin",
          "text": "skin to skin"
        },
        {
          "key": "cradle",
          "text": "cradle"
        },
        {
          "key": "cross cradle",
          "text": "cross cradle"
        },
        {
          "key": "football",
          "text": "football"
        },
        {
          "key": "side",
          "text": "side lying"
        },
        {
          "key": "tandem",
          "text": "tandem"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "149",
      "fqn": "newBornFeeding.neoFeedPosition"
    },
    {
      "elementKey": "neoFeedPositionOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "150",
      "fqn": "newBornFeeding.neoFeedPositionOther"
    },
    {
      "elementKey": "neoFeedObserv",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "good",
          "text": "good latch"
        },
        {
          "key": "poor",
          "text": "poor latch"
        },
        {
          "key": "no",
          "text": "no latch"
        },
        {
          "key": "strong",
          "text": "strong suck"
        },
        {
          "key": "moderate",
          "text": "moderate suck"
        },
        {
          "key": "weak",
          "text": "weak suck"
        },
        {
          "key": "audible",
          "text": "audible swallow"
        },
        {
          "key": "licking",
          "text": "licking/muzzling"
        },
        {
          "key": "no swallow",
          "text": "no swallow"
        },
        {
          "key": "nipple",
          "text": "nipple shield"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "151",
      "fqn": "newBornFeeding.neoFeedObserv"
    },
    {
      "elementKey": "neoFeedObservOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "152",
      "fqn": "newBornFeeding.neoFeedObservOther"
    },
    {
      "elementKey": "neoFeedBreast",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "byNurse",
          "text": "observed by nurse"
        },
        {
          "key": "byParents",
          "text": "observed by parents"
        }
      ],
      "tableColumn": "153",
      "fqn": "newBornFeeding.neoFeedBreast"
    },
    {
      "elementKey": "neoFeedBreastDuration",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "min",
      "tableColumn": "154",
      "fqn": "newBornFeeding.neoFeedBreastDuration",
      "suffixText": "min"
    },
    {
      "elementKey": "neoFeedBottle",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "strong",
          "text": "strong suck"
        },
        {
          "key": "weak",
          "text": "weak suck"
        },
        {
          "key": "moderate",
          "text": "moderate suck"
        },
        {
          "key": "uncoordinated",
          "text": "uncoordinated suck"
        },
        {
          "key": "selfPacing",
          "text": "self pacing"
        },
        {
          "key": "external",
          "text": "needed external pacing"
        },
        {
          "key": "pooling",
          "text": "pooling/spilling"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "156",
      "fqn": "newBornFeeding.neoFeedBottle"
    },
    {
      "elementKey": "neoFeedBottleOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "157",
      "fqn": "newBornFeeding.neoFeedBottleOther"
    },
    {
      "elementKey": "neoFeedBottleVol",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "ml",
      "tableColumn": "158",
      "fqn": "newBornFeeding.neoFeedBottleVol",
      "suffixText": "ml"
    },
    {
      "elementKey": "neoFeedBottleDur",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "min",
      "tableColumn": "159",
      "fqn": "newBornFeeding.neoFeedBottleDur",
      "suffixText": "min"
    },
    {
      "elementKey": "neoFeedFormula",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "160",
      "fqn": "newBornFeeding.neoFeedFormula"
    },
    {
      "elementKey": "neoFeedInstructions",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "161",
      "fqn": "newBornFeeding.neoFeedInstructions"
    },
    {
      "elementKey": "neoFeedProvidedBy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "nurse",
          "text": "nurse"
        },
        {
          "key": "parent",
          "text": "parent"
        },
        {
          "key": "caregiver",
          "text": "caregiver"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "163",
      "fqn": "newBornFeeding.neoFeedProvidedBy"
    },
    {
      "elementKey": "neoFeedSupport",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "none"
        },
        {
          "key": "minimal",
          "text": "minimal assistance"
        },
        {
          "key": "full",
          "text": "full assistance"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "164",
      "fqn": "newBornFeeding.neoFeedSupport"
    },
    {
      "elementKey": "neoFeedTolerance",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "165",
      "fqn": "newBornFeeding.neoFeedTolerance"
    },
    {
      "elementKey": "neoFeedCarePlan",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "166",
      "fqn": "newBornFeeding.neoFeedCarePlan"
    },
    {
      "elementKey": "neoFeedComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "168",
      "fqn": "newBornFeeding.neoFeedComments"
    }
  ],
  "pageElements": {
    "neoFeed_form": {
      "elementKey": "neoFeed_form",
      "pageElementIndex": "1",
      "pageKey": "newBornFeeding",
      "tableKey": "neoFeed_form",
      "isTable": true,
      "form": {
        "elementKey": "neoFeed_form",
        "label": "Feeding",
        "addButtonText": "Add Feeding Assessment",
        "formKey": "neoFeed_form",
        "ehr_groups": [
          {
            "elementKey": "neoFeed_hdrGroup",
            "formCss": "record-header",
            "fqn": "newBornFeeding.neoFeed_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "neoFeed_form_name",
              "neoFeed_form_profession",
              "neoFeed_form_day",
              "neoFeed_form_time"
            ]
          },
          {
            "elementKey": "neoFeed_1",
            "formCss": "grid-left-to-right-3",
            "fqn": "newBornFeeding.neoFeed_1",
            "gIndex": "2",
            "gChildren": [
              "neoFeedGoals"
            ]
          },
          {
            "elementKey": "neoFeed_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "newBornFeeding.neoFeed_2",
            "gIndex": "3",
            "gChildren": [
              "neoFeedCues",
              "neoFeedCuesOther"
            ]
          },
          {
            "elementKey": "neoFeed_4",
            "formCss": "grid-left-to-right-3",
            "fqn": "newBornFeeding.neoFeed_4",
            "gIndex": "4",
            "gChildren": [
              "neoFeedPosition",
              "neoFeedPositionOther",
              "neoFeedObserv",
              "neoFeedObservOther",
              "neoFeedBreast",
              "neoFeedBreastDuration"
            ]
          },
          {
            "elementKey": "neoFeed_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "newBornFeeding.neoFeed_5",
            "gIndex": "5",
            "gChildren": [
              "neoFeedBottle",
              "neoFeedBottleOther",
              "neoFeedBottleVol",
              "neoFeedBottleDur",
              "neoFeedFormula",
              "neoFeedInstructions"
            ]
          },
          {
            "elementKey": "neoFeed_6",
            "formCss": "grid-left-to-right-3",
            "fqn": "newBornFeeding.neoFeed_6",
            "gIndex": "6",
            "gChildren": [
              "neoFeedProvidedBy",
              "neoFeedSupport",
              "neoFeedTolerance",
              "neoFeedCarePlan"
            ]
          },
          {
            "elementKey": "neoFeed_7",
            "formCss": "grid-left-to-right-1",
            "fqn": "newBornFeeding.neoFeed_7",
            "gIndex": "7",
            "gChildren": [
              "neoFeedComments"
            ]
          }
        ],
        "ehr_data": {
          "neoFeed_form_name": "",
          "neoFeed_form_profession": "",
          "neoFeed_form_day": "",
          "neoFeed_form_time": "",
          "neoFeedGoals": "",
          "neoFeedCues": "",
          "neoFeedCuesOther": "",
          "neoFeedPosition": "",
          "neoFeedPositionOther": "",
          "neoFeedObserv": "",
          "neoFeedObservOther": "",
          "neoFeedBreast": "",
          "neoFeedBreastDuration": "",
          "neoFeedBottle": "",
          "neoFeedBottleOther": "",
          "neoFeedBottleVol": "",
          "neoFeedBottleDur": "",
          "neoFeedFormula": "",
          "neoFeedInstructions": "",
          "neoFeedProvidedBy": "",
          "neoFeedSupport": "",
          "neoFeedTolerance": "",
          "neoFeedCarePlan": "",
          "neoFeedComments": ""
        }
      },
      "fqn": "newBornFeeding.neoFeed_form",
      "tableChildren": [
        "neoFeed_form_id",
        "neoFeed_form_day",
        "neoFeed_form_time",
        "neoFeedGoals",
        "neoFeedCues",
        "neoFeedCuesOther",
        "neoFeedPosition",
        "neoFeedPositionOther",
        "neoFeedObserv",
        "neoFeedObservOther",
        "neoFeedBreast",
        "neoFeedBreastDuration",
        "neoFeedBottle",
        "neoFeedBottleOther",
        "neoFeedBottleVol",
        "neoFeedBottleDur",
        "neoFeedFormula",
        "neoFeedInstructions",
        "neoFeedProvidedBy",
        "neoFeedSupport",
        "neoFeedTolerance",
        "neoFeedCarePlan",
        "neoFeedComments",
        "neoFeed_form_name",
        "neoFeed_form_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS