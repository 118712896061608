/* eslint-disable */
const DEFS = {
  "pageDataKey": "specialIndicators",
  "pIndex": "58",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "precautionList_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "specialIndicators.name"
    },
    {
      "elementKey": "precautionList_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "precautionList_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "specialIndicators.profession"
    },
    {
      "elementKey": "precautionList_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "specialIndicators.day"
    },
    {
      "elementKey": "precautionList_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "specialIndicators.time"
    },
    {
      "elementKey": "precautionIndicator",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Airborne",
          "text": "Airborne Precautions"
        },
        {
          "key": "Contact",
          "text": "Contact Precautions"
        },
        {
          "key": "Droplet",
          "text": "Droplet Precautions"
        },
        {
          "key": "DC",
          "text": "Droplet/Contact Precautions"
        },
        {
          "key": "Routine",
          "text": "Routine Precautions"
        }
      ],
      "tableColumn": "2",
      "fqn": "specialIndicators.precautionIndicator"
    },
    {
      "elementKey": "precautionComment",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "specialIndicators.precautionComment"
    },
    {
      "elementKey": "riskList_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "specialIndicators.name"
    },
    {
      "elementKey": "riskList_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "riskList_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "specialIndicators.profession"
    },
    {
      "elementKey": "riskList_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "specialIndicators.day"
    },
    {
      "elementKey": "riskList_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "specialIndicators.time"
    },
    {
      "elementKey": "riskIndicator",
      "formIndex": "2",
      "formCss": "form_risk_indicator",
      "inputType": "checkset",
      "options": [
        {
          "key": "AVB",
          "text": "AVB (Aggressive Violent Behaviour"
        },
        {
          "key": "ARO",
          "text": "ARO (Antibiotic Resistant Organism)"
        },
        {
          "key": "DNA",
          "text": "DNA (Do Not Acknowledge)"
        }
      ],
      "tableColumn": "2",
      "fqn": "specialIndicators.riskIndicator"
    },
    {
      "elementKey": "riskComment",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "specialIndicators.riskComment"
    }
  ],
  "pageElements": {
    "precautionList": {
      "elementKey": "precautionList",
      "pageElementIndex": "1",
      "pageKey": "specialIndicators",
      "tableKey": "precautionList",
      "isTable": true,
      "form": {
        "elementKey": "precautionList",
        "label": "Precautions",
        "addButtonText": "Add an precaution",
        "formKey": "precautionList",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "specialIndicators.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "precautionList_name",
              "precautionList_profession",
              "precautionList_day",
              "precautionList_time"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "specialIndicators.cGroup",
            "gIndex": "2",
            "gChildren": [
              "precautionIndicator",
              "precautionComment"
            ]
          }
        ],
        "ehr_data": {
          "precautionList_name": "",
          "precautionList_profession": "",
          "precautionList_day": "",
          "precautionList_time": "",
          "precautionIndicator": "",
          "precautionComment": ""
        }
      },
      "fqn": "specialIndicators.precautionList",
      "tableChildren": [
        "precautionList_id",
        "precautionList_day",
        "precautionList_time",
        "precautionIndicator",
        "precautionComment",
        "precautionList_name",
        "precautionList_profession"
      ],
      "hasRecHeader": true
    },
    "riskList": {
      "elementKey": "riskList",
      "pageElementIndex": "2",
      "pageKey": "specialIndicators",
      "tableKey": "riskList",
      "isTable": true,
      "form": {
        "elementKey": "riskList",
        "label": "Indicators",
        "addButtonText": "Add an indicator",
        "formKey": "riskList",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "specialIndicators.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "riskList_name",
              "riskList_profession",
              "riskList_day",
              "riskList_time"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "specialIndicators.cGroup",
            "gIndex": "2",
            "gChildren": [
              "riskIndicator",
              "riskComment"
            ]
          }
        ],
        "ehr_data": {
          "riskList_name": "",
          "riskList_profession": "",
          "riskList_day": "",
          "riskList_time": "",
          "riskIndicator": "",
          "riskComment": ""
        }
      },
      "fqn": "specialIndicators.riskList",
      "tableChildren": [
        "riskList_id",
        "riskList_day",
        "riskList_time",
        "riskIndicator",
        "riskComment",
        "riskList_name",
        "riskList_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS