/* eslint-disable */
const DEFS = {
  "pageDataKey": "codeLookup",
  "pIndex": "36",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "snomed",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "codeLookup.snomed"
    },
    {
      "elementKey": "icf",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "codeLookup.icf"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "codeLookup.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup36-1",
          "formCss": "grid-left-to-right-3",
          "fqn": "codeLookup.cGroup36-1",
          "gIndex": "1",
          "gChildren": [
            "snomed",
            "icf"
          ]
        }
      ]
    }
  }
}
export default DEFS