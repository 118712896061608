/* eslint-disable */
const DEFS = {
  "pageDataKey": "gastrointestinal",
  "pIndex": "17",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "gastrointestinal.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "gastrointestinal.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "gastrointestinal.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "gastrointestinal.time"
    },
    {
      "elementKey": "bowel",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "normal",
          "text": "Patient’s pre-hospital baseline"
        },
        {
          "key": "constipation",
          "text": "Constipation"
        },
        {
          "key": "diarrhea",
          "text": "Diarrhea"
        },
        {
          "key": "melena",
          "text": "Melena"
        },
        {
          "key": "incontinent",
          "text": "Incontinent"
        }
      ],
      "tableColumn": "2",
      "fqn": "gastrointestinal.bowel"
    },
    {
      "elementKey": "bSounds",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "luq",
          "text": "LUQ"
        },
        {
          "key": "ruq",
          "text": "RUQ"
        },
        {
          "key": "llq",
          "text": "LLQ"
        },
        {
          "key": "rlq",
          "text": "RLQ"
        },
        {
          "key": "hypoactive",
          "text": "Hypoactive"
        },
        {
          "key": "hyperactive",
          "text": "Hyperactive"
        }
      ],
      "tableColumn": "3",
      "fqn": "gastrointestinal.bSounds"
    },
    {
      "elementKey": "condition",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "soft",
          "text": "Soft"
        },
        {
          "key": "tender",
          "text": "Tender"
        },
        {
          "key": "rigid",
          "text": "Rigid"
        },
        {
          "key": "guarding",
          "text": "Guarding"
        },
        {
          "key": "distended",
          "text": "Distended"
        },
        {
          "key": "scars",
          "text": "Scars"
        }
      ],
      "tableColumn": "4",
      "fqn": "gastrointestinal.condition"
    },
    {
      "elementKey": "spacer1",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "gastrointestinal.spacer1"
    },
    {
      "elementKey": "spacer2",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "gastrointestinal.spacer2"
    },
    {
      "elementKey": "pain",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "5",
      "fqn": "gastrointestinal.pain"
    },
    {
      "elementKey": "spacer3",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "gastrointestinal.spacer3"
    },
    {
      "elementKey": "spacer4",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "gastrointestinal.spacer4"
    },
    {
      "elementKey": "instructionLabel",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "gastrointestinal.instructionLabel"
    },
    {
      "elementKey": "emesisPresent",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "None",
          "text": "None"
        },
        {
          "key": "Nausea",
          "text": "Nausea"
        },
        {
          "key": "Vomiting",
          "text": "Vomiting"
        }
      ],
      "tableColumn": "6",
      "fqn": "gastrointestinal.emesisPresent"
    },
    {
      "elementKey": "colour",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Greenish-yellow",
          "text": "Greenish-yellow"
        },
        {
          "key": "Blood-tinged",
          "text": "Blood-tinged"
        },
        {
          "key": "Bright red",
          "text": "Bright red"
        },
        {
          "key": "Dark red",
          "text": "Dark red"
        },
        {
          "key": "Black",
          "text": "Black"
        }
      ],
      "tableColumn": "7",
      "fqn": "gastrointestinal.colour"
    },
    {
      "elementKey": "amount",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Small",
          "text": "Small"
        },
        {
          "key": "Moderate",
          "text": "Moderate"
        },
        {
          "key": "Large",
          "text": "Large"
        }
      ],
      "tableColumn": "8",
      "fqn": "gastrointestinal.amount"
    },
    {
      "elementKey": "description",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "9",
      "fqn": "gastrointestinal.description"
    },
    {
      "elementKey": "eating",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Regular",
          "text": "Regular"
        },
        {
          "key": "Difficult",
          "text": "Difficult"
        }
      ],
      "tableColumn": "10",
      "fqn": "gastrointestinal.eating"
    },
    {
      "elementKey": "reason",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "gastrointestinal.reason"
    },
    {
      "elementKey": "spacer5",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "gastrointestinal.spacer5"
    },
    {
      "elementKey": "npoSinceDay",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "12",
      "validation": "visitDay",
      "fqn": "gastrointestinal.npoSinceDay"
    },
    {
      "elementKey": "npoSinceTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "13",
      "validation": "time24",
      "fqn": "gastrointestinal.npoSinceTime"
    },
    {
      "elementKey": "weightLoss",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "gastrointestinal.weightLoss"
    },
    {
      "elementKey": "lastBm",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "gastrointestinal.lastBm"
    },
    {
      "elementKey": "stoolColour",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Brown",
          "text": "Brown"
        },
        {
          "key": "Green",
          "text": "Green"
        },
        {
          "key": "Clay coloured",
          "text": "Clay coloured"
        },
        {
          "key": "Yellow",
          "text": "Yellow"
        },
        {
          "key": "Black",
          "text": "Black"
        },
        {
          "key": "Bright red",
          "text": "Bright red"
        },
        {
          "key": "Dark red",
          "text": "Dark red"
        }
      ],
      "tableColumn": "16",
      "fqn": "gastrointestinal.stoolColour"
    },
    {
      "elementKey": "stoolDescription",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Runny",
          "text": "Runny"
        },
        {
          "key": "Hard pellets",
          "text": "Hard pellets"
        }
      ],
      "tableColumn": "17",
      "fqn": "gastrointestinal.stoolDescription"
    },
    {
      "elementKey": "stoolAmount",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "gastrointestinal.stoolAmount"
    },
    {
      "elementKey": "stoolSource",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Observed",
          "text": "Observed"
        },
        {
          "key": "As per patient observed",
          "text": "As per patient observed"
        },
        {
          "key": "Not observed",
          "text": "Not observed"
        }
      ],
      "tableColumn": "19",
      "fqn": "gastrointestinal.stoolSource"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "embedRef": ".",
      "inputType": "textarea",
      "tableColumn": "20",
      "fqn": "gastrointestinal.comments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "gastrointestinal",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Gastrointestinal assessment",
        "addButtonText": "Add a gastrointestinal assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "gastrointestinal.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "gasGroup1",
            "formCss": "grid-left-to-right-3",
            "fqn": "gastrointestinal.gasGroup1",
            "gIndex": "2",
            "gChildren": [
              "bowel",
              "bSounds",
              "condition",
              "spacer1",
              "spacer2",
              "pain",
              "spacer3",
              "spacer4",
              "instructionLabel"
            ]
          },
          {
            "elementKey": "emesis",
            "formCss": "grid-left-to-right-3",
            "fqn": "gastrointestinal.emesis",
            "gIndex": "3",
            "gChildren": [
              "emesisPresent",
              "colour",
              "amount",
              "description"
            ]
          },
          {
            "elementKey": "eatingGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "gastrointestinal.eatingGroup",
            "gIndex": "4",
            "gChildren": [
              "eating",
              "reason",
              "spacer5",
              "npoSinceDay",
              "npoSinceTime",
              "weightLoss"
            ]
          },
          {
            "elementKey": "stool",
            "formCss": "grid-left-to-right-3",
            "fqn": "gastrointestinal.stool",
            "gIndex": "5",
            "gChildren": [
              "lastBm",
              "stoolColour",
              "stoolDescription",
              "stoolAmount",
              "stoolSource"
            ]
          },
          {
            "elementKey": "gasComments",
            "formCss": "grid-left-to-right-1 section-divider",
            "fqn": "gastrointestinal.gasComments",
            "gIndex": "6",
            "gChildren": [
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "bowel": "",
          "bSounds": "",
          "condition": "",
          "pain": "",
          "emesisPresent": "",
          "colour": "",
          "amount": "",
          "description": "",
          "eating": "",
          "reason": "",
          "npoSinceDay": "",
          "npoSinceTime": "",
          "weightLoss": "",
          "lastBm": "",
          "stoolColour": "",
          "stoolDescription": "",
          "stoolAmount": "",
          "stoolSource": "",
          "comments": ""
        }
      },
      "fqn": "gastrointestinal.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "bowel",
        "bSounds",
        "condition",
        "pain",
        "emesisPresent",
        "colour",
        "amount",
        "description",
        "eating",
        "reason",
        "npoSinceDay",
        "npoSinceTime",
        "weightLoss",
        "lastBm",
        "stoolColour",
        "stoolDescription",
        "stoolAmount",
        "stoolSource",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS