/* eslint-disable */
const DEFS = [
  "acpMost",
  "allergies",
  "assessmentTools",
  "billing",
  "biopsychosocial",
  "cardiovascular",
  "carePlan",
  "careTeam",
  "caseMixGroup",
  "checklistsPage",
  "codeLookup",
  "consentsPage",
  "consults",
  "demographics",
  "diagnosticCodes",
  "dischargeSummary",
  "dishargeplan",
  "familyHistory",
  "fluidBalance",
  "gastrointestinal",
  "genitourinary",
  "hematology",
  "immunization",
  "integumentaryAssessment",
  "interventionCodes",
  "labRequisitions",
  "labResults",
  "leveyJennings",
  "linesDevices",
  "medAdminRec",
  "medLabAccession",
  "medLabBody",
  "medLabChemistry",
  "medLabPhoneLog",
  "medLabUrinalysis",
  "medical",
  "medicalImaging",
  "medicationOrders",
  "musculoskeletal",
  "neurological",
  "newBorn",
  "newBornFeeding",
  "newBornRec",
  "nonmedOrders",
  "operationReports",
  "orderSets",
  "pain",
  "pastAppointments",
  "periPed",
  "progressNotes",
  "psychosocial",
  "referrals",
  "respiratory",
  "riskAssessment",
  "specialIndicators",
  "surgical",
  "visit",
  "vitals"
]
export default DEFS