import InstoreHelper from '@/store/modules/instoreHelper'
import { Text } from '@/helpers/ehr-text'
import StoreHelper from '@/helpers/store-helper'
const NAME = 'AdminStore'
const state = {
  consumerId: undefined, // the consumer the user with admin rights is viewing
  adminActivities: [],
  consumerRecord: {},
  visitList: [],

}

const getters = {
  consumerId: state => state.consumerId,
  consumerRecord: state => state.consumerRecord,
  adminActivities: state => state.adminActivities,
  getVisitList: state => { return state.visitList },
}

const actions = {
  setConsumerId: async function (context, id) {
    console.log('Set consumer id for admin user', id)
    context.commit('_setConsumerId', id)
    await context.dispatch('loadAdminConsumer', id)
  },
  loadAdminConsumer (context, id) {
    console.log('api call to get consumer for admin user')
    const API = 'consumers'
    const OBJ = 'consumer'
    let url = 'get/' + id + '/details'
    return InstoreHelper.getRequest(context, API, url).then(response => {
      let results = response.data[OBJ]
      if (!results) {
        let msg = Text.CANNOT_GET_CONSUMER_STORE(NAME, id)
        StoreHelper.setApiError(msg)
      }
      console.log('Set consumer record for admin user', results)
      context.commit('_setConsumerRecord', results)
    })
  },
  loadAdminActivities (context, consumerId) {
    if (!consumerId) {
      console.log('loadAdminActivities. Must provide consumer id')
      return
    }
    // console.log('seedListStore. Fetch seed list')
    const API = 'activities'
    let url = 'admin-activities/' + consumerId
    return InstoreHelper.getRequest(context, API, url).then(response => {
      let list = response.data.activities
      if (!list) {
        console.error('ERROR the system should have activities')
        return
      }
      console.log('load Admin Activities results', list)
      context.commit('_setAdminActivities', list)
      return list
    })
  },
  loadUserVisits (context, userId) {
    const API = 'visits'
    if (userId) {
      let url = 'user/' + userId
      return InstoreHelper.getRequest(context, API, url).then(response => {
        console.log('loadVisit what is the response? ', response.data)
        let results = response.data
        context.commit('_setVisitList', results)
      })
    } else {
      context.commit('_setVisitList', [])
    }
  }
}

const mutations = {
  _setConsumerId (state, id) {
    state.consumerId = id
  },
  _setConsumerRecord (state, consumer) {
    state.consumerRecord = consumer
  },
  _setAdminActivities: ( state, list ) => {
    state.adminActivities = list
  },
  _setVisitList: (state, list) => {
    state.visitList = list
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
