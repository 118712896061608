/* eslint-disable */
const DEFS = {
  "pageDataKey": "medical",
  "pIndex": "3",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "history",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "medical.history"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "medical.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup",
          "formCss": "grid-left-to-right-1",
          "fqn": "medical.cGroup",
          "gIndex": "1",
          "gChildren": [
            "history"
          ]
        }
      ]
    }
  }
}
export default DEFS