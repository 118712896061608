/* eslint-disable */
const DEFS = {
  "pageDataKey": "medicationOrders",
  "pIndex": "25",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medicationOrders.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medicationOrders.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medicationOrders.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medicationOrders.time"
    },
    {
      "elementKey": "medication",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "lookahead",
      "tableColumn": "2",
      "fqn": "medicationOrders.medication"
    },
    {
      "elementKey": "dose",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medicationOrders.dose"
    },
    {
      "elementKey": "route",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Oral",
          "text": "Oral"
        },
        {
          "key": "Inhaler",
          "text": "Inhaler"
        },
        {
          "key": "Intravenous",
          "text": "Intravenous"
        },
        {
          "key": "Intramuscular",
          "text": "Intramuscular"
        },
        {
          "key": "Nasal",
          "text": "Nasal"
        },
        {
          "key": "Nebulized",
          "text": "Nebulized"
        },
        {
          "key": "Subcutaneous",
          "text": "Subcutaneous"
        },
        {
          "key": "Sublingual",
          "text": "Sublingual"
        },
        {
          "key": "Rectal",
          "text": "Rectal"
        },
        {
          "key": "Topical",
          "text": "Topical"
        },
        {
          "key": "Vaginal",
          "text": "Vaginal"
        }
      ],
      "tableColumn": "4",
      "fqn": "medicationOrders.route"
    },
    {
      "elementKey": "startMeds",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "5",
      "validation": "visitDay",
      "fqn": "medicationOrders.startMeds"
    },
    {
      "elementKey": "endMeds",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "6",
      "validation": "visitDay",
      "fqn": "medicationOrders.endMeds"
    },
    {
      "elementKey": "administration",
      "defaultValue": "sched",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "sched",
          "text": "Scheduled"
        },
        {
          "key": "prn",
          "text": "PRN (as needed)"
        },
        {
          "key": "once",
          "text": "Once"
        },
        {
          "key": "stat",
          "text": "Stat"
        },
        {
          "key": "od",
          "text": "OD (Once a Day)"
        },
        {
          "key": "set",
          "text": "Set times"
        }
      ],
      "tableColumn": "7",
      "fqn": "medicationOrders.administration"
    },
    {
      "elementKey": "scheduled",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "BID / q12h",
          "text": "BID / q12h"
        },
        {
          "key": "TID",
          "text": "TID"
        },
        {
          "key": "q8h",
          "text": "q8h"
        },
        {
          "key": "QID",
          "text": "QID"
        },
        {
          "key": "q6h",
          "text": "q6h"
        },
        {
          "key": "q4h",
          "text": "q4h"
        }
      ],
      "tableColumn": "9",
      "fqn": "medicationOrders.scheduled"
    },
    {
      "elementKey": "prn1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "validation": "time24",
      "fqn": "medicationOrders.prn1"
    },
    {
      "elementKey": "prn2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "validation": "time24",
      "fqn": "medicationOrders.prn2"
    },
    {
      "elementKey": "prn3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "validation": "time24",
      "fqn": "medicationOrders.prn3"
    },
    {
      "elementKey": "prn4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "validation": "time24",
      "fqn": "medicationOrders.prn4"
    },
    {
      "elementKey": "prn5",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "validation": "time24",
      "fqn": "medicationOrders.prn5"
    },
    {
      "elementKey": "prn6",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "validation": "time24",
      "fqn": "medicationOrders.prn6"
    },
    {
      "elementKey": "once",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "medicationOrders.once"
    },
    {
      "elementKey": "stat",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "medicationOrders.stat"
    },
    {
      "elementKey": "instructions",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "medicationOrders.instructions"
    },
    {
      "elementKey": "reason",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "medicationOrders.reason"
    },
    {
      "elementKey": "notes",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "18",
      "fqn": "medicationOrders.notes"
    },
    {
      "elementKey": "medicationOrdersTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medicationOrders.name"
    },
    {
      "elementKey": "medicationOrdersTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "medicationOrdersTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medicationOrders.profession"
    },
    {
      "elementKey": "medicationOrdersTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medicationOrders.day"
    },
    {
      "elementKey": "medicationOrdersTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medicationOrders.time"
    },
    {
      "elementKey": "med_medication",
      "formIndex": "2",
      "inputType": "lookahead",
      "mandatory": "TRUE",
      "tableColumn": "2",
      "fqn": "medicationOrders.med_medication"
    },
    {
      "elementKey": "med_dose",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medicationOrders.med_dose"
    },
    {
      "elementKey": "med_timing",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "cont",
          "text": "Continuous"
        },
        {
          "key": "once",
          "text": "Once"
        },
        {
          "key": "prn",
          "text": "PRN"
        },
        {
          "key": "sched",
          "text": "Scheduled"
        },
        {
          "key": "set",
          "text": "Set times"
        },
        {
          "key": "stat",
          "text": "Stat"
        }
      ],
      "tableColumn": "4",
      "fqn": "medicationOrders.med_timing"
    },
    {
      "elementKey": "med_route",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "oral",
          "text": "PO"
        },
        {
          "key": "bucc",
          "text": "Buccal"
        },
        {
          "key": "epidural",
          "text": "Epidural"
        },
        {
          "key": "impl",
          "text": "Implant"
        },
        {
          "key": "inha",
          "text": "Inhalation"
        },
        {
          "key": "inje",
          "text": "Injection (obsolete)"
        },
        {
          "key": "intradermal",
          "text": "Intradermal"
        },
        {
          "key": "intramuscular",
          "text": "Intramuscular"
        },
        {
          "key": "intraosseous",
          "text": "Intraosseous"
        },
        {
          "key": "intraperitoneal",
          "text": "Intraperitoneal"
        },
        {
          "key": "intrathecal",
          "text": "Intrathecal"
        },
        {
          "key": "intra",
          "text": "Intravenous"
        },
        {
          "key": "nasa",
          "text": "Nasal"
        },
        {
          "key": "nebu",
          "text": "Nebulization"
        },
        {
          "key": "ocul",
          "text": "Ocular"
        },
        {
          "key": "otic",
          "text": "Otic"
        },
        {
          "key": "rect",
          "text": "Rectal"
        },
        {
          "key": "subc",
          "text": "Subcutaneous"
        },
        {
          "key": "subl",
          "text": "Sublingual"
        },
        {
          "key": "topical",
          "text": "Topical"
        },
        {
          "key": "tran",
          "text": "Transdermal"
        },
        {
          "key": "vagi",
          "text": "Vaginal"
        }
      ],
      "tableColumn": "5",
      "fqn": "medicationOrders.med_route"
    },
    {
      "elementKey": "med_reason",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "medicationOrders.med_reason"
    },
    {
      "elementKey": "med_injectionLocation",
      "formIndex": "2",
      "formOption": "hideElement",
      "inputType": "select",
      "options": [
        {
          "key": "arterial",
          "text": "Arterial"
        },
        {
          "key": "epidural",
          "text": "Epidural"
        },
        {
          "key": "intramuscular",
          "text": "Intramuscular"
        },
        {
          "key": "intraosseous",
          "text": "Intraosseous"
        },
        {
          "key": "intraperitoneal",
          "text": "Intraperitoneal"
        },
        {
          "key": "intrathecal",
          "text": "Intrathecal"
        },
        {
          "key": "intravenous",
          "text": "Intravenous"
        },
        {
          "key": "subcutaneous",
          "text": "Subcutaneous"
        }
      ],
      "fqn": "medicationOrders.med_injectionLocation"
    },
    {
      "elementKey": "med_alert",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "cytotoxic",
          "text": "Cytotoxic"
        },
        {
          "key": "alert",
          "text": "High alert"
        },
        {
          "key": "confusion",
          "text": "Potential medication name confusion"
        },
        {
          "key": "second",
          "text": "Second signature required"
        }
      ],
      "tableColumn": "7",
      "fqn": "medicationOrders.med_alert"
    },
    {
      "elementKey": "med_status",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "orde",
          "text": "Ordered"
        },
        {
          "key": "appr",
          "text": "Approved"
        },
        {
          "key": "disp",
          "text": "Dispensed"
        },
        {
          "key": "sche",
          "text": "Scheduled"
        },
        {
          "key": "reac",
          "text": "Reactivated"
        },
        {
          "key": "erro",
          "text": "Error"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "8",
      "fqn": "medicationOrders.med_status"
    },
    {
      "elementKey": "med_prescriber",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "medicationOrders.med_prescriber"
    },
    {
      "elementKey": "med_scheduled",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "OD",
          "text": "Daily"
        },
        {
          "key": "BID",
          "text": "BID"
        },
        {
          "key": "TID",
          "text": "TID"
        },
        {
          "key": "QID",
          "text": "QID"
        },
        {
          "key": "Q12H",
          "text": "Q12H"
        },
        {
          "key": "Q8H",
          "text": "Q8H"
        },
        {
          "key": "Q6H",
          "text": "Q6H"
        },
        {
          "key": "Q4H",
          "text": "Q4H"
        },
        {
          "key": "Q2H",
          "text": "Q2H"
        },
        {
          "key": "Q1H",
          "text": "Q1H"
        }
      ],
      "tableColumn": "10",
      "fqn": "medicationOrders.med_scheduled"
    },
    {
      "elementKey": "med_prnMaxDosage",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "medicationOrders.med_prnMaxDosage"
    },
    {
      "elementKey": "med_time1",
      "dependentOn": "onChange:med_scheduled",
      "formIndex": "2",
      "inputType": "text",
      "validation": "time24",
      "fqn": "medicationOrders.med_time1"
    },
    {
      "elementKey": "med_time2",
      "dependentOn": "onChange:med_scheduled",
      "formIndex": "2",
      "inputType": "text",
      "validation": "time24",
      "fqn": "medicationOrders.med_time2"
    },
    {
      "elementKey": "med_time3",
      "dependentOn": "onChange:med_scheduled",
      "formIndex": "2",
      "inputType": "text",
      "validation": "time24",
      "fqn": "medicationOrders.med_time3"
    },
    {
      "elementKey": "med_time4",
      "dependentOn": "onChange:med_scheduled",
      "formIndex": "2",
      "inputType": "text",
      "validation": "time24",
      "fqn": "medicationOrders.med_time4"
    },
    {
      "elementKey": "med_time5",
      "dependentOn": "onChange:med_scheduled",
      "formIndex": "2",
      "inputType": "text",
      "validation": "time24",
      "fqn": "medicationOrders.med_time5"
    },
    {
      "elementKey": "med_time6",
      "dependentOn": "onChange:med_scheduled",
      "formIndex": "2",
      "inputType": "text",
      "validation": "time24",
      "fqn": "medicationOrders.med_time6"
    },
    {
      "elementKey": "med_continuous_description",
      "formIndex": "2",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "fqn": "medicationOrders.med_continuous_description"
    },
    {
      "elementKey": "med_instructions",
      "formIndex": "2",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "12",
      "fqn": "medicationOrders.med_instructions"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "medicationOrders",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Medication orders v1",
        "addButtonText": "Add a v1 medication order",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medicationOrders.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "group1",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.group1",
            "gIndex": "2",
            "gChildren": [
              "medication",
              "dose",
              "route",
              "startMeds",
              "endMeds"
            ]
          },
          {
            "elementKey": "adminGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.adminGroup",
            "gIndex": "3",
            "gChildren": [
              "administration"
            ]
          },
          {
            "elementKey": "schedGroup",
            "dependentOn": "visble:administration=sched,prn",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.schedGroup",
            "gIndex": "4",
            "gChildren": [
              "scheduled"
            ]
          },
          {
            "elementKey": "prnGroup",
            "dependentOn": "visble:administration=set",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.prnGroup",
            "gIndex": "5",
            "gChildren": [
              "prn1",
              "prn2",
              "prn3",
              "prn4",
              "prn5",
              "prn6"
            ]
          },
          {
            "elementKey": "onceGroup",
            "dependentOn": "visble:administration=once",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.onceGroup",
            "gIndex": "6",
            "gChildren": [
              "once"
            ]
          },
          {
            "elementKey": "statGroup",
            "dependentOn": "visble:administration=stat",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.statGroup",
            "gIndex": "7",
            "gChildren": [
              "stat"
            ]
          },
          {
            "elementKey": "group2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.group2",
            "gIndex": "8",
            "gChildren": [
              "instructions",
              "reason",
              "notes"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "medication": "",
          "dose": "",
          "route": "",
          "startMeds": "",
          "endMeds": "",
          "administration": "sched",
          "scheduled": "",
          "prn1": "",
          "prn2": "",
          "prn3": "",
          "prn4": "",
          "prn5": "",
          "prn6": "",
          "once": "",
          "stat": "",
          "instructions": "",
          "reason": "",
          "notes": ""
        }
      },
      "fqn": "medicationOrders.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "medication",
        "dose",
        "route",
        "startMeds",
        "endMeds",
        "administration",
        "scheduled",
        "prn1",
        "prn2",
        "prn3",
        "prn4",
        "prn5",
        "prn6",
        "once",
        "stat",
        "instructions",
        "reason",
        "notes",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "medicationOrdersTable": {
      "elementKey": "medicationOrdersTable",
      "pageElementIndex": "2",
      "pageKey": "medicationOrders",
      "tableKey": "medicationOrdersTable",
      "isTable": true,
      "tableAction": "[\"hideButton\",  \"medAdminRec.marTable\"]",
      "form": {
        "elementKey": "medicationOrdersTable",
        "label": "Medication orders",
        "addButtonText": "Add a medication order",
        "formKey": "medicationOrdersTable",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medicationOrders.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "medicationOrdersTable_name",
              "medicationOrdersTable_profession",
              "medicationOrdersTable_day",
              "medicationOrdersTable_time"
            ]
          },
          {
            "elementKey": "med_group_main",
            "formCss": "grid-left-to-right-1",
            "fqn": "medicationOrders.med_group_main",
            "gIndex": "2",
            "gChildren": [
              "med_medication",
              "med_dose",
              "med_timing",
              "med_route",
              "med_reason",
              "med_injectionLocation",
              "med_alert",
              "med_status",
              "med_prescriber"
            ]
          },
          {
            "elementKey": "med_group_with_times",
            "dependentOn": "visble:med_timing=prn,sched,set",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.med_group_with_times",
            "gIndex": "3",
            "gChildren": [
              "med_scheduled"
            ]
          },
          {
            "elementKey": "med_prn_group",
            "dependentOn": "visble:med_timing=prn",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.med_prn_group",
            "gIndex": "4",
            "gChildren": [
              "med_prnMaxDosage"
            ]
          },
          {
            "elementKey": "med_group_times",
            "dependentOn": "visble:med_timing=prn,sched,set",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicationOrders.med_group_times",
            "gIndex": "5",
            "gChildren": [
              "med_time1",
              "med_time2",
              "med_time3",
              "med_time4",
              "med_time5",
              "med_time6"
            ]
          },
          {
            "elementKey": "med_group_infusion_c1",
            "dependentOn": "visble:med_timing=cont",
            "formCss": "grid-left-to-right-1",
            "fqn": "medicationOrders.med_group_infusion_c1",
            "gIndex": "6",
            "gChildren": [
              "med_continuous_description"
            ]
          },
          {
            "elementKey": "med_group_notes",
            "formCss": "grid-left-to-right-1",
            "fqn": "medicationOrders.med_group_notes",
            "gIndex": "7",
            "gChildren": [
              "med_instructions"
            ]
          }
        ],
        "ehr_data": {
          "medicationOrdersTable_name": "",
          "medicationOrdersTable_profession": "",
          "medicationOrdersTable_day": "",
          "medicationOrdersTable_time": "",
          "med_medication": "",
          "med_dose": "",
          "med_timing": "",
          "med_route": "",
          "med_reason": "",
          "med_injectionLocation": "",
          "med_alert": "",
          "med_status": "",
          "med_prescriber": "",
          "med_scheduled": "",
          "med_prnMaxDosage": "",
          "med_time1": "",
          "med_time2": "",
          "med_time3": "",
          "med_time4": "",
          "med_time5": "",
          "med_time6": "",
          "med_continuous_description": "",
          "med_instructions": ""
        }
      },
      "fqn": "medicationOrders.medicationOrdersTable",
      "tableActionType": "hideButton",
      "tableActionRef": "medAdminRec.marTable",
      "taTargetPageKey": "medAdminRec",
      "taTargetTableKey": "marTable",
      "taSourcePageKey": "medicationOrders",
      "taSourceTableKey": "medicationOrdersTable",
      "tableChildren": [
        "medicationOrdersTable_id",
        "medicationOrdersTable_day",
        "medicationOrdersTable_time",
        "med_medication",
        "med_dose",
        "med_timing",
        "med_route",
        "med_reason",
        "med_alert",
        "med_status",
        "med_prescriber",
        "med_scheduled",
        "med_prnMaxDosage",
        "med_instructions",
        "medicationOrdersTable_name",
        "medicationOrdersTable_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS