/* eslint-disable */
const DEFS = {
  "pageDataKey": "labResults",
  "pIndex": "32",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labResults.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labResults.time"
    },
    {
      "elementKey": "laboratoryReport",
      "formIndex": "1",
      "inputType": "ehrFile",
      "tableColumn": "2",
      "fqn": "labResults.laboratoryReport"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "labResults.comments"
    },
    {
      "elementKey": "labResultGeneral_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.name"
    },
    {
      "elementKey": "labResultGeneral_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "labResultGeneral_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.profession"
    },
    {
      "elementKey": "labResultGeneral_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labResults.day"
    },
    {
      "elementKey": "labResultGeneral_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labResults.time"
    },
    {
      "elementKey": "urinOrderPhys",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "labResults.urinOrderPhys"
    },
    {
      "elementKey": "lrh_HCO3",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "21–32 mmol/L",
      "tableColumn": "3",
      "fqn": "labResults.lrh_HCO3",
      "suffixText": "21–32 mmol/L"
    },
    {
      "elementKey": "lrh_Ca",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "2.12–2.52 mmol/L",
      "tableColumn": "4",
      "fqn": "labResults.lrh_Ca",
      "suffixText": "2.12–2.52 mmol/L"
    },
    {
      "elementKey": "lrh_Ca_Ion",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "1.14–1.28 mmol/L",
      "tableColumn": "5",
      "fqn": "labResults.lrh_Ca_Ion",
      "suffixText": "1.14–1.28 mmol/L"
    },
    {
      "elementKey": "lrh_CO2",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "23–28 mmol/L",
      "tableColumn": "6",
      "fqn": "labResults.lrh_CO2",
      "suffixText": "23–28 mmol/L"
    },
    {
      "elementKey": "lrh_chloride",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "98 - 107 mmol/L",
      "tableColumn": "7",
      "fqn": "labResults.lrh_chloride",
      "suffixText": "98 - 107 mmol/L"
    },
    {
      "elementKey": "lrh_crp",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "≤ 10 mg/L",
      "tableColumn": "8",
      "fqn": "labResults.lrh_crp",
      "suffixText": "≤ 10 mg/L"
    },
    {
      "elementKey": "lrh_ck",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "F: 30 - 190 IU/L-NL-M: 30 - 250 IU/L",
      "tableColumn": "9",
      "fqn": "labResults.lrh_ck",
      "suffixText": "F: 30 - 190 IU/L\nM: 30 - 250 IU/L"
    },
    {
      "elementKey": "lrh_glucose",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "3.9 - 5.6 mmol/L (fasting)",
      "tableColumn": "10",
      "fqn": "labResults.lrh_glucose",
      "suffixText": "3.9 - 5.6 mmol/L (fasting)"
    },
    {
      "elementKey": "lrh_Mg",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "0.74–1.03 mmol/L",
      "tableColumn": "11",
      "fqn": "labResults.lrh_Mg",
      "suffixText": "0.74–1.03 mmol/L"
    },
    {
      "elementKey": "lrh_PO4",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "0.81–1.58 mmol/L",
      "tableColumn": "12",
      "fqn": "labResults.lrh_PO4",
      "suffixText": "0.81–1.58 mmol/L"
    },
    {
      "elementKey": "lrh_K",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "3.5–5.1 mmol/L",
      "tableColumn": "13",
      "fqn": "labResults.lrh_K",
      "suffixText": "3.5–5.1 mmol/L"
    },
    {
      "elementKey": "lrh_Na",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "136–146 mmol/L",
      "tableColumn": "14",
      "fqn": "labResults.lrh_Na",
      "suffixText": "136–146 mmol/L"
    },
    {
      "elementKey": "lrh_bun",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "2.1–8.0 mmol/L",
      "tableColumn": "15",
      "fqn": "labResults.lrh_bun",
      "suffixText": "2.1–8.0 mmol/L"
    },
    {
      "elementKey": "lrh_cr",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "F: 22–75 µmol/L-NL-M: 49–93 µmol/L",
      "tableColumn": "16",
      "fqn": "labResults.lrh_cr",
      "suffixText": "F: 22–75 µmol/L\nM: 49–93 µmol/L"
    },
    {
      "elementKey": "lrh_egfr",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "> 60 mL/min",
      "tableColumn": "17",
      "fqn": "labResults.lrh_egfr",
      "suffixText": "> 60 mL/min"
    },
    {
      "elementKey": "lrh_alt",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "17–63 IU/L",
      "tableColumn": "18",
      "fqn": "labResults.lrh_alt",
      "suffixText": "17–63 IU/L"
    },
    {
      "elementKey": "lrh_ast",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "15–37 IU/L",
      "tableColumn": "19",
      "fqn": "labResults.lrh_ast",
      "suffixText": "15–37 IU/L"
    },
    {
      "elementKey": "lrh_alp",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "50–136 IU/L",
      "tableColumn": "20",
      "fqn": "labResults.lrh_alp",
      "suffixText": "50–136 IU/L"
    },
    {
      "elementKey": "lrh_bilirubinD",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "2–9 µmol/L",
      "tableColumn": "21",
      "fqn": "labResults.lrh_bilirubinD",
      "suffixText": "2–9 µmol/L"
    },
    {
      "elementKey": "lrh_bilirubinT",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "3–17 µmol/L",
      "tableColumn": "22",
      "fqn": "labResults.lrh_bilirubinT",
      "suffixText": "3–17 µmol/L"
    },
    {
      "elementKey": "lrh_ggt",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "F: 5.0–55.0 IU/L-NL-M: 15.0–85.0 IU/L",
      "tableColumn": "23",
      "fqn": "labResults.lrh_ggt",
      "suffixText": "F: 5.0–55.0 IU/L\nM: 15.0–85.0 IU/L"
    },
    {
      "elementKey": "lrh_ldh",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "100–205 IU/L",
      "tableColumn": "24",
      "fqn": "labResults.lrh_ldh",
      "suffixText": "100–205 IU/L"
    },
    {
      "elementKey": "lrh_lipase",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "73–393 IU/L",
      "tableColumn": "25",
      "fqn": "labResults.lrh_lipase",
      "suffixText": "73–393 IU/L"
    },
    {
      "elementKey": "lrh_abg_hco3",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "22–27 mmol/L",
      "tableColumn": "26",
      "fqn": "labResults.lrh_abg_hco3",
      "suffixText": "22–27 mmol/L"
    },
    {
      "elementKey": "lrh_abg_o2sat",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "96-100%",
      "tableColumn": "27",
      "fqn": "labResults.lrh_abg_o2sat",
      "suffixText": "96-100%"
    },
    {
      "elementKey": "lrh_abg_pco2",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "32–45 mm Hg",
      "tableColumn": "28",
      "fqn": "labResults.lrh_abg_pco2",
      "suffixText": "32–45 mm Hg"
    },
    {
      "elementKey": "lrh_po2",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "83–116 mm Hg",
      "tableColumn": "29",
      "fqn": "labResults.lrh_po2",
      "suffixText": "83–116 mm Hg"
    },
    {
      "elementKey": "lrh_abg_ph",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "7.38–7.46",
      "tableColumn": "30",
      "fqn": "labResults.lrh_abg_ph",
      "suffixText": "7.38–7.46"
    },
    {
      "elementKey": "lru_bnp",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "≤ 50 pg/mL",
      "tableColumn": "31",
      "fqn": "labResults.lru_bnp",
      "suffixText": "≤ 50 pg/mL"
    },
    {
      "elementKey": "lrh_lrtrop_I",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "< 0.03 ng/mL",
      "tableColumn": "32",
      "fqn": "labResults.lrh_lrtrop_I",
      "suffixText": "< 0.03 ng/mL"
    },
    {
      "elementKey": "lrh_lrtrop_T",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "< 0.1 ng/mL",
      "tableColumn": "33",
      "fqn": "labResults.lrh_lrtrop_T",
      "suffixText": "< 0.1 ng/mL"
    },
    {
      "elementKey": "lrh_lrtrop_H",
      "formIndex": "2",
      "inputType": "lab_result",
      "suffix": "F: < 14 ng/L-NL-M: < 22 ng/L",
      "tableColumn": "34",
      "fqn": "labResults.lrh_lrtrop_H",
      "suffixText": "F: < 14 ng/L\nM: < 22 ng/L"
    },
    {
      "elementKey": "lrh_bloodGroup",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "A-",
          "text": "A-"
        },
        {
          "key": "A+",
          "text": "A+"
        },
        {
          "key": "B-",
          "text": "B-"
        },
        {
          "key": "B+",
          "text": "B+"
        },
        {
          "key": "AB-",
          "text": "AB-"
        },
        {
          "key": "AB+",
          "text": "AB+"
        },
        {
          "key": "O-",
          "text": "O-"
        },
        {
          "key": "O+",
          "text": "O+"
        }
      ],
      "tableColumn": "35",
      "fqn": "labResults.lrh_bloodGroup"
    },
    {
      "elementKey": "lrh_antibody",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "36",
      "fqn": "labResults.lrh_antibody"
    },
    {
      "elementKey": "lrh_gen_comments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "37",
      "fqn": "labResults.lrh_gen_comments"
    },
    {
      "elementKey": "labResultHematology_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.name"
    },
    {
      "elementKey": "labResultHematology_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "labResultHematology_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.profession"
    },
    {
      "elementKey": "labResultHematology_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labResults.day"
    },
    {
      "elementKey": "labResultHematology_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labResults.time"
    },
    {
      "elementKey": "lrh_HbA1C",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "4.8%–6.0%",
      "tableColumn": "2",
      "fqn": "labResults.lrh_HbA1C",
      "suffixText": "4.8%–6.0%"
    },
    {
      "elementKey": "lrh_Hgb",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "115-155 g/L",
      "tableColumn": "3",
      "fqn": "labResults.lrh_Hgb",
      "suffixText": "115-155 g/L"
    },
    {
      "elementKey": "lrh_hct",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "0.42 – 0.52 L/L",
      "tableColumn": "4",
      "fqn": "labResults.lrh_hct",
      "suffixText": "0.42 – 0.52 L/L"
    },
    {
      "elementKey": "lrh_platelets",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "130-380 x 10^9 /L",
      "tableColumn": "5",
      "fqn": "labResults.lrh_platelets",
      "suffixText": "130-380 x 10^9 /L"
    },
    {
      "elementKey": "lrh_rbc",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "3.50-5.00 x 10^12 /L",
      "tableColumn": "6",
      "fqn": "labResults.lrh_rbc",
      "suffixText": "3.50-5.00 x 10^12 /L"
    },
    {
      "elementKey": "lrh_wbc",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "3.5–10.5 × 10^9/L",
      "tableColumn": "7",
      "fqn": "labResults.lrh_wbc",
      "suffixText": "3.5–10.5 × 10^9/L"
    },
    {
      "elementKey": "lrh_esr",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "F: 0-20 mm/hour-NL-M: 0-10 mm/hour",
      "tableColumn": "8",
      "fqn": "labResults.lrh_esr",
      "suffixText": "F: 0-20 mm/hour\nM: 0-10 mm/hour"
    },
    {
      "elementKey": "lrh_basophils",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "0-0.1 x 10^9 /L",
      "tableColumn": "9",
      "fqn": "labResults.lrh_basophils",
      "suffixText": "0-0.1 x 10^9 /L"
    },
    {
      "elementKey": "lrh_eosinophils",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "0-0.5 x 10^9 /L",
      "tableColumn": "10",
      "fqn": "labResults.lrh_eosinophils",
      "suffixText": "0-0.5 x 10^9 /L"
    },
    {
      "elementKey": "lrh_lymphocytes",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "F: 0.8-3.3 x 10^9 /L-NL-M: 0.8–3.5 × 10^9/L",
      "tableColumn": "11",
      "fqn": "labResults.lrh_lymphocytes",
      "suffixText": "F: 0.8-3.3 x 10^9 /L\nM: 0.8–3.5 × 10^9/L"
    },
    {
      "elementKey": "lrh_monocytes",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "0.1-1.0 x 10^9 /L",
      "tableColumn": "12",
      "fqn": "labResults.lrh_monocytes",
      "suffixText": "0.1-1.0 x 10^9 /L"
    },
    {
      "elementKey": "lrh_neutrophils",
      "formIndex": "3",
      "inputType": "lab_result",
      "suffix": "2.0-7.5 x 10^9 /L",
      "tableColumn": "13",
      "fqn": "labResults.lrh_neutrophils",
      "suffixText": "2.0-7.5 x 10^9 /L"
    },
    {
      "elementKey": "lrh_comments",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "14",
      "fqn": "labResults.lrh_comments"
    },
    {
      "elementKey": "labResultCoagulation_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.name"
    },
    {
      "elementKey": "labResultCoagulation_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "labResultCoagulation_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.profession"
    },
    {
      "elementKey": "labResultCoagulation_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labResults.day"
    },
    {
      "elementKey": "labResultCoagulation_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labResults.time"
    },
    {
      "elementKey": "coag_fib",
      "formIndex": "4",
      "inputType": "lab_result",
      "suffix": "2-4 g/L",
      "tableColumn": "2",
      "fqn": "labResults.coag_fib",
      "suffixText": "2-4 g/L"
    },
    {
      "elementKey": "coag_ddimer",
      "formIndex": "4",
      "inputType": "lab_result",
      "tableColumn": "3",
      "fqn": "labResults.coag_ddimer"
    },
    {
      "elementKey": "coag_inr",
      "formIndex": "4",
      "inputType": "lab_result",
      "suffix": "0.9-1.2",
      "tableColumn": "4",
      "fqn": "labResults.coag_inr",
      "suffixText": "0.9-1.2"
    },
    {
      "elementKey": "coag_ptt",
      "formIndex": "4",
      "inputType": "lab_result",
      "tableColumn": "5",
      "fqn": "labResults.coag_ptt"
    },
    {
      "elementKey": "coag_appt",
      "formIndex": "4",
      "inputType": "lab_result",
      "suffix": "22-30 s",
      "tableColumn": "6",
      "fqn": "labResults.coag_appt",
      "suffixText": "22-30 s"
    },
    {
      "elementKey": "coag_comments",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "7",
      "fqn": "labResults.coag_comments"
    },
    {
      "elementKey": "labImmune_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.name"
    },
    {
      "elementKey": "labImmune_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "labImmune_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.profession"
    },
    {
      "elementKey": "labImmune_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labResults.day"
    },
    {
      "elementKey": "labImmune_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labResults.time"
    },
    {
      "elementKey": "immRF",
      "formIndex": "5",
      "inputType": "lab_result",
      "suffix": "≤ 20 kIU/L",
      "tableColumn": "2",
      "fqn": "labResults.immRF",
      "suffixText": "≤ 20 kIU/L"
    },
    {
      "elementKey": "imm_dna",
      "formIndex": "5",
      "inputType": "lab_result",
      "suffix": "<1:20 dilution",
      "tableColumn": "3",
      "fqn": "labResults.imm_dna",
      "suffixText": "<1:20 dilution"
    },
    {
      "elementKey": "imm_mono",
      "formIndex": "5",
      "inputType": "lab_result",
      "suffix": "positive or negative",
      "tableColumn": "4",
      "fqn": "labResults.imm_mono",
      "suffixText": "positive or negative"
    },
    {
      "elementKey": "labResultUrine_name",
      "formIndex": "6",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.name"
    },
    {
      "elementKey": "labResultUrine_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "labResultUrine_profession",
      "formIndex": "6",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labResults.profession"
    },
    {
      "elementKey": "labResultUrine_day",
      "formIndex": "6",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labResults.day"
    },
    {
      "elementKey": "labResultUrine_time",
      "formIndex": "6",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labResults.time"
    },
    {
      "elementKey": "lru_calrity",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "Normal: Clear",
      "tableColumn": "2",
      "fqn": "labResults.lru_calrity",
      "suffixText": "Normal: Clear"
    },
    {
      "elementKey": "lru_colour",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "Normal: Pale to dark yellow",
      "tableColumn": "3",
      "fqn": "labResults.lru_colour",
      "suffixText": "Normal: Pale to dark yellow"
    },
    {
      "elementKey": "lru_ordour",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "Slightly \"nutty\" odour",
      "tableColumn": "4",
      "fqn": "labResults.lru_ordour",
      "suffixText": "Slightly \"nutty\" odour"
    },
    {
      "elementKey": "lru_gravity",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "1.005–1.030",
      "tableColumn": "5",
      "fqn": "labResults.lru_gravity",
      "suffixText": "1.005–1.030"
    },
    {
      "elementKey": "lru_albumin",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "< 30mg",
      "tableColumn": "5",
      "fqn": "labResults.lru_albumin",
      "suffixText": "< 30mg"
    },
    {
      "elementKey": "lru_acr",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "≤ 2.0 g/mol Cr",
      "tableColumn": "6",
      "fqn": "labResults.lru_acr",
      "suffixText": "≤ 2.0 g/mol Cr"
    },
    {
      "elementKey": "lru_aldosterone",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "13.9–52.6 nmol/24 hour",
      "tableColumn": "7",
      "fqn": "labResults.lru_aldosterone",
      "suffixText": "13.9–52.6 nmol/24 hour"
    },
    {
      "elementKey": "lru_calcium",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "< 6.2 mmol/d",
      "tableColumn": "8",
      "fqn": "labResults.lru_calcium",
      "suffixText": "< 6.2 mmol/d"
    },
    {
      "elementKey": "lru_catecholamines",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "< 591 nmol/m2/24 hours",
      "tableColumn": "9",
      "fqn": "labResults.lru_catecholamines",
      "suffixText": "< 591 nmol/m2/24 hours"
    },
    {
      "elementKey": "lru_cortisol",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "< 248 nmol/24 hours",
      "tableColumn": "10",
      "fqn": "labResults.lru_cortisol",
      "suffixText": "< 248 nmol/24 hours"
    },
    {
      "elementKey": "lru_glucose",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "60–830 mcmol/L",
      "tableColumn": "11",
      "fqn": "labResults.lru_glucose",
      "suffixText": "60–830 mcmol/L"
    },
    {
      "elementKey": "lru_ketones",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "none",
      "tableColumn": "12",
      "fqn": "labResults.lru_ketones",
      "suffixText": "none"
    },
    {
      "elementKey": "lru_leukocyte",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "13",
      "fqn": "labResults.lru_leukocyte"
    },
    {
      "elementKey": "lru_nitrates",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "14",
      "fqn": "labResults.lru_nitrates"
    },
    {
      "elementKey": "lru_osmolality",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "38–1400 mOsm/kg H2O",
      "tableColumn": "15",
      "fqn": "labResults.lru_osmolality",
      "suffixText": "38–1400 mOsm/kg H2O"
    },
    {
      "elementKey": "lru_ph",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "5.0–8.5",
      "tableColumn": "16",
      "fqn": "labResults.lru_ph",
      "suffixText": "5.0–8.5"
    },
    {
      "elementKey": "lru_phosphate",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "Adult: 1.0-1.5 mmol/L-NL-Child: 1.3-2.3 mmol/L",
      "tableColumn": "17",
      "fqn": "labResults.lru_phosphate",
      "suffixText": "Adult: 1.0-1.5 mmol/L\nChild: 1.3-2.3 mmol/L"
    },
    {
      "elementKey": "lru_protein",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "None",
      "tableColumn": "18",
      "fqn": "labResults.lru_protein",
      "suffixText": "None"
    },
    {
      "elementKey": "lru_sodium",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "40-220 mmol/d",
      "tableColumn": "19",
      "fqn": "labResults.lru_sodium",
      "suffixText": "40-220 mmol/d"
    },
    {
      "elementKey": "lru_speccificGravity",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "1.005–1.030",
      "tableColumn": "20",
      "fqn": "labResults.lru_speccificGravity",
      "suffixText": "1.005–1.030"
    },
    {
      "elementKey": "lru_uricAcid",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "1.48–4.43 mmol/24 hours",
      "tableColumn": "21",
      "fqn": "labResults.lru_uricAcid",
      "suffixText": "1.48–4.43 mmol/24 hours"
    },
    {
      "elementKey": "lru_urobilinogen",
      "formIndex": "6",
      "inputType": "lab_result",
      "suffix": "≤ 16.0 µmol/L",
      "tableColumn": "22",
      "fqn": "labResults.lru_urobilinogen",
      "suffixText": "≤ 16.0 µmol/L"
    },
    {
      "elementKey": "lru_casts",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "23",
      "fqn": "labResults.lru_casts"
    },
    {
      "elementKey": "lru_crystals",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "24",
      "fqn": "labResults.lru_crystals"
    },
    {
      "elementKey": "lru_epitehlial",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "25",
      "fqn": "labResults.lru_epitehlial"
    },
    {
      "elementKey": "lru_rbc",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "26",
      "fqn": "labResults.lru_rbc"
    },
    {
      "elementKey": "lru_wbc",
      "formIndex": "6",
      "inputType": "lab_result",
      "tableColumn": "27",
      "fqn": "labResults.lru_wbc"
    },
    {
      "elementKey": "lruComments",
      "formIndex": "6",
      "inputType": "textarea",
      "tableColumn": "28",
      "fqn": "labResults.lruComments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "labResults",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Laboratory reports v1",
        "addButtonText": "Add a laboratory report",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labResults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup32-1",
            "formCss": "grid-left-to-right-3",
            "fqn": "labResults.cGroup32-1",
            "gIndex": "2",
            "gChildren": [
              "laboratoryReport",
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "laboratoryReport": "",
          "comments": ""
        }
      },
      "fqn": "labResults.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "laboratoryReport",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "labResultGeneral": {
      "elementKey": "labResultGeneral",
      "pageElementIndex": "2",
      "pageKey": "labResults",
      "tableKey": "labResultGeneral",
      "isTable": true,
      "form": {
        "elementKey": "labResultGeneral",
        "label": "General",
        "addButtonText": "Create a laboratory report",
        "formOption": "transpose",
        "formKey": "labResultGeneral",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labResults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "labResultGeneral_name",
              "labResultGeneral_profession",
              "labResultGeneral_day",
              "labResultGeneral_time"
            ]
          },
          {
            "elementKey": "cAccGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "labResults.cAccGroup",
            "gIndex": "2",
            "gChildren": [
              "urinOrderPhys"
            ]
          },
          {
            "elementKey": "cChemGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cChemGroup",
            "gIndex": "3",
            "gChildren": [
              "lrh_HCO3",
              "lrh_Ca",
              "lrh_Ca_Ion",
              "lrh_CO2",
              "lrh_chloride",
              "lrh_crp",
              "lrh_ck",
              "lrh_glucose",
              "lrh_Mg",
              "lrh_PO4",
              "lrh_K",
              "lrh_Na"
            ]
          },
          {
            "elementKey": "cRenalGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cRenalGroup",
            "gIndex": "4",
            "gChildren": [
              "lrh_bun",
              "lrh_cr",
              "lrh_egfr"
            ]
          },
          {
            "elementKey": "cLiverGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cLiverGroup",
            "gIndex": "5",
            "gChildren": [
              "lrh_alt",
              "lrh_ast",
              "lrh_alp",
              "lrh_bilirubinD",
              "lrh_bilirubinT",
              "lrh_ggt",
              "lrh_ldh",
              "lrh_lipase"
            ]
          },
          {
            "elementKey": "cBloodGasGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cBloodGasGroup",
            "gIndex": "6",
            "gChildren": [
              "lrh_abg_hco3",
              "lrh_abg_o2sat",
              "lrh_abg_pco2",
              "lrh_po2",
              "lrh_abg_ph"
            ]
          },
          {
            "elementKey": "cCardiac",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cCardiac",
            "gIndex": "7",
            "gChildren": [
              "lru_bnp",
              "lrh_lrtrop_I",
              "lrh_lrtrop_T",
              "lrh_lrtrop_H"
            ]
          },
          {
            "elementKey": "cGroup32-6",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-6",
            "gIndex": "8",
            "gChildren": [
              "lrh_bloodGroup",
              "lrh_antibody"
            ]
          },
          {
            "elementKey": "cGroup32-7",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-7",
            "gIndex": "9",
            "gChildren": [
              "lrh_gen_comments"
            ]
          }
        ],
        "ehr_data": {
          "labResultGeneral_name": "",
          "labResultGeneral_profession": "",
          "labResultGeneral_day": "",
          "labResultGeneral_time": "",
          "urinOrderPhys": "",
          "lrh_HCO3": "",
          "lrh_Ca": "",
          "lrh_Ca_Ion": "",
          "lrh_CO2": "",
          "lrh_chloride": "",
          "lrh_crp": "",
          "lrh_ck": "",
          "lrh_glucose": "",
          "lrh_Mg": "",
          "lrh_PO4": "",
          "lrh_K": "",
          "lrh_Na": "",
          "lrh_bun": "",
          "lrh_cr": "",
          "lrh_egfr": "",
          "lrh_alt": "",
          "lrh_ast": "",
          "lrh_alp": "",
          "lrh_bilirubinD": "",
          "lrh_bilirubinT": "",
          "lrh_ggt": "",
          "lrh_ldh": "",
          "lrh_lipase": "",
          "lrh_abg_hco3": "",
          "lrh_abg_o2sat": "",
          "lrh_abg_pco2": "",
          "lrh_po2": "",
          "lrh_abg_ph": "",
          "lru_bnp": "",
          "lrh_lrtrop_I": "",
          "lrh_lrtrop_T": "",
          "lrh_lrtrop_H": "",
          "lrh_bloodGroup": "",
          "lrh_antibody": "",
          "lrh_gen_comments": ""
        }
      },
      "fqn": "labResults.labResultGeneral",
      "tableChildren": [
        "labResultGeneral_id",
        "labResultGeneral_day",
        "labResultGeneral_time",
        "urinOrderPhys",
        "lrh_HCO3",
        "lrh_Ca",
        "lrh_Ca_Ion",
        "lrh_CO2",
        "lrh_chloride",
        "lrh_crp",
        "lrh_ck",
        "lrh_glucose",
        "lrh_Mg",
        "lrh_PO4",
        "lrh_K",
        "lrh_Na",
        "lrh_bun",
        "lrh_cr",
        "lrh_egfr",
        "lrh_alt",
        "lrh_ast",
        "lrh_alp",
        "lrh_bilirubinD",
        "lrh_bilirubinT",
        "lrh_ggt",
        "lrh_ldh",
        "lrh_lipase",
        "lrh_abg_hco3",
        "lrh_abg_o2sat",
        "lrh_abg_pco2",
        "lrh_po2",
        "lrh_abg_ph",
        "lru_bnp",
        "lrh_lrtrop_I",
        "lrh_lrtrop_T",
        "lrh_lrtrop_H",
        "lrh_bloodGroup",
        "lrh_antibody",
        "lrh_gen_comments",
        "labResultGeneral_name",
        "labResultGeneral_profession"
      ],
      "hasRecHeader": true
    },
    "labResultHematology": {
      "elementKey": "labResultHematology",
      "pageElementIndex": "3",
      "pageKey": "labResults",
      "tableKey": "labResultHematology",
      "isTable": true,
      "form": {
        "elementKey": "labResultHematology",
        "label": "Hematology",
        "addButtonText": "Create a hematology laboratory report",
        "formOption": "transpose",
        "formKey": "labResultHematology",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labResults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "labResultHematology_name",
              "labResultHematology_profession",
              "labResultHematology_day",
              "labResultHematology_time"
            ]
          },
          {
            "elementKey": "cGroup32-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-2",
            "gIndex": "2",
            "gChildren": [
              "lrh_HbA1C",
              "lrh_Hgb",
              "lrh_hct",
              "lrh_platelets",
              "lrh_rbc",
              "lrh_wbc",
              "lrh_esr"
            ]
          },
          {
            "elementKey": "cGroup32-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-3",
            "gIndex": "3",
            "gChildren": [
              "lrh_basophils",
              "lrh_eosinophils",
              "lrh_lymphocytes",
              "lrh_monocytes",
              "lrh_neutrophils"
            ]
          },
          {
            "elementKey": "cGroup32-4",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-4",
            "gIndex": "4",
            "gChildren": [
              "lrh_comments"
            ]
          }
        ],
        "ehr_data": {
          "labResultHematology_name": "",
          "labResultHematology_profession": "",
          "labResultHematology_day": "",
          "labResultHematology_time": "",
          "lrh_HbA1C": "",
          "lrh_Hgb": "",
          "lrh_hct": "",
          "lrh_platelets": "",
          "lrh_rbc": "",
          "lrh_wbc": "",
          "lrh_esr": "",
          "lrh_basophils": "",
          "lrh_eosinophils": "",
          "lrh_lymphocytes": "",
          "lrh_monocytes": "",
          "lrh_neutrophils": "",
          "lrh_comments": ""
        }
      },
      "fqn": "labResults.labResultHematology",
      "tableChildren": [
        "labResultHematology_id",
        "labResultHematology_day",
        "labResultHematology_time",
        "lrh_HbA1C",
        "lrh_Hgb",
        "lrh_hct",
        "lrh_platelets",
        "lrh_rbc",
        "lrh_wbc",
        "lrh_esr",
        "lrh_basophils",
        "lrh_eosinophils",
        "lrh_lymphocytes",
        "lrh_monocytes",
        "lrh_neutrophils",
        "lrh_comments",
        "labResultHematology_name",
        "labResultHematology_profession"
      ],
      "hasRecHeader": true
    },
    "labResultCoagulation": {
      "elementKey": "labResultCoagulation",
      "pageElementIndex": "4",
      "pageKey": "labResults",
      "tableKey": "labResultCoagulation",
      "isTable": true,
      "form": {
        "elementKey": "labResultCoagulation",
        "label": "Coagulation",
        "addButtonText": "Create a coagulation laboratory report",
        "formOption": "transpose",
        "formKey": "labResultCoagulation",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labResults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "labResultCoagulation_name",
              "labResultCoagulation_profession",
              "labResultCoagulation_day",
              "labResultCoagulation_time"
            ]
          },
          {
            "elementKey": "cGroup32-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-2",
            "gIndex": "2",
            "gChildren": [
              "coag_fib",
              "coag_ddimer",
              "coag_inr",
              "coag_ptt",
              "coag_appt"
            ]
          },
          {
            "elementKey": "cGroup32-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-3",
            "gIndex": "3",
            "gChildren": [
              "coag_comments"
            ]
          }
        ],
        "ehr_data": {
          "labResultCoagulation_name": "",
          "labResultCoagulation_profession": "",
          "labResultCoagulation_day": "",
          "labResultCoagulation_time": "",
          "coag_fib": "",
          "coag_ddimer": "",
          "coag_inr": "",
          "coag_ptt": "",
          "coag_appt": "",
          "coag_comments": ""
        }
      },
      "fqn": "labResults.labResultCoagulation",
      "tableChildren": [
        "labResultCoagulation_id",
        "labResultCoagulation_day",
        "labResultCoagulation_time",
        "coag_fib",
        "coag_ddimer",
        "coag_inr",
        "coag_ptt",
        "coag_appt",
        "coag_comments",
        "labResultCoagulation_name",
        "labResultCoagulation_profession"
      ],
      "hasRecHeader": true
    },
    "labImmune": {
      "elementKey": "labImmune",
      "pageElementIndex": "5",
      "pageKey": "labResults",
      "tableKey": "labImmune",
      "isTable": true,
      "form": {
        "elementKey": "labImmune",
        "label": "Immunology",
        "addButtonText": "Create a immunology laboratory report",
        "formKey": "labImmune",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labResults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "labImmune_name",
              "labImmune_profession",
              "labImmune_day",
              "labImmune_time"
            ]
          },
          {
            "elementKey": "immGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.immGroup",
            "gIndex": "2",
            "gChildren": [
              "immRF",
              "imm_dna",
              "imm_mono"
            ]
          }
        ],
        "ehr_data": {
          "labImmune_name": "",
          "labImmune_profession": "",
          "labImmune_day": "",
          "labImmune_time": "",
          "immRF": "",
          "imm_dna": "",
          "imm_mono": ""
        }
      },
      "fqn": "labResults.labImmune",
      "tableChildren": [
        "labImmune_id",
        "labImmune_day",
        "labImmune_time",
        "immRF",
        "imm_dna",
        "imm_mono",
        "labImmune_name",
        "labImmune_profession"
      ],
      "hasRecHeader": true
    },
    "labResultUrine": {
      "elementKey": "labResultUrine",
      "pageElementIndex": "6",
      "pageKey": "labResults",
      "tableKey": "labResultUrine",
      "isTable": true,
      "form": {
        "elementKey": "labResultUrine",
        "label": "Urine analysis",
        "addButtonText": "Create a urinary laboratory report",
        "formOption": "transpose",
        "formKey": "labResultUrine",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labResults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "labResultUrine_name",
              "labResultUrine_profession",
              "labResultUrine_day",
              "labResultUrine_time"
            ]
          },
          {
            "elementKey": "cGroup32-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-2",
            "gIndex": "2",
            "gChildren": [
              "lru_calrity",
              "lru_colour",
              "lru_ordour",
              "lru_gravity"
            ]
          },
          {
            "elementKey": "cGroup32-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-3",
            "gIndex": "3",
            "gChildren": [
              "lru_albumin",
              "lru_acr",
              "lru_aldosterone",
              "lru_calcium",
              "lru_catecholamines",
              "lru_cortisol",
              "lru_glucose",
              "lru_ketones",
              "lru_leukocyte",
              "lru_nitrates",
              "lru_osmolality",
              "lru_ph",
              "lru_phosphate",
              "lru_protein",
              "lru_sodium",
              "lru_speccificGravity",
              "lru_uricAcid",
              "lru_urobilinogen"
            ]
          },
          {
            "elementKey": "cGroup32-4",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-4",
            "gIndex": "4",
            "gChildren": [
              "lru_casts",
              "lru_crystals",
              "lru_epitehlial",
              "lru_rbc",
              "lru_wbc"
            ]
          },
          {
            "elementKey": "cGroup32-5",
            "formCss": "grid-left-to-right-1",
            "fqn": "labResults.cGroup32-5",
            "gIndex": "5",
            "gChildren": [
              "lruComments"
            ]
          }
        ],
        "ehr_data": {
          "labResultUrine_name": "",
          "labResultUrine_profession": "",
          "labResultUrine_day": "",
          "labResultUrine_time": "",
          "lru_calrity": "",
          "lru_colour": "",
          "lru_ordour": "",
          "lru_gravity": "",
          "lru_albumin": "",
          "lru_acr": "",
          "lru_aldosterone": "",
          "lru_calcium": "",
          "lru_catecholamines": "",
          "lru_cortisol": "",
          "lru_glucose": "",
          "lru_ketones": "",
          "lru_leukocyte": "",
          "lru_nitrates": "",
          "lru_osmolality": "",
          "lru_ph": "",
          "lru_phosphate": "",
          "lru_protein": "",
          "lru_sodium": "",
          "lru_speccificGravity": "",
          "lru_uricAcid": "",
          "lru_urobilinogen": "",
          "lru_casts": "",
          "lru_crystals": "",
          "lru_epitehlial": "",
          "lru_rbc": "",
          "lru_wbc": "",
          "lruComments": ""
        }
      },
      "fqn": "labResults.labResultUrine",
      "tableChildren": [
        "labResultUrine_id",
        "labResultUrine_day",
        "labResultUrine_time",
        "lru_calrity",
        "lru_colour",
        "lru_ordour",
        "lru_gravity",
        "lru_albumin",
        "lru_acr",
        "lru_aldosterone",
        "lru_calcium",
        "lru_catecholamines",
        "lru_cortisol",
        "lru_glucose",
        "lru_ketones",
        "lru_leukocyte",
        "lru_nitrates",
        "lru_osmolality",
        "lru_ph",
        "lru_phosphate",
        "lru_protein",
        "lru_sodium",
        "lru_speccificGravity",
        "lru_uricAcid",
        "lru_urobilinogen",
        "lru_casts",
        "lru_crystals",
        "lru_epitehlial",
        "lru_rbc",
        "lru_wbc",
        "lruComments",
        "labResultUrine_name",
        "labResultUrine_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS