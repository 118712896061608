<template lang="pug">
  div(class="ehr-nav-list", :class="ehrNavCollapsed ? 'ehr-nav-collapsed' : 'ehr-nav-full'")
    div(v-show="showNavPath(path)", class="EhrNavList__teaserList")
      ehr-nav-list-item(:path="path", :level="level", :opened='open')
      div(v-show="open")
        ehr-nav-list(v-for="child in path.children", v-bind:key="child.name" :path="child" :level="level + 1")
</template>

<script>
import EhrNavList from './EhrNavList'
import EhrNavListItem from './EhrNavListItem'
import StoreHelper from '@/helpers/store-helper'
import { EhrPages } from '@/ehr-definitions/ehr-models'
import FeatureHelper, { FF_NEW_BORN, FF_PED_PAGES } from '@/helpers/feature-helper'

const Pages = new EhrPages()

export default {
  name: 'EhrNavList',
  components: {
    EhrNavList,
    EhrNavListItem
  },
  props: {
    path: { type: Object },
    level: { type: Number }
  },
  computed: {
    ehrNavCollapsed () { return this.$store.getters['system/ehrNavCollapsed']},
    open () {
      if(StoreHelper.isLIS_Showing()) {
        // force all LIS nav groups to be open
        return true
      }
      if (this.level > 1) {
        return true
      }
      return this.$store.state.visit.topLevelMenu === this.path.name
    }
  },
  methods: {
    showNavPath (path) {
      let showPath = false
      if (path.isLIS) {
        showPath = StoreHelper.isLIS_Showing()
      }
      if (path.isEHR) {
        showPath = showPath || StoreHelper.isEHR_Showing()
      }
      const pageDef = Pages.findPage(path.pageDataKey) || {}
      if (showPath && !!pageDef.featureFlag) {
        if (pageDef.featureFlag === FF_NEW_BORN) {
          showPath = FeatureHelper.isFeatureFlagEnabled(FF_NEW_BORN)
        }
      }
      return showPath
    }
  },
  watch: {
    $route: function (to) {
      if (to.name === this.path.name) {
        this.$store.commit('visit/topLevelMenu', this.path.topLevel)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../scss/definitions';

.ehr-nav-collapsed {
  width: 9rem;
}
.ehr-nav-full {
  width: 20rem;
}

.ehr-nav-list {
  padding: 0;
  &__teaserList {
    margin-left: 0;
  }
  &__link {
    color: $white;
  }
}
</style>
