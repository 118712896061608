/* eslint-disable */
const DEFS = {
  "pageDataKey": "psychosocial",
  "pIndex": "4",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "history",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "psychosocial.history"
    },
    {
      "elementKey": "diet",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "psychosocial.diet"
    },
    {
      "elementKey": "livingSituation",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "psychosocial.livingSituation"
    },
    {
      "elementKey": "employmentSchool",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "psychosocial.employmentSchool"
    },
    {
      "elementKey": "hobbies",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "psychosocial.hobbies"
    },
    {
      "elementKey": "exercise",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "psychosocial.exercise"
    },
    {
      "elementKey": "exerciseText",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "text",
      "fqn": "psychosocial.exerciseText"
    },
    {
      "elementKey": "exerciseSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "psychosocial.exerciseSpacer"
    },
    {
      "elementKey": "caffeineUse",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "psychosocial.caffeineUse"
    },
    {
      "elementKey": "caffeineUseText",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "text",
      "fqn": "psychosocial.caffeineUseText"
    },
    {
      "elementKey": "caffeineUseSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "psychosocial.caffeineUseSpacer"
    },
    {
      "elementKey": "tobaccoUse",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "psychosocial.tobaccoUse"
    },
    {
      "elementKey": "tobaccoUseText",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "text",
      "fqn": "psychosocial.tobaccoUseText"
    },
    {
      "elementKey": "tobaccoUseSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "psychosocial.tobaccoUseSpacer"
    },
    {
      "elementKey": "cannabisUse",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "psychosocial.cannabisUse"
    },
    {
      "elementKey": "cannabisUseText",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "text",
      "fqn": "psychosocial.cannabisUseText"
    },
    {
      "elementKey": "cannabisUseSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "psychosocial.cannabisUseSpacer"
    },
    {
      "elementKey": "alcoholUse",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "psychosocial.alcoholUse"
    },
    {
      "elementKey": "alcoholUseText",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "text",
      "fqn": "psychosocial.alcoholUseText"
    },
    {
      "elementKey": "alcoholUseSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "psychosocial.alcoholUseSpacer"
    },
    {
      "elementKey": "substanceUse",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "psychosocial.substanceUse"
    },
    {
      "elementKey": "substanceUseText",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "text",
      "fqn": "psychosocial.substanceUseText"
    },
    {
      "elementKey": "substanceUseSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "psychosocial.substanceUseSpacer"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "psychosocial.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup",
          "formCss": "grid-left-to-right-1",
          "fqn": "psychosocial.cGroup",
          "gIndex": "1",
          "gChildren": [
            "history",
            "diet",
            "livingSituation",
            "employmentSchool",
            "hobbies"
          ]
        },
        {
          "elementKey": "cGroup2",
          "formCss": "grid-left-to-right-3",
          "fqn": "psychosocial.cGroup2",
          "gIndex": "2",
          "gChildren": [
            "exercise",
            "exerciseText",
            "exerciseSpacer",
            "caffeineUse",
            "caffeineUseText",
            "caffeineUseSpacer",
            "tobaccoUse",
            "tobaccoUseText",
            "tobaccoUseSpacer",
            "cannabisUse",
            "cannabisUseText",
            "cannabisUseSpacer",
            "alcoholUse",
            "alcoholUseText",
            "alcoholUseSpacer",
            "substanceUse",
            "substanceUseText",
            "substanceUseSpacer"
          ]
        }
      ]
    }
  }
}
export default DEFS