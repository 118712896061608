/* eslint-disable */
const DEFS = {
  "pageDataKey": "dishargeplan",
  "pIndex": "44",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "dishargeplan.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "dishargeplan.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "dishargeplan.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "dishargeplan.time"
    },
    {
      "elementKey": "goal",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "dishargeplan.goal"
    },
    {
      "elementKey": "interventions",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "dishargeplan.interventions"
    },
    {
      "elementKey": "evaluation",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "dishargeplan.evaluation"
    },
    {
      "elementKey": "status",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "In progress",
          "text": "In progress"
        },
        {
          "key": "Complete",
          "text": "Complete"
        }
      ],
      "tableColumn": "5",
      "fqn": "dishargeplan.status"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "dishargeplan",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Discharge plan",
        "addButtonText": "Add a discharge planning note",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "dishargeplan.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup44-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "dishargeplan.cGroup44-2",
            "gIndex": "2",
            "gChildren": [
              "goal",
              "interventions",
              "evaluation",
              "status"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "goal": "",
          "interventions": "",
          "evaluation": "",
          "status": ""
        }
      },
      "fqn": "dishargeplan.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "goal",
        "interventions",
        "evaluation",
        "status",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS