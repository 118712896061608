/* eslint-disable */
const DEFS = {
  "pageDataKey": "neurological",
  "pIndex": "14",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "neurological.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "neurological.time"
    },
    {
      "elementKey": "alert",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "alert",
          "text": "Alert"
        },
        {
          "key": "oriented",
          "text": "Oriented"
        },
        {
          "key": "slightlyDrowsy",
          "text": "Slightly Drowsy"
        },
        {
          "key": "drowsy",
          "text": "Drowsy"
        },
        {
          "key": "unresponsive",
          "text": "Unresponsive"
        }
      ],
      "tableColumn": "2",
      "fqn": "neurological.alert"
    },
    {
      "elementKey": "orientation",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "person",
          "text": "Person"
        },
        {
          "key": "place",
          "text": "Place"
        },
        {
          "key": "date",
          "text": "Time"
        },
        {
          "key": "situ",
          "text": "Situation"
        }
      ],
      "tableColumn": "3",
      "fqn": "neurological.orientation"
    },
    {
      "elementKey": "communication",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "verbal",
          "text": "Verbal"
        },
        {
          "key": "nonverbal",
          "text": "Nonverbal"
        },
        {
          "key": "slur",
          "text": "Slurred"
        },
        {
          "key": "aphasic",
          "text": "Aphasic"
        },
        {
          "key": "slow",
          "text": "Slow speech"
        },
        {
          "key": "rapid",
          "text": "Rapid speech"
        }
      ],
      "tableColumn": "4",
      "fqn": "neurological.communication"
    },
    {
      "elementKey": "eyeOpening",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Non testable",
          "text": "0 = Non testable"
        },
        {
          "key": "1 = None",
          "text": "1 = None"
        },
        {
          "key": "2 = To pressure",
          "text": "2 = To pressure"
        },
        {
          "key": "3 = To sound",
          "text": "3 = To sound"
        },
        {
          "key": "4 = Spontaneous",
          "text": "4 = Spontaneous"
        }
      ],
      "passToFunction": "glasgowCalculation",
      "tableColumn": "5",
      "fqn": "neurological.eyeOpening"
    },
    {
      "elementKey": "verbalResponse",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Non testable",
          "text": "0 = Non testable"
        },
        {
          "key": "1 = None",
          "text": "1 = None"
        },
        {
          "key": "2 = Sounds",
          "text": "2 = Sounds"
        },
        {
          "key": "3 = Words",
          "text": "3 = Words"
        },
        {
          "key": "4 = Confused",
          "text": "4 = Confused"
        },
        {
          "key": "5 = Oriented",
          "text": "5 = Oriented"
        }
      ],
      "passToFunction": "glasgowCalculation",
      "tableColumn": "6",
      "fqn": "neurological.verbalResponse"
    },
    {
      "elementKey": "bestMotorResponse",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Non testable",
          "text": "0 = Non testable"
        },
        {
          "key": "1 = None",
          "text": "1 = None"
        },
        {
          "key": "2 = Extension",
          "text": "2 = Extension"
        },
        {
          "key": "3 = Normal flexion",
          "text": "3 = Normal flexion"
        },
        {
          "key": "4 = Abnormal flexion",
          "text": "4 = Abnormal flexion"
        },
        {
          "key": "5 = Localising",
          "text": "5 = Localising"
        },
        {
          "key": "6 = Obeys commands",
          "text": "6 = Obeys commands"
        }
      ],
      "passToFunction": "glasgowCalculation",
      "tableColumn": "7",
      "fqn": "neurological.bestMotorResponse"
    },
    {
      "elementKey": "glasgowCalculation",
      "calculationType": "sum",
      "defaultValue": "0",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "tableColumn": "8",
      "fqn": "neurological.glasgowCalculation"
    },
    {
      "elementKey": "loss",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "No",
          "text": "No"
        },
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "Unknown",
          "text": "Unknown"
        }
      ],
      "tableColumn": "9",
      "fqn": "neurological.loss"
    },
    {
      "elementKey": "duration",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "neurological.duration"
    },
    {
      "elementKey": "leftPupilSize",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        }
      ],
      "tableColumn": "11",
      "fqn": "neurological.leftPupilSize"
    },
    {
      "elementKey": "leftPupilResponseToLight",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Brisk",
          "text": "Brisk"
        },
        {
          "key": "Sluggish",
          "text": "Sluggish"
        },
        {
          "key": "Fixed",
          "text": "Fixed"
        }
      ],
      "tableColumn": "12",
      "fqn": "neurological.leftPupilResponseToLight"
    },
    {
      "elementKey": "spacerEys1",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "neurological.spacerEys1"
    },
    {
      "elementKey": "rightPupilSize",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        }
      ],
      "tableColumn": "13",
      "fqn": "neurological.rightPupilSize"
    },
    {
      "elementKey": "rightPupilResponseToLight",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Brisk",
          "text": "Brisk"
        },
        {
          "key": "Sluggish",
          "text": "Sluggish"
        },
        {
          "key": "Fixed",
          "text": "Fixed"
        }
      ],
      "tableColumn": "14",
      "fqn": "neurological.rightPupilResponseToLight"
    },
    {
      "elementKey": "bothPupils",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Equal",
          "text": "Equal"
        },
        {
          "key": "Unequal",
          "text": "Unequal"
        }
      ],
      "tableColumn": "15",
      "fqn": "neurological.bothPupils"
    },
    {
      "elementKey": "facialSymmetry",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "leftDroop",
          "text": "Left sided facial droop"
        },
        {
          "key": "rightDroop",
          "text": "Right sided facial droop"
        },
        {
          "key": "bilateral",
          "text": "Bilateral facial droop"
        }
      ],
      "tableColumn": "16",
      "fqn": "neurological.facialSymmetry"
    },
    {
      "elementKey": "handRight",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "strong",
          "text": "Strong"
        },
        {
          "key": "mod",
          "text": "Moderate"
        },
        {
          "key": "weak",
          "text": "Weak"
        },
        {
          "key": "flaccid",
          "text": "Flaccid"
        }
      ],
      "tableColumn": "17",
      "fqn": "neurological.handRight"
    },
    {
      "elementKey": "handLet",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "strong",
          "text": "Strong"
        },
        {
          "key": "mod",
          "text": "Moderate"
        },
        {
          "key": "weak",
          "text": "Weak"
        },
        {
          "key": "flaccid",
          "text": "Flaccid"
        }
      ],
      "tableColumn": "18",
      "fqn": "neurological.handLet"
    },
    {
      "elementKey": "armRight",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "strong",
          "text": "Strong"
        },
        {
          "key": "mod",
          "text": "Moderate"
        },
        {
          "key": "weak",
          "text": "Weak"
        },
        {
          "key": "flaccid",
          "text": "Flaccid"
        }
      ],
      "tableColumn": "19",
      "fqn": "neurological.armRight"
    },
    {
      "elementKey": "armLeft",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "strong",
          "text": "Strong"
        },
        {
          "key": "mod",
          "text": "Moderate"
        },
        {
          "key": "weak",
          "text": "Weak"
        },
        {
          "key": "flaccid",
          "text": "Flaccid"
        }
      ],
      "tableColumn": "20",
      "fqn": "neurological.armLeft"
    },
    {
      "elementKey": "legRight",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "strong",
          "text": "Strong"
        },
        {
          "key": "mod",
          "text": "Moderate"
        },
        {
          "key": "weak",
          "text": "Weak"
        },
        {
          "key": "flaccid",
          "text": "Flaccid"
        }
      ],
      "tableColumn": "21",
      "fqn": "neurological.legRight"
    },
    {
      "elementKey": "legLeft",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "strong",
          "text": "Strong"
        },
        {
          "key": "mod",
          "text": "Moderate"
        },
        {
          "key": "weak",
          "text": "Weak"
        },
        {
          "key": "flaccid",
          "text": "Flaccid"
        }
      ],
      "tableColumn": "22",
      "fqn": "neurological.legLeft"
    },
    {
      "elementKey": "armDrift",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "No arm drift"
        },
        {
          "key": "dRight",
          "text": "Right arm drift"
        },
        {
          "key": "dLeft",
          "text": "Left arm drift"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "23",
      "fqn": "neurological.armDrift"
    },
    {
      "elementKey": "otherArmDrift",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "neurological.otherArmDrift"
    },
    {
      "elementKey": "pressure",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "neurological.pressure"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "26",
      "fqn": "neurological.comments"
    },
    {
      "elementKey": "resourcesMini",
      "assetBase": "standardized-assessment-tools",
      "assetName": "mini-mental-health-screen.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "neurological.resourcesMini"
    },
    {
      "elementKey": "resourcesMorse",
      "assetBase": "standardized-assessment-tools",
      "assetName": "Morse-Fall-Scale-score.png",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "neurological.resourcesMorse"
    },
    {
      "elementKey": "strokeAssessmentTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.name"
    },
    {
      "elementKey": "strokeAssessmentTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "strokeAssessmentTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.profession"
    },
    {
      "elementKey": "strokeAssessmentTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "neurological.day"
    },
    {
      "elementKey": "strokeAssessmentTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "neurological.time"
    },
    {
      "elementKey": "levelOfConciousness",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Alert; keenly responsive",
          "text": "0 = Alert; keenly responsive"
        },
        {
          "key": "1 = Not alert; but arousable by minor stimulation",
          "text": "1 = Not alert; but arousable by minor stimulation"
        },
        {
          "key": "2 = Not alert; requires repeated stimulation",
          "text": "2 = Not alert; requires repeated stimulation"
        },
        {
          "key": "3 = Unresponsive or responds only with reflex",
          "text": "3 = Unresponsive or responds only with reflex"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "2",
      "fqn": "neurological.levelOfConciousness"
    },
    {
      "elementKey": "levelOfConciousnessQuestions",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Answers two questions correctly",
          "text": "0 = Answers two questions correctly"
        },
        {
          "key": "1 = Answers one question correctly",
          "text": "1 = Answers one question correctly"
        },
        {
          "key": "2 = Answers neither question correctly",
          "text": "2 = Answers neither question correctly"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "3",
      "fqn": "neurological.levelOfConciousnessQuestions"
    },
    {
      "elementKey": "levelOfConciousnessCommands",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Performs both tasks correctly",
          "text": "0 = Performs both tasks correctly"
        },
        {
          "key": "1 = Performs one task correctly",
          "text": "1 = Performs one task correctly"
        },
        {
          "key": "2 = Performs neither task correctly",
          "text": "2 = Performs neither task correctly"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "4",
      "fqn": "neurological.levelOfConciousnessCommands"
    },
    {
      "elementKey": "motorLeftArm",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No drift",
          "text": "0 = No drift"
        },
        {
          "key": "1 = Drift",
          "text": "1 = Drift"
        },
        {
          "key": "2 = Some effort against gravity",
          "text": "2 = Some effort against gravity"
        },
        {
          "key": "3 = No effort against gravity; limb falls",
          "text": "3 = No effort against gravity; limb falls"
        },
        {
          "key": "4 = No movement",
          "text": "4 = No movement"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "5",
      "fqn": "neurological.motorLeftArm"
    },
    {
      "elementKey": "motorRightArm",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No drift",
          "text": "0 = No drift"
        },
        {
          "key": "1 = Drift",
          "text": "1 = Drift"
        },
        {
          "key": "2 = Some effort against gravity",
          "text": "2 = Some effort against gravity"
        },
        {
          "key": "3 = No effort against gravity; limb falls",
          "text": "3 = No effort against gravity; limb falls"
        },
        {
          "key": "4 = No movement",
          "text": "4 = No movement"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "6",
      "fqn": "neurological.motorRightArm"
    },
    {
      "elementKey": "spacerStroke1",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "neurological.spacerStroke1"
    },
    {
      "elementKey": "motorLeftLeg",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No drift",
          "text": "0 = No drift"
        },
        {
          "key": "1 = Drift",
          "text": "1 = Drift"
        },
        {
          "key": "2 = Some effort against gravity",
          "text": "2 = Some effort against gravity"
        },
        {
          "key": "3 = No effort against gravity",
          "text": "3 = No effort against gravity"
        },
        {
          "key": "4 = No movement",
          "text": "4 = No movement"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "7",
      "fqn": "neurological.motorLeftLeg"
    },
    {
      "elementKey": "motorRightLeg",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No drift",
          "text": "0 = No drift"
        },
        {
          "key": "1 = Drift",
          "text": "1 = Drift"
        },
        {
          "key": "2 = Some effort against gravity",
          "text": "2 = Some effort against gravity"
        },
        {
          "key": "3 = No effort against gravity",
          "text": "3 = No effort against gravity"
        },
        {
          "key": "4 = No movement",
          "text": "4 = No movement"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "8",
      "fqn": "neurological.motorRightLeg"
    },
    {
      "elementKey": "limbAtaxia",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Absent",
          "text": "0 = Absent"
        },
        {
          "key": "1 = Present in one limb",
          "text": "1 = Present in one limb"
        },
        {
          "key": "2 = Present in two limbs",
          "text": "2 = Present in two limbs"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "9",
      "fqn": "neurological.limbAtaxia"
    },
    {
      "elementKey": "bestGaze",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No deviation",
          "text": "0 = No deviation"
        },
        {
          "key": "1 = Partial gaze palsy",
          "text": "1 = Partial gaze palsy"
        },
        {
          "key": "2 = Forced deviation",
          "text": "2 = Forced deviation"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "10",
      "fqn": "neurological.bestGaze"
    },
    {
      "elementKey": "visual",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No visual loss",
          "text": "0 = No visual loss"
        },
        {
          "key": "1 = Partial hemianopia",
          "text": "1 = Partial hemianopia"
        },
        {
          "key": "2 = Partial paralysis",
          "text": "2 = Partial paralysis"
        },
        {
          "key": "3 = Complete paralysis of one or both sides",
          "text": "3 = Complete paralysis of one or both sides"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "11",
      "fqn": "neurological.visual"
    },
    {
      "elementKey": "facialPalsy",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Normal symmetric movements",
          "text": "0 = Normal symmetric movements"
        },
        {
          "key": "1 = Minor paralysis",
          "text": "1 = Minor paralysis"
        },
        {
          "key": "2 = Partial paralysis",
          "text": "2 = Partial paralysis"
        },
        {
          "key": "3 = Complete paralysis of one or both sides",
          "text": "3 = Complete paralysis of one or both sides"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "12",
      "fqn": "neurological.facialPalsy"
    },
    {
      "elementKey": "sensory",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Normal; no sensory loss",
          "text": "0 = Normal; no sensory loss"
        },
        {
          "key": "1 = Mild-to-moderate sensory loss",
          "text": "1 = Mild-to-moderate sensory loss"
        },
        {
          "key": "2 = Severe to total sensory loss",
          "text": "2 = Severe to total sensory loss"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "13",
      "fqn": "neurological.sensory"
    },
    {
      "elementKey": "bestLanguage",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No aphasia; normal",
          "text": "0 = No aphasia; normal"
        },
        {
          "key": "1 = Mild to moderate aphasia",
          "text": "1 = Mild to moderate aphasia"
        },
        {
          "key": "2 = Severe aphasia",
          "text": "2 = Severe aphasia"
        },
        {
          "key": "3 = Mute, global aphasia",
          "text": "3 = Mute, global aphasia"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "14",
      "fqn": "neurological.bestLanguage"
    },
    {
      "elementKey": "dysarthria",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = Normal",
          "text": "0 = Normal"
        },
        {
          "key": "1 = Mild to moderate",
          "text": "1 = Mild to moderate"
        },
        {
          "key": "2 = Severe dysarthria",
          "text": "2 = Severe dysarthria"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "15",
      "fqn": "neurological.dysarthria"
    },
    {
      "elementKey": "extinctionAndInattention",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0 = No abnormality",
          "text": "0 = No abnormality"
        },
        {
          "key": "1 = Visual, tactile, auditory, spatial, or personal inattention",
          "text": "1 = Visual, tactile, auditory, spatial, or personal inattention"
        },
        {
          "key": "2 = Profound hemi-inattention or extinction",
          "text": "2 = Profound hemi-inattention or extinction"
        }
      ],
      "passToFunction": "strokeAssessmentCalculation",
      "tableColumn": "16",
      "fqn": "neurological.extinctionAndInattention"
    },
    {
      "elementKey": "strokeAssessmentCalculation",
      "calculationType": "sum",
      "defaultValue": "0",
      "formIndex": "2",
      "formCss": "grid-new-subsection",
      "inputType": "calculatedValue",
      "tableColumn": "17",
      "fqn": "neurological.strokeAssessmentCalculation"
    },
    {
      "elementKey": "strokeComments",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "18",
      "fqn": "neurological.strokeComments"
    },
    {
      "elementKey": "del_table_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.name"
    },
    {
      "elementKey": "del_table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "del_table_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.profession"
    },
    {
      "elementKey": "del_table_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "neurological.day"
    },
    {
      "elementKey": "del_table_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "neurological.time"
    },
    {
      "elementKey": "del_individual",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "age75OrOlder",
          "text": "Age 75 or older"
        },
        {
          "key": "alcohol /Drug /TobaccoUse",
          "text": "Alcohol / drug / tobacco use"
        },
        {
          "key": "cognitiveImpairment",
          "text": "Cognitive impairment"
        },
        {
          "key": "functionalImpairment",
          "text": "Functional impairment"
        },
        {
          "key": "historyOfDepression",
          "text": "History of depression"
        },
        {
          "key": "previousDelirium",
          "text": "Previous delirium"
        },
        {
          "key": "reloaction",
          "text": "Reloaction"
        },
        {
          "key": "sleepDisturbance",
          "text": "Sleep disturbance"
        },
        {
          "key": "useOfRestraints",
          "text": "Use of restraints"
        },
        {
          "key": "vision /HearingLoss",
          "text": "Vision / hearing loss"
        }
      ],
      "tableColumn": "2",
      "fqn": "neurological.del_individual"
    },
    {
      "elementKey": "del_medicaal",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "advancedIllness",
          "text": "Advanced illness"
        },
        {
          "key": "chronicIllness",
          "text": "Chronic illness"
        },
        {
          "key": "electrolyteImbalance",
          "text": "Electrolyte imbalance"
        },
        {
          "key": "fever",
          "text": "Fever"
        },
        {
          "key": "historyOfTia /Stroke",
          "text": "History of TIA / stroke"
        },
        {
          "key": "hypotension",
          "text": "Hypotension"
        },
        {
          "key": "hypoxia",
          "text": "Hypoxia"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "multipleCoMorbidities",
          "text": "Multiple co-morbidities"
        },
        {
          "key": "surgery /Anesthesia",
          "text": "Surgery / anesthesia"
        },
        {
          "key": "unrelievedPain",
          "text": "Unrelieved pain"
        }
      ],
      "tableColumn": "3",
      "fqn": "neurological.del_medicaal"
    },
    {
      "elementKey": "del_medication",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "antipsychotics",
          "text": "Antipsychotics"
        },
        {
          "key": "benzodiazepines",
          "text": "Benzodiazepines"
        },
        {
          "key": "cardiacDrugs",
          "text": "Cardiac drugs"
        },
        {
          "key": "opiodAnalgesics",
          "text": "Opiod analgesics"
        },
        {
          "key": "receiving5OrMoreMeds",
          "text": "Receiving 5 or more meds"
        }
      ],
      "tableColumn": "4",
      "fqn": "neurological.del_medication"
    },
    {
      "elementKey": "delLabel1",
      "formIndex": "3",
      "inputType": "form_label",
      "fqn": "neurological.delLabel1"
    },
    {
      "elementKey": "delLabel2",
      "formIndex": "3",
      "inputType": "form_label",
      "fqn": "neurological.delLabel2"
    },
    {
      "elementKey": "del_doCam",
      "formIndex": "3",
      "inputType": "checkbox",
      "tableColumn": "5",
      "fqn": "neurological.del_doCam"
    },
    {
      "elementKey": "delLabel3",
      "assetName": "https://www2.gov.bc.ca/assets/gov/health/practitioner-pro/bc-guidelines/cogimp-appendix-c.pdf",
      "formIndex": "3",
      "inputType": "externalLink",
      "fqn": "neurological.delLabel3"
    },
    {
      "elementKey": "del_comments",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "neurological.del_comments"
    },
    {
      "elementKey": "cam_table_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.name"
    },
    {
      "elementKey": "cam_table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "cam_table_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.profession"
    },
    {
      "elementKey": "cam_table_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "neurological.day"
    },
    {
      "elementKey": "cam_table_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "neurological.time"
    },
    {
      "elementKey": "camLink",
      "assetName": "https://www2.gov.bc.ca/assets/gov/health/practitioner-pro/bc-guidelines/cogimp-appendix-c.pdf",
      "formIndex": "4",
      "inputType": "externalLink",
      "fqn": "neurological.camLink"
    },
    {
      "elementKey": "cam_1",
      "formIndex": "4",
      "inputType": "checkbox",
      "tableColumn": "2",
      "fqn": "neurological.cam_1"
    },
    {
      "elementKey": "cam_2",
      "formIndex": "4",
      "inputType": "checkbox",
      "tableColumn": "3",
      "fqn": "neurological.cam_2"
    },
    {
      "elementKey": "cam_3",
      "formIndex": "4",
      "inputType": "checkbox",
      "tableColumn": "4",
      "fqn": "neurological.cam_3"
    },
    {
      "elementKey": "cam_4",
      "formIndex": "4",
      "inputType": "checkbox",
      "tableColumn": "5",
      "fqn": "neurological.cam_4"
    },
    {
      "elementKey": "cam_assessment",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "neurological.cam_assessment"
    },
    {
      "elementKey": "mse_table_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.name"
    },
    {
      "elementKey": "mse_table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "mse_table_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "neurological.profession"
    },
    {
      "elementKey": "mse_table_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "neurological.day"
    },
    {
      "elementKey": "mse_table_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "neurological.time"
    },
    {
      "elementKey": "mseCustomForm",
      "formIndex": "5",
      "formOption": "mmse-form",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "neurological.mseCustomForm"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "neurological",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Neurological assessment",
        "addButtonText": "Add a neurological assessment",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "neurological.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "overviewGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.overviewGroup",
            "gIndex": "2",
            "gChildren": [
              "alert",
              "orientation",
              "communication"
            ]
          },
          {
            "elementKey": "glasgowGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.glasgowGroup",
            "gIndex": "3",
            "gChildren": [
              "eyeOpening",
              "verbalResponse",
              "bestMotorResponse",
              "glasgowCalculation"
            ]
          },
          {
            "elementKey": "conciousGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.conciousGroup",
            "gIndex": "4",
            "gChildren": [
              "loss",
              "duration"
            ]
          },
          {
            "elementKey": "eyesGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.eyesGroup",
            "gIndex": "5",
            "gChildren": [
              "leftPupilSize",
              "leftPupilResponseToLight",
              "spacerEys1",
              "rightPupilSize",
              "rightPupilResponseToLight",
              "bothPupils"
            ]
          },
          {
            "elementKey": "facilaGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.facilaGroup",
            "gIndex": "6",
            "gChildren": [
              "facialSymmetry"
            ]
          },
          {
            "elementKey": "motoGroup",
            "formCss": "grid-left-to-right-2",
            "fqn": "neurological.motoGroup",
            "gIndex": "7",
            "gChildren": [
              "handRight",
              "handLet",
              "armRight",
              "armLeft",
              "legRight",
              "legLeft",
              "armDrift",
              "otherArmDrift",
              "pressure"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.cGroup",
            "gIndex": "8",
            "gChildren": [
              "comments",
              "resourcesMini",
              "resourcesMorse"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "alert": "",
          "orientation": "",
          "communication": "",
          "eyeOpening": "",
          "verbalResponse": "",
          "bestMotorResponse": "",
          "glasgowCalculation": "0",
          "loss": "",
          "duration": "",
          "leftPupilSize": "",
          "leftPupilResponseToLight": "",
          "rightPupilSize": "",
          "rightPupilResponseToLight": "",
          "bothPupils": "",
          "facialSymmetry": "",
          "handRight": "",
          "handLet": "",
          "armRight": "",
          "armLeft": "",
          "legRight": "",
          "legLeft": "",
          "armDrift": "",
          "otherArmDrift": "",
          "pressure": "",
          "comments": ""
        }
      },
      "fqn": "neurological.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "alert",
        "orientation",
        "communication",
        "eyeOpening",
        "verbalResponse",
        "bestMotorResponse",
        "glasgowCalculation",
        "loss",
        "duration",
        "leftPupilSize",
        "leftPupilResponseToLight",
        "rightPupilSize",
        "rightPupilResponseToLight",
        "bothPupils",
        "facialSymmetry",
        "handRight",
        "handLet",
        "armRight",
        "armLeft",
        "legRight",
        "legLeft",
        "armDrift",
        "otherArmDrift",
        "pressure",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "strokeAssessmentTable": {
      "elementKey": "strokeAssessmentTable",
      "pageElementIndex": "2",
      "pageKey": "neurological",
      "tableKey": "strokeAssessmentTable",
      "isTable": true,
      "form": {
        "elementKey": "strokeAssessmentTable",
        "label": "Stroke assessment",
        "addButtonText": "Add a stroke assessment",
        "formOption": "transpose",
        "formKey": "strokeAssessmentTable",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup2",
            "formCss": "record-header",
            "fqn": "neurological.hdrGroup2",
            "gIndex": "1",
            "gChildren": [
              "strokeAssessmentTable_name",
              "strokeAssessmentTable_profession",
              "strokeAssessmentTable_day",
              "strokeAssessmentTable_time"
            ]
          },
          {
            "elementKey": "strokeGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "neurological.strokeGroup",
            "gIndex": "2",
            "gChildren": [
              "levelOfConciousness",
              "levelOfConciousnessQuestions",
              "levelOfConciousnessCommands",
              "motorLeftArm",
              "motorRightArm",
              "spacerStroke1",
              "motorLeftLeg",
              "motorRightLeg",
              "limbAtaxia",
              "bestGaze",
              "visual",
              "facialPalsy",
              "sensory",
              "bestLanguage",
              "dysarthria",
              "extinctionAndInattention",
              "strokeAssessmentCalculation",
              "strokeComments"
            ]
          }
        ],
        "ehr_data": {
          "strokeAssessmentTable_name": "",
          "strokeAssessmentTable_profession": "",
          "strokeAssessmentTable_day": "",
          "strokeAssessmentTable_time": "",
          "levelOfConciousness": "",
          "levelOfConciousnessQuestions": "",
          "levelOfConciousnessCommands": "",
          "motorLeftArm": "",
          "motorRightArm": "",
          "motorLeftLeg": "",
          "motorRightLeg": "",
          "limbAtaxia": "",
          "bestGaze": "",
          "visual": "",
          "facialPalsy": "",
          "sensory": "",
          "bestLanguage": "",
          "dysarthria": "",
          "extinctionAndInattention": "",
          "strokeAssessmentCalculation": "0",
          "strokeComments": ""
        }
      },
      "fqn": "neurological.strokeAssessmentTable",
      "tableChildren": [
        "strokeAssessmentTable_id",
        "strokeAssessmentTable_day",
        "strokeAssessmentTable_time",
        "levelOfConciousness",
        "levelOfConciousnessQuestions",
        "levelOfConciousnessCommands",
        "motorLeftArm",
        "motorRightArm",
        "motorLeftLeg",
        "motorRightLeg",
        "limbAtaxia",
        "bestGaze",
        "visual",
        "facialPalsy",
        "sensory",
        "bestLanguage",
        "dysarthria",
        "extinctionAndInattention",
        "strokeAssessmentCalculation",
        "strokeComments",
        "strokeAssessmentTable_name",
        "strokeAssessmentTable_profession"
      ],
      "hasRecHeader": true
    },
    "del_table": {
      "elementKey": "del_table",
      "pageElementIndex": "3",
      "pageKey": "neurological",
      "tableKey": "del_table",
      "isTable": true,
      "form": {
        "elementKey": "del_table",
        "label": "Delirium risk assessment",
        "addButtonText": "Add a delirium assessment",
        "formOption": "transpose",
        "formKey": "del_table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup3",
            "formCss": "record-header",
            "fqn": "neurological.hdrGroup3",
            "gIndex": "1",
            "gChildren": [
              "del_table_name",
              "del_table_profession",
              "del_table_day",
              "del_table_time"
            ]
          },
          {
            "elementKey": "delGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "neurological.delGroup",
            "gIndex": "2",
            "gChildren": [
              "del_individual",
              "del_medicaal",
              "del_medication"
            ]
          },
          {
            "elementKey": "delGroup2",
            "formCss": "grid-left-to-right-1",
            "fqn": "neurological.delGroup2",
            "gIndex": "3",
            "gChildren": [
              "delLabel1",
              "delLabel2",
              "del_doCam",
              "delLabel3",
              "del_comments"
            ]
          }
        ],
        "ehr_data": {
          "del_table_name": "",
          "del_table_profession": "",
          "del_table_day": "",
          "del_table_time": "",
          "del_individual": "",
          "del_medicaal": "",
          "del_medication": "",
          "del_doCam": "",
          "del_comments": ""
        }
      },
      "fqn": "neurological.del_table",
      "tableChildren": [
        "del_table_id",
        "del_table_day",
        "del_table_time",
        "del_individual",
        "del_medicaal",
        "del_medication",
        "del_doCam",
        "del_comments",
        "del_table_name",
        "del_table_profession"
      ],
      "hasRecHeader": true
    },
    "cam_table": {
      "elementKey": "cam_table",
      "pageElementIndex": "4",
      "pageKey": "neurological",
      "tableKey": "cam_table",
      "isTable": true,
      "form": {
        "elementKey": "cam_table",
        "label": "CAM assessment",
        "addButtonText": "Add a confusion assessment",
        "formOption": "transpose",
        "formKey": "cam_table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup4",
            "formCss": "record-header",
            "fqn": "neurological.hdrGroup4",
            "gIndex": "1",
            "gChildren": [
              "cam_table_name",
              "cam_table_profession",
              "cam_table_day",
              "cam_table_time"
            ]
          },
          {
            "elementKey": "camGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "neurological.camGroup",
            "gIndex": "2",
            "gChildren": [
              "camLink",
              "cam_1",
              "cam_2",
              "cam_3",
              "cam_4",
              "cam_assessment"
            ]
          }
        ],
        "ehr_data": {
          "cam_table_name": "",
          "cam_table_profession": "",
          "cam_table_day": "",
          "cam_table_time": "",
          "cam_1": "",
          "cam_2": "",
          "cam_3": "",
          "cam_4": "",
          "cam_assessment": ""
        }
      },
      "fqn": "neurological.cam_table",
      "tableChildren": [
        "cam_table_id",
        "cam_table_day",
        "cam_table_time",
        "cam_1",
        "cam_2",
        "cam_3",
        "cam_4",
        "cam_assessment",
        "cam_table_name",
        "cam_table_profession"
      ],
      "hasRecHeader": true
    },
    "mse_table": {
      "elementKey": "mse_table",
      "pageElementIndex": "5",
      "pageKey": "neurological",
      "tableKey": "mse_table",
      "isTable": true,
      "form": {
        "elementKey": "mse_table",
        "label": "MSE",
        "addButtonText": "Add a MSE assessment",
        "formKey": "mse_table",
        "ehr_groups": [
          {
            "elementKey": "mse_recHdrGroup",
            "formCss": "record-header",
            "fqn": "neurological.mse_recHdrGroup",
            "gIndex": "1",
            "gChildren": [
              "mse_table_name",
              "mse_table_profession",
              "mse_table_day",
              "mse_table_time"
            ]
          },
          {
            "elementKey": "mseGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "neurological.mseGroup",
            "gIndex": "2",
            "gChildren": [
              "mseCustomForm"
            ]
          }
        ],
        "ehr_data": {
          "mse_table_name": "",
          "mse_table_profession": "",
          "mse_table_day": "",
          "mse_table_time": "",
          "mseCustomForm": ""
        }
      },
      "fqn": "neurological.mse_table",
      "tableChildren": [
        "mse_table_id",
        "mse_table_day",
        "mse_table_time",
        "mseCustomForm",
        "mse_table_name",
        "mse_table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS