/* eslint-disable */
const DEFS = {
  "pageDataKey": "vitals",
  "pIndex": "11",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "vitals.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "vitals.time"
    },
    {
      "elementKey": "systolic",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "tableColumn": "2",
      "validation": "numeric",
      "fqn": "vitals.systolic"
    },
    {
      "elementKey": "diastolic",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "tableColumn": "3",
      "validation": "numeric",
      "fqn": "vitals.diastolic"
    },
    {
      "elementKey": "patientPosition",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Supine",
          "text": "Supine"
        },
        {
          "key": "Sitting",
          "text": "Sitting"
        },
        {
          "key": "Standing",
          "text": "Standing"
        },
        {
          "key": "left",
          "text": "Left lateral"
        },
        {
          "key": "right",
          "text": "Right lateral"
        },
        {
          "key": "prone",
          "text": "Prone"
        }
      ],
      "tableColumn": "4",
      "fqn": "vitals.patientPosition"
    },
    {
      "elementKey": "bpSource",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "auto",
          "text": "Automatic cuff"
        },
        {
          "key": "manual",
          "text": "Manual cuff"
        },
        {
          "key": "arterial",
          "text": "Arterial line"
        }
      ],
      "tableColumn": "5",
      "fqn": "vitals.bpSource"
    },
    {
      "elementKey": "temperature",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "suffix": "C",
      "tableColumn": "6",
      "validation": "numeric",
      "fqn": "vitals.temperature",
      "suffixText": "C"
    },
    {
      "elementKey": "source",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Oral",
          "text": "Oral"
        },
        {
          "key": "Axilla",
          "text": "Axilla"
        },
        {
          "key": "Rectal",
          "text": "Rectal"
        },
        {
          "key": "Tympanic",
          "text": "Tympanic"
        }
      ],
      "tableColumn": "7",
      "fqn": "vitals.source"
    },
    {
      "elementKey": "vitalsHemi",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "left",
          "text": "Left"
        },
        {
          "key": "right",
          "text": "Right"
        }
      ],
      "tableColumn": "8",
      "fqn": "vitals.vitalsHemi"
    },
    {
      "elementKey": "strength",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "apical",
          "text": "Apical"
        },
        {
          "key": "brachial",
          "text": "Brachial artery (arm)"
        },
        {
          "key": "carotid",
          "text": "Carotid artery (neck)"
        },
        {
          "key": "dorsalis",
          "text": "Dorsalis pedis artery (foot)"
        },
        {
          "key": "femoral",
          "text": "Femoral artery (groin)"
        },
        {
          "key": "popliteal",
          "text": "Popliteal artery (behind knee)"
        },
        {
          "key": "tibial",
          "text": "Posterior tibial artery (ankle joint)"
        },
        {
          "key": "radial",
          "text": "Radial artery (wrist)"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "vitals.strength"
    },
    {
      "elementKey": "pulseSource",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "oximeter",
          "text": "Pulse oximeter"
        },
        {
          "key": "cardia",
          "text": "Cardiac monitor"
        },
        {
          "key": "auto",
          "text": "Automatic monitor"
        },
        {
          "key": "palpation",
          "text": "Palpation"
        },
        {
          "key": "auscultation",
          "text": "Auscultation"
        }
      ],
      "tableColumn": "10",
      "fqn": "vitals.pulseSource"
    },
    {
      "elementKey": "rate",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "tableColumn": "11",
      "validation": "numeric",
      "fqn": "vitals.rate"
    },
    {
      "elementKey": "rhythm",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Regular",
          "text": "Regular"
        },
        {
          "key": "Irregular",
          "text": "Irregular"
        }
      ],
      "tableColumn": "12",
      "fqn": "vitals.rhythm"
    },
    {
      "elementKey": "respirationRate",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "tableColumn": "13",
      "validation": "numeric",
      "fqn": "vitals.respirationRate"
    },
    {
      "elementKey": "spacer2",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "vitals.spacer2"
    },
    {
      "elementKey": "spacer3",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "vitals.spacer3"
    },
    {
      "elementKey": "oxygenSaturation",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "suffix": "%",
      "tableColumn": "14",
      "validation": "numeric",
      "fqn": "vitals.oxygenSaturation",
      "suffixText": "%"
    },
    {
      "elementKey": "oxygenMode",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Room air",
          "text": "Room air"
        },
        {
          "key": "Nasal prongs",
          "text": "Nasal prongs"
        },
        {
          "key": "Simple face mask",
          "text": "Simple face mask"
        },
        {
          "key": "Rebreathing face mask",
          "text": "Rebreathing face mask"
        },
        {
          "key": "Optiflow",
          "text": "Optiflow"
        }
      ],
      "tableColumn": "15",
      "fqn": "vitals.oxygenMode"
    },
    {
      "elementKey": "flowRate",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "suffix": "/lpm",
      "tableColumn": "16",
      "validation": "numeric",
      "fqn": "vitals.flowRate",
      "suffixText": "/lpm"
    },
    {
      "elementKey": "bloodSugar",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "17",
      "validation": "numeric",
      "fqn": "vitals.bloodSugar",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "glucoseWarn",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "form_label",
      "fqn": "vitals.glucoseWarn"
    },
    {
      "elementKey": "painLevel",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "tableColumn": "18",
      "validation": "numeric",
      "fqn": "vitals.painLevel"
    },
    {
      "elementKey": "painWarn",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "form_label",
      "fqn": "vitals.painWarn"
    },
    {
      "elementKey": "cvp",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "text",
      "tableColumn": "19",
      "validation": "cvp",
      "fqn": "vitals.cvp"
    },
    {
      "elementKey": "vitalsComment",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "20",
      "fqn": "vitals.vitalsComment"
    },
    {
      "elementKey": "glucoseTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.name"
    },
    {
      "elementKey": "glucoseTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "glucoseTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.profession"
    },
    {
      "elementKey": "glucoseTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "vitals.day"
    },
    {
      "elementKey": "glucoseTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "vitals.time"
    },
    {
      "elementKey": "gluBloodSugar",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "2",
      "validation": "numeric",
      "fqn": "vitals.gluBloodSugar",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "gluWhen",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "brk",
          "text": "Before breakfast"
        },
        {
          "key": "lunch",
          "text": "Before lunch"
        },
        {
          "key": "supper",
          "text": "Before supper"
        },
        {
          "key": "bed",
          "text": "Before bedtime snack"
        },
        {
          "key": "outside",
          "text": "Outside meal times"
        }
      ],
      "tableColumn": "3",
      "fqn": "vitals.gluWhen"
    },
    {
      "elementKey": "comment",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "vitals.comment"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "vitals",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Vital signs",
        "addButtonText": "Add vital signs",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "vitals.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.cGroup",
            "gIndex": "2",
            "gChildren": [
              "systolic",
              "diastolic",
              "patientPosition",
              "bpSource"
            ]
          },
          {
            "elementKey": "vitalsGrp_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.vitalsGrp_3",
            "gIndex": "3",
            "gChildren": [
              "temperature",
              "source"
            ]
          },
          {
            "elementKey": "vitalsGrp_4",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.vitalsGrp_4",
            "gIndex": "4",
            "gChildren": [
              "vitalsHemi",
              "strength",
              "pulseSource",
              "rate",
              "rhythm"
            ]
          },
          {
            "elementKey": "vitalsGrp_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.vitalsGrp_5",
            "gIndex": "5",
            "gChildren": [
              "respirationRate",
              "spacer2",
              "spacer3"
            ]
          },
          {
            "elementKey": "vitalsGrp_6",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.vitalsGrp_6",
            "gIndex": "6",
            "gChildren": [
              "oxygenSaturation",
              "oxygenMode",
              "flowRate"
            ]
          },
          {
            "elementKey": "vitalsGrp_7",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.vitalsGrp_7",
            "gIndex": "7",
            "gChildren": [
              "bloodSugar",
              "glucoseWarn",
              "painLevel",
              "painWarn",
              "cvp"
            ]
          },
          {
            "elementKey": "vitalsGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "vitals.vitalsGrp",
            "gIndex": "8",
            "gChildren": [
              "vitalsComment"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "systolic": "",
          "diastolic": "",
          "patientPosition": "",
          "bpSource": "",
          "temperature": "",
          "source": "",
          "vitalsHemi": "",
          "strength": "",
          "pulseSource": "",
          "rate": "",
          "rhythm": "",
          "respirationRate": "",
          "oxygenSaturation": "",
          "oxygenMode": "",
          "flowRate": "",
          "bloodSugar": "",
          "painLevel": "",
          "cvp": "",
          "vitalsComment": ""
        }
      },
      "fqn": "vitals.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "systolic",
        "diastolic",
        "patientPosition",
        "bpSource",
        "temperature",
        "source",
        "vitalsHemi",
        "strength",
        "pulseSource",
        "rate",
        "rhythm",
        "respirationRate",
        "oxygenSaturation",
        "oxygenMode",
        "flowRate",
        "bloodSugar",
        "painLevel",
        "cvp",
        "vitalsComment",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "glucoseTable": {
      "elementKey": "glucoseTable",
      "pageElementIndex": "2",
      "pageKey": "vitals",
      "tableKey": "glucoseTable",
      "isTable": true,
      "form": {
        "elementKey": "glucoseTable",
        "label": "Glucose",
        "addButtonText": "Add glucose reading",
        "formOption": "transpose",
        "formKey": "glucoseTable",
        "ehr_groups": [
          {
            "elementKey": "gluGrp1",
            "formCss": "record-header",
            "fqn": "vitals.gluGrp1",
            "gIndex": "1",
            "gChildren": [
              "glucoseTable_name",
              "glucoseTable_profession",
              "glucoseTable_day",
              "glucoseTable_time"
            ]
          },
          {
            "elementKey": "gluGrp2",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.gluGrp2",
            "gIndex": "2",
            "gChildren": [
              "gluBloodSugar",
              "gluWhen"
            ]
          },
          {
            "elementKey": "gluGrp3",
            "formCss": "grid-left-to-right-1",
            "fqn": "vitals.gluGrp3",
            "gIndex": "3",
            "gChildren": [
              "comment"
            ]
          }
        ],
        "ehr_data": {
          "glucoseTable_name": "",
          "glucoseTable_profession": "",
          "glucoseTable_day": "",
          "glucoseTable_time": "",
          "gluBloodSugar": "",
          "gluWhen": "",
          "comment": ""
        }
      },
      "fqn": "vitals.glucoseTable",
      "tableChildren": [
        "glucoseTable_id",
        "glucoseTable_day",
        "glucoseTable_time",
        "gluBloodSugar",
        "gluWhen",
        "comment",
        "glucoseTable_name",
        "glucoseTable_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS