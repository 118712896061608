/* eslint-disable */
const DEFS = {
  "pageDataKey": "acpMost",
  "pIndex": "55",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "acpMost.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "acpMost.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "acpMost.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "acpMost.time"
    },
    {
      "elementKey": "mostOrderSert",
      "formIndex": "1",
      "formOption": "most-form",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "acpMost.mostOrderSert"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "acpMost",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Medical Orders for Scope of Treatment",
        "addButtonText": "Add a MOST",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "acpMost.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "acpGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "acpMost.acpGroup",
            "gIndex": "2",
            "gChildren": [
              "mostOrderSert"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "mostOrderSert": ""
        }
      },
      "fqn": "acpMost.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "mostOrderSert",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS