/* eslint-disable */
const DEFS = {
  "pageDataKey": "medLabChemistry",
  "pIndex": "45",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "tableQcChemistry_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabChemistry.name"
    },
    {
      "elementKey": "tableQcChemistry_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableQcChemistry_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabChemistry.profession"
    },
    {
      "elementKey": "tableQcChemistry_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabChemistry.day"
    },
    {
      "elementKey": "tableQcChemistry_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabChemistry.time"
    },
    {
      "elementKey": "label55-11",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-11"
    },
    {
      "elementKey": "label55-12",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-12"
    },
    {
      "elementKey": "chemQcAllAcceptable",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        },
        {
          "key": "na",
          "text": "N/A"
        }
      ],
      "tableColumn": "89",
      "fqn": "medLabChemistry.chemQcAllAcceptable"
    },
    {
      "elementKey": "label55-13",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-13"
    },
    {
      "elementKey": "chemQcAllDuplicatesAcceptable",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        },
        {
          "key": "na",
          "text": "N/A"
        }
      ],
      "tableColumn": "90",
      "fqn": "medLabChemistry.chemQcAllDuplicatesAcceptable"
    },
    {
      "elementKey": "label55-14",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-14"
    },
    {
      "elementKey": "chemQcAllPlausible",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        },
        {
          "key": "na",
          "text": "N/A"
        }
      ],
      "tableColumn": "91",
      "fqn": "medLabChemistry.chemQcAllPlausible"
    },
    {
      "elementKey": "label55-15",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-15"
    },
    {
      "elementKey": "chemQcAllCanBeReported",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        },
        {
          "key": "na",
          "text": "N/A"
        }
      ],
      "tableColumn": "92",
      "fqn": "medLabChemistry.chemQcAllCanBeReported"
    },
    {
      "elementKey": "label55-16",
      "formIndex": "1",
      "formCss": "grid-new-subsection grid-span-2-columns no-border",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-16"
    },
    {
      "elementKey": "label55-17",
      "formIndex": "1",
      "formCss": "grid-new-subsection grid-span-2-columns no-border",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-17"
    },
    {
      "elementKey": "label55-18",
      "formIndex": "1",
      "inputType": "formTableHeader",
      "fqn": "medLabChemistry.label55-18"
    },
    {
      "elementKey": "label55-19",
      "formIndex": "1",
      "inputType": "formTableHeader",
      "fqn": "medLabChemistry.label55-19"
    },
    {
      "elementKey": "label55-20",
      "formIndex": "1",
      "inputType": "formTableHeader",
      "fqn": "medLabChemistry.label55-20"
    },
    {
      "elementKey": "label55-21",
      "formIndex": "1",
      "inputType": "formTableHeader",
      "fqn": "medLabChemistry.label55-21"
    },
    {
      "elementKey": "label55-22",
      "formIndex": "1",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label55-22"
    },
    {
      "elementKey": "chmQcAnalyte1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "93",
      "fqn": "medLabChemistry.chmQcAnalyte1"
    },
    {
      "elementKey": "chmQcLevel1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "94",
      "fqn": "medLabChemistry.chmQcLevel1"
    },
    {
      "elementKey": "chmQcRule1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "95",
      "fqn": "medLabChemistry.chmQcRule1"
    },
    {
      "elementKey": "chmQcSeRe1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "96",
      "fqn": "medLabChemistry.chmQcSeRe1"
    },
    {
      "elementKey": "chmQcDescribe1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "97",
      "fqn": "medLabChemistry.chmQcDescribe1"
    },
    {
      "elementKey": "chmQcAnalyte2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "98",
      "fqn": "medLabChemistry.chmQcAnalyte2"
    },
    {
      "elementKey": "chmQcLevel2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "99",
      "fqn": "medLabChemistry.chmQcLevel2"
    },
    {
      "elementKey": "chmQcRule2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "100",
      "fqn": "medLabChemistry.chmQcRule2"
    },
    {
      "elementKey": "chmQcSeRe2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "101",
      "fqn": "medLabChemistry.chmQcSeRe2"
    },
    {
      "elementKey": "chmQcDescribe2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "102",
      "fqn": "medLabChemistry.chmQcDescribe2"
    },
    {
      "elementKey": "chmQcAnalyte3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "103",
      "fqn": "medLabChemistry.chmQcAnalyte3"
    },
    {
      "elementKey": "chmQcLevel3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "104",
      "fqn": "medLabChemistry.chmQcLevel3"
    },
    {
      "elementKey": "chmQcRule3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "105",
      "fqn": "medLabChemistry.chmQcRule3"
    },
    {
      "elementKey": "chmQcSeRe3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "106",
      "fqn": "medLabChemistry.chmQcSeRe3"
    },
    {
      "elementKey": "chmQcDescribe3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "107",
      "fqn": "medLabChemistry.chmQcDescribe3"
    },
    {
      "elementKey": "chmQcAnalyte4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "108",
      "fqn": "medLabChemistry.chmQcAnalyte4"
    },
    {
      "elementKey": "chmQcLevel4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "109",
      "fqn": "medLabChemistry.chmQcLevel4"
    },
    {
      "elementKey": "chmQcRule4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "110",
      "fqn": "medLabChemistry.chmQcRule4"
    },
    {
      "elementKey": "chmQcSeRe4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "111",
      "fqn": "medLabChemistry.chmQcSeRe4"
    },
    {
      "elementKey": "chmQcDescribe4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "112",
      "fqn": "medLabChemistry.chmQcDescribe4"
    },
    {
      "elementKey": "label55-112",
      "formIndex": "1",
      "formCss": "grid-new-subsection grid-span-2-columns no-border",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-112"
    },
    {
      "elementKey": "label55-113",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-113"
    },
    {
      "elementKey": "label55-114",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-114"
    },
    {
      "elementKey": "cqcPendAnal_1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "113",
      "fqn": "medLabChemistry.cqcPendAnal_1"
    },
    {
      "elementKey": "cqcPendResult_1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "114",
      "fqn": "medLabChemistry.cqcPendResult_1"
    },
    {
      "elementKey": "cqcPendAnal_2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "115",
      "fqn": "medLabChemistry.cqcPendAnal_2"
    },
    {
      "elementKey": "cqcPendResult_2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "116",
      "fqn": "medLabChemistry.cqcPendResult_2"
    },
    {
      "elementKey": "cqcPendAnal_3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "117",
      "fqn": "medLabChemistry.cqcPendAnal_3"
    },
    {
      "elementKey": "cqcPendResult_3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "118",
      "fqn": "medLabChemistry.cqcPendResult_3"
    },
    {
      "elementKey": "cqcPendAnal_4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "119",
      "fqn": "medLabChemistry.cqcPendAnal_4"
    },
    {
      "elementKey": "cqcPendResult_4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "120",
      "fqn": "medLabChemistry.cqcPendResult_4"
    },
    {
      "elementKey": "tableChem_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabChemistry.name"
    },
    {
      "elementKey": "tableChem_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableChem_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabChemistry.profession"
    },
    {
      "elementKey": "tableChem_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabChemistry.day"
    },
    {
      "elementKey": "tableChem_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabChemistry.time"
    },
    {
      "elementKey": "chemAccession",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "medLabChemistry.chemAccession"
    },
    {
      "elementKey": "chemCollDay",
      "defaultValue": "Today",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabChemistry.chemCollDay"
    },
    {
      "elementKey": "chemCollTime",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "medLabChemistry.chemCollTime"
    },
    {
      "elementKey": "urinOrderPhys",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "medLabChemistry.urinOrderPhys"
    },
    {
      "elementKey": "label45-3-1",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "options": [
        {
          "key": "Clear",
          "text": "Clear"
        },
        {
          "key": "Hazy",
          "text": "Hazy"
        },
        {
          "key": "Cloudy",
          "text": "Cloudy"
        },
        {
          "key": "Turbid",
          "text": "Turbid"
        }
      ],
      "fqn": "medLabChemistry.label45-3-1"
    },
    {
      "elementKey": "label45-3-2",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "options": [
        {
          "key": "Colorless",
          "text": "Colorless"
        },
        {
          "key": "Pale yellow",
          "text": "Pale yellow"
        },
        {
          "key": "Yellow",
          "text": "Yellow"
        },
        {
          "key": "Amber",
          "text": "Amber"
        },
        {
          "key": "Orange",
          "text": "Orange"
        },
        {
          "key": "Red",
          "text": "Red"
        },
        {
          "key": "Pink",
          "text": "Pink"
        },
        {
          "key": "Brown",
          "text": "Brown"
        },
        {
          "key": "Green",
          "text": "Green"
        }
      ],
      "fqn": "medLabChemistry.label45-3-2"
    },
    {
      "elementKey": "label45-3-3",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabChemistry.label45-3-3"
    },
    {
      "elementKey": "label45-3-4",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabChemistry.label45-3-4"
    },
    {
      "elementKey": "label45-3-5",
      "formIndex": "2",
      "inputType": "formTableText",
      "suffix": "mmol/L",
      "fqn": "medLabChemistry.label45-3-5",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "chemSodium",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "medLabChemistry.chemSodium"
    },
    {
      "elementKey": "chemSodiumFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "7",
      "fqn": "medLabChemistry.chemSodiumFlag"
    },
    {
      "elementKey": "chemSodiumRange",
      "defaultValue": "135 – 145 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "medLabChemistry.chemSodiumRange"
    },
    {
      "elementKey": "label45-3-7",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-7"
    },
    {
      "elementKey": "chemPotassium",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "medLabChemistry.chemPotassium"
    },
    {
      "elementKey": "chemPotassiumFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "10",
      "fqn": "medLabChemistry.chemPotassiumFlag"
    },
    {
      "elementKey": "chemPotassiumRange",
      "defaultValue": "3.5–5.1 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "medLabChemistry.chemPotassiumRange"
    },
    {
      "elementKey": "label45-3-9",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-9"
    },
    {
      "elementKey": "chemChloride",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "medLabChemistry.chemChloride"
    },
    {
      "elementKey": "chemChlorideFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "13",
      "fqn": "medLabChemistry.chemChlorideFlag"
    },
    {
      "elementKey": "chemChlorideRange",
      "defaultValue": "95 – 107 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "medLabChemistry.chemChlorideRange"
    },
    {
      "elementKey": "label45-3-11",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-11"
    },
    {
      "elementKey": "chemtCO2",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "medLabChemistry.chemtCO2"
    },
    {
      "elementKey": "chemtCO2Flag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "16",
      "fqn": "medLabChemistry.chemtCO2Flag"
    },
    {
      "elementKey": "chemtCO2Range",
      "defaultValue": "22 – 31 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "medLabChemistry.chemtCO2Range"
    },
    {
      "elementKey": "label45-3-13",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-13"
    },
    {
      "elementKey": "chemAnionGap",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "medLabChemistry.chemAnionGap"
    },
    {
      "elementKey": "chemAnionGapFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "19",
      "fqn": "medLabChemistry.chemAnionGapFlag"
    },
    {
      "elementKey": "chemAnionGapRange",
      "defaultValue": "8 – 16 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "medLabChemistry.chemAnionGapRange"
    },
    {
      "elementKey": "label45-3-15",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-15"
    },
    {
      "elementKey": "chemOsmalityMeasured",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "medLabChemistry.chemOsmalityMeasured"
    },
    {
      "elementKey": "chemOsmalityMeasuredFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "22",
      "fqn": "medLabChemistry.chemOsmalityMeasuredFlag"
    },
    {
      "elementKey": "chemOsmalityMeasuredRange",
      "defaultValue": "275 – 295 mOsm/kg",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "23",
      "fqn": "medLabChemistry.chemOsmalityMeasuredRange"
    },
    {
      "elementKey": "label45-3-17",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-17"
    },
    {
      "elementKey": "chemOsmalityCalculated",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "medLabChemistry.chemOsmalityCalculated"
    },
    {
      "elementKey": "chemOsmalityCalculatedFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "25",
      "fqn": "medLabChemistry.chemOsmalityCalculatedFlag"
    },
    {
      "elementKey": "chemOsmalityCalculatedRange",
      "defaultValue": "275 – 295 mOsm/kg",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "medLabChemistry.chemOsmalityCalculatedRange"
    },
    {
      "elementKey": "label45-3-19",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-19"
    },
    {
      "elementKey": "chemOsmolarGap",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "27",
      "fqn": "medLabChemistry.chemOsmolarGap"
    },
    {
      "elementKey": "chemOsmolarGapFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "28",
      "fqn": "medLabChemistry.chemOsmolarGapFlag"
    },
    {
      "elementKey": "chemOsmolarGapRange",
      "defaultValue": "≤ 10 mOsm/kg",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "29",
      "fqn": "medLabChemistry.chemOsmolarGapRange"
    },
    {
      "elementKey": "label45-3-22",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-22"
    },
    {
      "elementKey": "chemGlugoseFastingRandom",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "30",
      "fqn": "medLabChemistry.chemGlugoseFastingRandom"
    },
    {
      "elementKey": "chemGlugoseFastingRandomFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "31",
      "fqn": "medLabChemistry.chemGlugoseFastingRandomFlag"
    },
    {
      "elementKey": "chemGlugoseFastingRandomRange",
      "defaultValue": "F(3.6-6.0) R(3.6-11.0)mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "32",
      "fqn": "medLabChemistry.chemGlugoseFastingRandomRange"
    },
    {
      "elementKey": "label45-3-24",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-24"
    },
    {
      "elementKey": "chemCreatinine",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "33",
      "fqn": "medLabChemistry.chemCreatinine"
    },
    {
      "elementKey": "chemCreatinineFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "34",
      "fqn": "medLabChemistry.chemCreatinineFlag"
    },
    {
      "elementKey": "chemCreatinineRange",
      "defaultValue": "M(60-115) F(40-95) umol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "35",
      "fqn": "medLabChemistry.chemCreatinineRange"
    },
    {
      "elementKey": "label45-3-26",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label45-3-26"
    },
    {
      "elementKey": "chemUreaBun",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "36",
      "fqn": "medLabChemistry.chemUreaBun"
    },
    {
      "elementKey": "chemUreaBunFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "37",
      "fqn": "medLabChemistry.chemUreaBunFlag"
    },
    {
      "elementKey": "chemUreaBunRange",
      "defaultValue": "2.0 – 8.2 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "38",
      "fqn": "medLabChemistry.chemUreaBunRange"
    },
    {
      "elementKey": "label55-1",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label55-1"
    },
    {
      "elementKey": "chemCalcium",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "39",
      "fqn": "medLabChemistry.chemCalcium"
    },
    {
      "elementKey": "chemCalciumFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "40",
      "fqn": "medLabChemistry.chemCalciumFlag"
    },
    {
      "elementKey": "chemCalciumRange",
      "defaultValue": "2.10 – 2.55 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "41",
      "fqn": "medLabChemistry.chemCalciumRange"
    },
    {
      "elementKey": "label55-3",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label55-3"
    },
    {
      "elementKey": "chemMagnesium",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "42",
      "fqn": "medLabChemistry.chemMagnesium"
    },
    {
      "elementKey": "chemMagnesiumFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "43",
      "fqn": "medLabChemistry.chemMagnesiumFlag"
    },
    {
      "elementKey": "chemMagnesiumRange",
      "defaultValue": "0.70 – 1.10 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "44",
      "fqn": "medLabChemistry.chemMagnesiumRange"
    },
    {
      "elementKey": "label55-5",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label55-5"
    },
    {
      "elementKey": "chemPhosphorus",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "45",
      "fqn": "medLabChemistry.chemPhosphorus"
    },
    {
      "elementKey": "chemPhosphorusFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "46",
      "fqn": "medLabChemistry.chemPhosphorusFlag"
    },
    {
      "elementKey": "chemPhosphorusRange",
      "defaultValue": "0.80 – 1.45 mmol/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "47",
      "fqn": "medLabChemistry.chemPhosphorusRange"
    },
    {
      "elementKey": "label55-7",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label55-7"
    },
    {
      "elementKey": "chemTotalProtein",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "48",
      "fqn": "medLabChemistry.chemTotalProtein"
    },
    {
      "elementKey": "chemTotalProteinFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "49",
      "fqn": "medLabChemistry.chemTotalProteinFlag"
    },
    {
      "elementKey": "chemTotalProteinRange",
      "defaultValue": "62 – 82 g/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "50",
      "fqn": "medLabChemistry.chemTotalProteinRange"
    },
    {
      "elementKey": "label55-9",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabChemistry.label55-9"
    },
    {
      "elementKey": "chemAlbumin",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "51",
      "fqn": "medLabChemistry.chemAlbumin"
    },
    {
      "elementKey": "chemAlbuminFlag",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "52",
      "fqn": "medLabChemistry.chemAlbuminFlag"
    },
    {
      "elementKey": "chemAlbuminRange",
      "defaultValue": "34 – 50 g/L",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "53",
      "fqn": "medLabChemistry.chemAlbuminRange"
    },
    {
      "elementKey": "chemOpt1Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "54",
      "fqn": "medLabChemistry.chemOpt1Analyte"
    },
    {
      "elementKey": "chemOpt1Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "55",
      "fqn": "medLabChemistry.chemOpt1Result"
    },
    {
      "elementKey": "chemOpt1Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "56",
      "fqn": "medLabChemistry.chemOpt1Type"
    },
    {
      "elementKey": "chemOpt1RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "57",
      "fqn": "medLabChemistry.chemOpt1RI"
    },
    {
      "elementKey": "chemOpt2Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "58",
      "fqn": "medLabChemistry.chemOpt2Analyte"
    },
    {
      "elementKey": "chemOpt2Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "59",
      "fqn": "medLabChemistry.chemOpt2Result"
    },
    {
      "elementKey": "chemOpt2Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "60",
      "fqn": "medLabChemistry.chemOpt2Type"
    },
    {
      "elementKey": "chemOpt2RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "61",
      "fqn": "medLabChemistry.chemOpt2RI"
    },
    {
      "elementKey": "chemOpt3Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "62",
      "fqn": "medLabChemistry.chemOpt3Analyte"
    },
    {
      "elementKey": "chemOpt3Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "63",
      "fqn": "medLabChemistry.chemOpt3Result"
    },
    {
      "elementKey": "chemOpt3Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "64",
      "fqn": "medLabChemistry.chemOpt3Type"
    },
    {
      "elementKey": "chemOpt3RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "65",
      "fqn": "medLabChemistry.chemOpt3RI"
    },
    {
      "elementKey": "chemOpt4Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "66",
      "fqn": "medLabChemistry.chemOpt4Analyte"
    },
    {
      "elementKey": "chemOpt4Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "67",
      "fqn": "medLabChemistry.chemOpt4Result"
    },
    {
      "elementKey": "chemOpt4Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "68",
      "fqn": "medLabChemistry.chemOpt4Type"
    },
    {
      "elementKey": "chemOpt4RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "69",
      "fqn": "medLabChemistry.chemOpt4RI"
    },
    {
      "elementKey": "chemOpt5Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "70",
      "fqn": "medLabChemistry.chemOpt5Analyte"
    },
    {
      "elementKey": "chemOpt5Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "71",
      "fqn": "medLabChemistry.chemOpt5Result"
    },
    {
      "elementKey": "chemOpt5Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "72",
      "fqn": "medLabChemistry.chemOpt5Type"
    },
    {
      "elementKey": "chemOpt5RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "73",
      "fqn": "medLabChemistry.chemOpt5RI"
    },
    {
      "elementKey": "chemOpt6Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "74",
      "fqn": "medLabChemistry.chemOpt6Analyte"
    },
    {
      "elementKey": "chemOpt6Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "75",
      "fqn": "medLabChemistry.chemOpt6Result"
    },
    {
      "elementKey": "chemOpt6Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "76",
      "fqn": "medLabChemistry.chemOpt6Type"
    },
    {
      "elementKey": "chemOpt6RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "77",
      "fqn": "medLabChemistry.chemOpt6RI"
    },
    {
      "elementKey": "chemOpt7Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "78",
      "fqn": "medLabChemistry.chemOpt7Analyte"
    },
    {
      "elementKey": "chemOpt7Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "79",
      "fqn": "medLabChemistry.chemOpt7Result"
    },
    {
      "elementKey": "chemOpt7Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "80",
      "fqn": "medLabChemistry.chemOpt7Type"
    },
    {
      "elementKey": "chemOpt7RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "81",
      "fqn": "medLabChemistry.chemOpt7RI"
    },
    {
      "elementKey": "chemOpt8Analyte",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "82",
      "fqn": "medLabChemistry.chemOpt8Analyte"
    },
    {
      "elementKey": "chemOpt8Result",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "83",
      "fqn": "medLabChemistry.chemOpt8Result"
    },
    {
      "elementKey": "chemOpt8Type",
      "formIndex": "2",
      "formCss": "chem-results-lhclch",
      "inputType": "radioset",
      "options": [
        {
          "key": "CL",
          "text": "CL"
        },
        {
          "key": "L",
          "text": "L"
        },
        {
          "key": "H",
          "text": "H"
        },
        {
          "key": "CH",
          "text": "CH"
        }
      ],
      "tableColumn": "84",
      "fqn": "medLabChemistry.chemOpt8Type"
    },
    {
      "elementKey": "chemOpt8RI",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "85",
      "fqn": "medLabChemistry.chemOpt8RI"
    },
    {
      "elementKey": "urnInternal",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "86",
      "fqn": "medLabChemistry.urnInternal"
    },
    {
      "elementKey": "chemSpecimumType",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "serum",
          "text": "Serum"
        },
        {
          "key": "urinine",
          "text": "Urinine"
        },
        {
          "key": "wholeBlood",
          "text": "Whole blood"
        }
      ],
      "tableColumn": "87",
      "fqn": "medLabChemistry.chemSpecimumType"
    },
    {
      "elementKey": "chemSerumAppearance",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "88",
      "fqn": "medLabChemistry.chemSerumAppearance"
    },
    {
      "elementKey": "chemInterpLabel",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "medLabChemistry.chemInterpLabel"
    },
    {
      "elementKey": "chemInterpValue",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        },
        {
          "key": "na",
          "text": "N/A"
        }
      ],
      "tableColumn": "89",
      "fqn": "medLabChemistry.chemInterpValue"
    },
    {
      "elementKey": "label55-40",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-40"
    },
    {
      "elementKey": "chemInterpReason",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "90",
      "fqn": "medLabChemistry.chemInterpReason"
    },
    {
      "elementKey": "chemPhLabel",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "medLabChemistry.chemPhLabel"
    },
    {
      "elementKey": "chemPhValue",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        },
        {
          "key": "na",
          "text": "N/A"
        }
      ],
      "tableColumn": "91",
      "fqn": "medLabChemistry.chemPhValue"
    },
    {
      "elementKey": "label55-41",
      "formIndex": "2",
      "formCss": "grid-new-subsection grid-span-2-columns no-border",
      "inputType": "form_label",
      "fqn": "medLabChemistry.label55-41"
    }
  ],
  "pageElements": {
    "tableQcChemistry": {
      "elementKey": "tableQcChemistry",
      "pageElementIndex": "1",
      "pageKey": "medLabChemistry",
      "tableKey": "tableQcChemistry",
      "isTable": true,
      "form": {
        "elementKey": "tableQcChemistry",
        "label": "Chemistry QC",
        "addButtonText": "Create a chemistry QC report",
        "formKey": "tableQcChemistry",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabChemistry.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableQcChemistry_name",
              "tableQcChemistry_profession",
              "tableQcChemistry_day",
              "tableQcChemistry_time"
            ]
          },
          {
            "elementKey": "cGroup45-6",
            "formCss": "chem-qc-assay form-table-group",
            "fqn": "medLabChemistry.cGroup45-6",
            "gIndex": "2",
            "gChildren": [
              "label55-11",
              "label55-12",
              "chemQcAllAcceptable",
              "label55-13",
              "chemQcAllDuplicatesAcceptable",
              "label55-14",
              "chemQcAllPlausible",
              "label55-15",
              "chemQcAllCanBeReported",
              "label55-16",
              "label55-17"
            ]
          },
          {
            "elementKey": "cGroup45-7",
            "formCss": "chem-qc-trouble form-table-group",
            "fqn": "medLabChemistry.cGroup45-7",
            "gIndex": "3",
            "gChildren": [
              "label55-18",
              "label55-19",
              "label55-20",
              "label55-21",
              "label55-22",
              "chmQcAnalyte1",
              "chmQcLevel1",
              "chmQcRule1",
              "chmQcSeRe1",
              "chmQcDescribe1",
              "chmQcAnalyte2",
              "chmQcLevel2",
              "chmQcRule2",
              "chmQcSeRe2",
              "chmQcDescribe2",
              "chmQcAnalyte3",
              "chmQcLevel3",
              "chmQcRule3",
              "chmQcSeRe3",
              "chmQcDescribe3",
              "chmQcAnalyte4",
              "chmQcLevel4",
              "chmQcRule4",
              "chmQcSeRe4",
              "chmQcDescribe4"
            ]
          },
          {
            "elementKey": "cGroup46-5",
            "formCss": "med-lab-urin-qc-pending-table form-table-group",
            "fqn": "medLabChemistry.cGroup46-5",
            "gIndex": "4",
            "gChildren": [
              "label55-112",
              "label55-113",
              "label55-114",
              "cqcPendAnal_1",
              "cqcPendResult_1",
              "cqcPendAnal_2",
              "cqcPendResult_2",
              "cqcPendAnal_3",
              "cqcPendResult_3",
              "cqcPendAnal_4",
              "cqcPendResult_4"
            ]
          }
        ],
        "ehr_data": {
          "tableQcChemistry_name": "",
          "tableQcChemistry_profession": "",
          "tableQcChemistry_day": "",
          "tableQcChemistry_time": "",
          "chemQcAllAcceptable": "",
          "chemQcAllDuplicatesAcceptable": "",
          "chemQcAllPlausible": "",
          "chemQcAllCanBeReported": "",
          "chmQcAnalyte1": "",
          "chmQcLevel1": "",
          "chmQcRule1": "",
          "chmQcSeRe1": "",
          "chmQcDescribe1": "",
          "chmQcAnalyte2": "",
          "chmQcLevel2": "",
          "chmQcRule2": "",
          "chmQcSeRe2": "",
          "chmQcDescribe2": "",
          "chmQcAnalyte3": "",
          "chmQcLevel3": "",
          "chmQcRule3": "",
          "chmQcSeRe3": "",
          "chmQcDescribe3": "",
          "chmQcAnalyte4": "",
          "chmQcLevel4": "",
          "chmQcRule4": "",
          "chmQcSeRe4": "",
          "chmQcDescribe4": "",
          "cqcPendAnal_1": "",
          "cqcPendResult_1": "",
          "cqcPendAnal_2": "",
          "cqcPendResult_2": "",
          "cqcPendAnal_3": "",
          "cqcPendResult_3": "",
          "cqcPendAnal_4": "",
          "cqcPendResult_4": ""
        }
      },
      "fqn": "medLabChemistry.tableQcChemistry",
      "tableChildren": [
        "tableQcChemistry_id",
        "tableQcChemistry_day",
        "tableQcChemistry_time",
        "chemQcAllAcceptable",
        "chemQcAllDuplicatesAcceptable",
        "chemQcAllPlausible",
        "chemQcAllCanBeReported",
        "chmQcAnalyte1",
        "chmQcLevel1",
        "chmQcRule1",
        "chmQcSeRe1",
        "chmQcDescribe1",
        "chmQcAnalyte2",
        "chmQcLevel2",
        "chmQcRule2",
        "chmQcSeRe2",
        "chmQcDescribe2",
        "chmQcAnalyte3",
        "chmQcLevel3",
        "chmQcRule3",
        "chmQcSeRe3",
        "chmQcDescribe3",
        "chmQcAnalyte4",
        "chmQcLevel4",
        "chmQcRule4",
        "chmQcSeRe4",
        "chmQcDescribe4",
        "cqcPendAnal_1",
        "cqcPendResult_1",
        "cqcPendAnal_2",
        "cqcPendResult_2",
        "cqcPendAnal_3",
        "cqcPendResult_3",
        "cqcPendAnal_4",
        "cqcPendResult_4",
        "tableQcChemistry_name",
        "tableQcChemistry_profession"
      ],
      "hasRecHeader": true
    },
    "tableChem": {
      "elementKey": "tableChem",
      "pageElementIndex": "2",
      "pageKey": "medLabChemistry",
      "tableKey": "tableChem",
      "isTable": true,
      "form": {
        "elementKey": "tableChem",
        "label": "Chemistry analysis",
        "addButtonText": "Create a chemistry lab report",
        "formKey": "tableChem",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabChemistry.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableChem_name",
              "tableChem_profession",
              "tableChem_day",
              "tableChem_time"
            ]
          },
          {
            "elementKey": "cGroup45-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabChemistry.cGroup45-2",
            "gIndex": "2",
            "gChildren": [
              "chemAccession",
              "chemCollDay",
              "chemCollTime",
              "urinOrderPhys"
            ]
          },
          {
            "elementKey": "cGroup45-3",
            "formCss": "chem-results-table form-table-group",
            "fqn": "medLabChemistry.cGroup45-3",
            "gIndex": "3",
            "gChildren": [
              "label45-3-1",
              "label45-3-2",
              "label45-3-3",
              "label45-3-4",
              "label45-3-5",
              "chemSodium",
              "chemSodiumFlag",
              "chemSodiumRange",
              "label45-3-7",
              "chemPotassium",
              "chemPotassiumFlag",
              "chemPotassiumRange",
              "label45-3-9",
              "chemChloride",
              "chemChlorideFlag",
              "chemChlorideRange",
              "label45-3-11",
              "chemtCO2",
              "chemtCO2Flag",
              "chemtCO2Range",
              "label45-3-13",
              "chemAnionGap",
              "chemAnionGapFlag",
              "chemAnionGapRange",
              "label45-3-15",
              "chemOsmalityMeasured",
              "chemOsmalityMeasuredFlag",
              "chemOsmalityMeasuredRange",
              "label45-3-17",
              "chemOsmalityCalculated",
              "chemOsmalityCalculatedFlag",
              "chemOsmalityCalculatedRange",
              "label45-3-19",
              "chemOsmolarGap",
              "chemOsmolarGapFlag",
              "chemOsmolarGapRange",
              "label45-3-22",
              "chemGlugoseFastingRandom",
              "chemGlugoseFastingRandomFlag",
              "chemGlugoseFastingRandomRange",
              "label45-3-24",
              "chemCreatinine",
              "chemCreatinineFlag",
              "chemCreatinineRange",
              "label45-3-26",
              "chemUreaBun",
              "chemUreaBunFlag",
              "chemUreaBunRange",
              "label55-1",
              "chemCalcium",
              "chemCalciumFlag",
              "chemCalciumRange",
              "label55-3",
              "chemMagnesium",
              "chemMagnesiumFlag",
              "chemMagnesiumRange",
              "label55-5",
              "chemPhosphorus",
              "chemPhosphorusFlag",
              "chemPhosphorusRange",
              "label55-7",
              "chemTotalProtein",
              "chemTotalProteinFlag",
              "chemTotalProteinRange",
              "label55-9",
              "chemAlbumin",
              "chemAlbuminFlag",
              "chemAlbuminRange",
              "chemOpt1Analyte",
              "chemOpt1Result",
              "chemOpt1Type",
              "chemOpt1RI",
              "chemOpt2Analyte",
              "chemOpt2Result",
              "chemOpt2Type",
              "chemOpt2RI",
              "chemOpt3Analyte",
              "chemOpt3Result",
              "chemOpt3Type",
              "chemOpt3RI",
              "chemOpt4Analyte",
              "chemOpt4Result",
              "chemOpt4Type",
              "chemOpt4RI",
              "chemOpt5Analyte",
              "chemOpt5Result",
              "chemOpt5Type",
              "chemOpt5RI",
              "chemOpt6Analyte",
              "chemOpt6Result",
              "chemOpt6Type",
              "chemOpt6RI",
              "chemOpt7Analyte",
              "chemOpt7Result",
              "chemOpt7Type",
              "chemOpt7RI",
              "chemOpt8Analyte",
              "chemOpt8Result",
              "chemOpt8Type",
              "chemOpt8RI"
            ]
          },
          {
            "elementKey": "cGroup45-4",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabChemistry.cGroup45-4",
            "gIndex": "4",
            "gChildren": [
              "urnInternal"
            ]
          },
          {
            "elementKey": "cGroup45-5",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabChemistry.cGroup45-5",
            "gIndex": "5",
            "gChildren": [
              "chemSpecimumType",
              "chemSerumAppearance"
            ]
          },
          {
            "elementKey": "cGroup45-4",
            "formCss": "grid-left-to-right-2",
            "fqn": "medLabChemistry.cGroup45-4",
            "gIndex": "6",
            "gChildren": [
              "chemInterpLabel",
              "chemInterpValue",
              "label55-40",
              "chemInterpReason"
            ]
          },
          {
            "elementKey": "cGroup45-4",
            "formCss": "grid-left-to-right-2",
            "fqn": "medLabChemistry.cGroup45-4",
            "gIndex": "7",
            "gChildren": [
              "chemPhLabel",
              "chemPhValue",
              "label55-41"
            ]
          }
        ],
        "ehr_data": {
          "tableChem_name": "",
          "tableChem_profession": "",
          "tableChem_day": "",
          "tableChem_time": "",
          "chemAccession": "",
          "chemCollDay": "Today",
          "chemCollTime": "",
          "urinOrderPhys": "",
          "chemSodium": "",
          "chemSodiumFlag": "",
          "chemSodiumRange": "135 – 145 mmol/L",
          "chemPotassium": "",
          "chemPotassiumFlag": "",
          "chemPotassiumRange": "3.5–5.1 mmol/L",
          "chemChloride": "",
          "chemChlorideFlag": "",
          "chemChlorideRange": "95 – 107 mmol/L",
          "chemtCO2": "",
          "chemtCO2Flag": "",
          "chemtCO2Range": "22 – 31 mmol/L",
          "chemAnionGap": "",
          "chemAnionGapFlag": "",
          "chemAnionGapRange": "8 – 16 mmol/L",
          "chemOsmalityMeasured": "",
          "chemOsmalityMeasuredFlag": "",
          "chemOsmalityMeasuredRange": "275 – 295 mOsm/kg",
          "chemOsmalityCalculated": "",
          "chemOsmalityCalculatedFlag": "",
          "chemOsmalityCalculatedRange": "275 – 295 mOsm/kg",
          "chemOsmolarGap": "",
          "chemOsmolarGapFlag": "",
          "chemOsmolarGapRange": "≤ 10 mOsm/kg",
          "chemGlugoseFastingRandom": "",
          "chemGlugoseFastingRandomFlag": "",
          "chemGlugoseFastingRandomRange": "F(3.6-6.0) R(3.6-11.0)mmol/L",
          "chemCreatinine": "",
          "chemCreatinineFlag": "",
          "chemCreatinineRange": "M(60-115) F(40-95) umol/L",
          "chemUreaBun": "",
          "chemUreaBunFlag": "",
          "chemUreaBunRange": "2.0 – 8.2 mmol/L",
          "chemCalcium": "",
          "chemCalciumFlag": "",
          "chemCalciumRange": "2.10 – 2.55 mmol/L",
          "chemMagnesium": "",
          "chemMagnesiumFlag": "",
          "chemMagnesiumRange": "0.70 – 1.10 mmol/L",
          "chemPhosphorus": "",
          "chemPhosphorusFlag": "",
          "chemPhosphorusRange": "0.80 – 1.45 mmol/L",
          "chemTotalProtein": "",
          "chemTotalProteinFlag": "",
          "chemTotalProteinRange": "62 – 82 g/L",
          "chemAlbumin": "",
          "chemAlbuminFlag": "",
          "chemAlbuminRange": "34 – 50 g/L",
          "chemOpt1Analyte": "",
          "chemOpt1Result": "",
          "chemOpt1Type": "",
          "chemOpt1RI": "",
          "chemOpt2Analyte": "",
          "chemOpt2Result": "",
          "chemOpt2Type": "",
          "chemOpt2RI": "",
          "chemOpt3Analyte": "",
          "chemOpt3Result": "",
          "chemOpt3Type": "",
          "chemOpt3RI": "",
          "chemOpt4Analyte": "",
          "chemOpt4Result": "",
          "chemOpt4Type": "",
          "chemOpt4RI": "",
          "chemOpt5Analyte": "",
          "chemOpt5Result": "",
          "chemOpt5Type": "",
          "chemOpt5RI": "",
          "chemOpt6Analyte": "",
          "chemOpt6Result": "",
          "chemOpt6Type": "",
          "chemOpt6RI": "",
          "chemOpt7Analyte": "",
          "chemOpt7Result": "",
          "chemOpt7Type": "",
          "chemOpt7RI": "",
          "chemOpt8Analyte": "",
          "chemOpt8Result": "",
          "chemOpt8Type": "",
          "chemOpt8RI": "",
          "urnInternal": "",
          "chemSpecimumType": "",
          "chemSerumAppearance": "",
          "chemInterpValue": "",
          "chemInterpReason": "",
          "chemPhValue": ""
        }
      },
      "fqn": "medLabChemistry.tableChem",
      "tableChildren": [
        "tableChem_id",
        "tableChem_day",
        "tableChem_time",
        "chemAccession",
        "chemCollDay",
        "chemCollTime",
        "urinOrderPhys",
        "chemSodium",
        "chemSodiumFlag",
        "chemSodiumRange",
        "chemPotassium",
        "chemPotassiumFlag",
        "chemPotassiumRange",
        "chemChloride",
        "chemChlorideFlag",
        "chemChlorideRange",
        "chemtCO2",
        "chemtCO2Flag",
        "chemtCO2Range",
        "chemAnionGap",
        "chemAnionGapFlag",
        "chemAnionGapRange",
        "chemOsmalityMeasured",
        "chemOsmalityMeasuredFlag",
        "chemOsmalityMeasuredRange",
        "chemOsmalityCalculated",
        "chemOsmalityCalculatedFlag",
        "chemOsmalityCalculatedRange",
        "chemOsmolarGap",
        "chemOsmolarGapFlag",
        "chemOsmolarGapRange",
        "chemGlugoseFastingRandom",
        "chemGlugoseFastingRandomFlag",
        "chemGlugoseFastingRandomRange",
        "chemCreatinine",
        "chemCreatinineFlag",
        "chemCreatinineRange",
        "chemUreaBun",
        "chemUreaBunFlag",
        "chemUreaBunRange",
        "chemCalcium",
        "chemCalciumFlag",
        "chemCalciumRange",
        "chemMagnesium",
        "chemMagnesiumFlag",
        "chemMagnesiumRange",
        "chemPhosphorus",
        "chemPhosphorusFlag",
        "chemPhosphorusRange",
        "chemTotalProtein",
        "chemTotalProteinFlag",
        "chemTotalProteinRange",
        "chemAlbumin",
        "chemAlbuminFlag",
        "chemAlbuminRange",
        "chemOpt1Analyte",
        "chemOpt1Result",
        "chemOpt1Type",
        "chemOpt1RI",
        "chemOpt2Analyte",
        "chemOpt2Result",
        "chemOpt2Type",
        "chemOpt2RI",
        "chemOpt3Analyte",
        "chemOpt3Result",
        "chemOpt3Type",
        "chemOpt3RI",
        "chemOpt4Analyte",
        "chemOpt4Result",
        "chemOpt4Type",
        "chemOpt4RI",
        "chemOpt5Analyte",
        "chemOpt5Result",
        "chemOpt5Type",
        "chemOpt5RI",
        "chemOpt6Analyte",
        "chemOpt6Result",
        "chemOpt6Type",
        "chemOpt6RI",
        "chemOpt7Analyte",
        "chemOpt7Result",
        "chemOpt7Type",
        "chemOpt7RI",
        "chemOpt8Analyte",
        "chemOpt8Result",
        "chemOpt8Type",
        "chemOpt8RI",
        "urnInternal",
        "chemSpecimumType",
        "chemSerumAppearance",
        "chemInterpValue",
        "chemInterpReason",
        "chemPhValue",
        "tableChem_name",
        "tableChem_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS