/* eslint-disable */
const DEFS = {
  "pageDataKey": "labRequisitions",
  "pIndex": "24",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labRequisitions.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labRequisitions.time"
    },
    {
      "elementKey": "patientName",
      "formIndex": "1",
      "inputType": "ehrPatientName",
      "tableColumn": "2",
      "fqn": "labRequisitions.patientName"
    },
    {
      "elementKey": "patientHealthNumber",
      "formIndex": "1",
      "inputType": "ehrPHN",
      "tableColumn": "3",
      "fqn": "labRequisitions.patientHealthNumber"
    },
    {
      "elementKey": "dateOfBirth",
      "formIndex": "1",
      "inputType": "ehrDOB",
      "tableColumn": "4",
      "fqn": "labRequisitions.dateOfBirth"
    },
    {
      "elementKey": "location",
      "formIndex": "1",
      "inputType": "ehrLocation",
      "tableColumn": "5",
      "fqn": "labRequisitions.location"
    },
    {
      "elementKey": "dateRequired",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "6",
      "fqn": "labRequisitions.dateRequired"
    },
    {
      "elementKey": "reason",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "labRequisitions.reason"
    },
    {
      "elementKey": "hematology",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "cbc",
          "text": "CBC"
        },
        {
          "key": "cbcWithDifferential",
          "text": "CBC with differential"
        },
        {
          "key": "hemoglobin",
          "text": "Hemoglobin"
        },
        {
          "key": "hemoglobinA1C",
          "text": "Hemoglobin A1-C"
        }
      ],
      "tableColumn": "8",
      "fqn": "labRequisitions.hematology"
    },
    {
      "elementKey": "electrolytes",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "k",
          "text": "K"
        },
        {
          "key": "na",
          "text": "Na"
        },
        {
          "key": "chloride",
          "text": "Chloride"
        },
        {
          "key": "bicarbonate",
          "text": "Bicarbonate"
        },
        {
          "key": "calcium",
          "text": "Calcium"
        },
        {
          "key": "ionizedCalcium",
          "text": "Ionized Calcium"
        }
      ],
      "tableColumn": "9",
      "fqn": "labRequisitions.electrolytes"
    },
    {
      "elementKey": "bloodGas",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "arterialBloodGas",
          "text": "Arterial blood gas"
        },
        {
          "key": "venousBloodGas",
          "text": "Venous blood gas"
        },
        {
          "key": "fetalCordBloodGas",
          "text": "Fetal cord blood gas"
        }
      ],
      "tableColumn": "10",
      "fqn": "labRequisitions.bloodGas"
    },
    {
      "elementKey": "renalProfile",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "bun",
          "text": "BUN"
        },
        {
          "key": "creatinine",
          "text": "Creatinine"
        }
      ],
      "tableColumn": "11",
      "fqn": "labRequisitions.renalProfile"
    },
    {
      "elementKey": "liverFunction",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "ast",
          "text": "AST"
        },
        {
          "key": "ggt",
          "text": "GGT"
        },
        {
          "key": "alt",
          "text": "ALT"
        },
        {
          "key": "totalBirilubin",
          "text": "Total Birilubin"
        },
        {
          "key": "directBirilubin",
          "text": "Direct Birilubin"
        }
      ],
      "tableColumn": "12",
      "fqn": "labRequisitions.liverFunction"
    },
    {
      "elementKey": "urineAnalysis",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "r&m",
          "text": "R&M"
        },
        {
          "key": "urineElectrolytes",
          "text": "Urine electrolytes"
        },
        {
          "key": "urineCreatinine",
          "text": "Urine creatinine"
        }
      ],
      "tableColumn": "13",
      "fqn": "labRequisitions.urineAnalysis"
    },
    {
      "elementKey": "microbiologyCulture",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "culture",
          "text": "culture"
        },
        {
          "key": "senstitivity",
          "text": "senstitivity"
        }
      ],
      "tableColumn": "14",
      "fqn": "labRequisitions.microbiologyCulture"
    },
    {
      "elementKey": "microbiologyLocation",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Wound",
          "text": "Wound"
        },
        {
          "key": "Sputum",
          "text": "Sputum"
        },
        {
          "key": "Blood",
          "text": "Blood"
        },
        {
          "key": "Urine",
          "text": "Urine"
        },
        {
          "key": "Stool",
          "text": "Stool"
        }
      ],
      "tableColumn": "15",
      "fqn": "labRequisitions.microbiologyLocation"
    },
    {
      "elementKey": "histologyTissueType",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Skin",
          "text": "Skin"
        },
        {
          "key": "Blood",
          "text": "Blood"
        },
        {
          "key": "Organ",
          "text": "Organ"
        }
      ],
      "tableColumn": "16",
      "fqn": "labRequisitions.histologyTissueType"
    },
    {
      "elementKey": "histologyLocation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "labRequisitions.histologyLocation"
    },
    {
      "elementKey": "requisitions_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.name"
    },
    {
      "elementKey": "requisitions_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "requisitions_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.profession"
    },
    {
      "elementKey": "requisitions_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labRequisitions.day"
    },
    {
      "elementKey": "requisitions_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labRequisitions.time"
    },
    {
      "elementKey": "req_id",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "labRequisitions.req_id"
    },
    {
      "elementKey": "req_dateRequired",
      "formIndex": "2",
      "inputType": "visitDay",
      "tableColumn": "2",
      "validation": "visitDay",
      "fqn": "labRequisitions.req_dateRequired"
    },
    {
      "elementKey": "req_schedule",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "stat",
          "text": "Stat"
        },
        {
          "key": "urgent",
          "text": "Urgent"
        },
        {
          "key": "routine",
          "text": "Routine"
        }
      ],
      "tableColumn": "3",
      "fqn": "labRequisitions.req_schedule"
    },
    {
      "elementKey": "req_OrderPhys",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "labRequisitions.req_OrderPhys"
    },
    {
      "elementKey": "req_CopyPhys",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "labRequisitions.req_CopyPhys"
    },
    {
      "elementKey": "req_reason",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "labRequisitions.req_reason"
    },
    {
      "elementKey": "req_hematology",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "CBC",
          "text": "CBC"
        },
        {
          "key": "CBC_Diff",
          "text": "CBC with differential"
        }
      ],
      "tableColumn": "5",
      "fqn": "labRequisitions.req_hematology"
    },
    {
      "elementKey": "req_cogagulation",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "fib",
          "text": "Fibrinogen"
        },
        {
          "key": "ddim",
          "text": "D-Dimer"
        },
        {
          "key": "inr",
          "text": "International normalised ratio (INR)"
        },
        {
          "key": "aptt",
          "text": "Activated partial thromboplastin time (PTT)"
        },
        {
          "key": "ptt",
          "text": "Partial thromboplastin time (PTT)"
        }
      ],
      "tableColumn": "6",
      "fqn": "labRequisitions.req_cogagulation"
    },
    {
      "elementKey": "req_tropinin",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "bnp",
          "text": "Brain natriuretic peptide(BNP)"
        },
        {
          "key": "tropI",
          "text": "Troponin I"
        },
        {
          "key": "tropT",
          "text": "Troponin T (TnT)"
        },
        {
          "key": "tropH",
          "text": "High-sensitivity troponin T"
        }
      ],
      "tableColumn": "7",
      "fqn": "labRequisitions.req_tropinin"
    },
    {
      "elementKey": "req_electrolytes",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "albumin",
          "text": "Albumin"
        },
        {
          "key": "hco3",
          "text": "Bicarbonate (HCO3)"
        },
        {
          "key": "CA",
          "text": "Calcium (Ca)"
        },
        {
          "key": "Ca_Ion",
          "text": "Calcium ionized"
        },
        {
          "key": "chloride",
          "text": "Chloride (Cl)"
        },
        {
          "key": "b12",
          "text": "B12"
        },
        {
          "key": "lipidProfileFasting",
          "text": "Lipid profile fasting - Total cholesterol, HDL, LDL, triglycerides"
        },
        {
          "key": "lipidProfileRandom",
          "text": "Lipid profile random - Total cholesterol, HDL, LDL, triglycerides"
        },
        {
          "key": "sCr",
          "text": "Serum Creatinine (sCr)"
        },
        {
          "key": "GFR",
          "text": "Glomerular Filtration Rate (GFR)"
        },
        {
          "key": "ck",
          "text": "Creatine kinase (CK)"
        },
        {
          "key": "ferritin",
          "text": "Ferritin"
        },
        {
          "key": "glucoseF",
          "text": "Glucose fasting"
        },
        {
          "key": "glucoseR",
          "text": "Glucose random"
        },
        {
          "key": "HbA1C",
          "text": "Hemoglobin A1-C"
        },
        {
          "key": "lactate",
          "text": "Lactate (on ice)"
        },
        {
          "key": "mg",
          "text": "Magnesium (Mg)"
        },
        {
          "key": "po4",
          "text": "Phosphate (PO4)"
        },
        {
          "key": "k",
          "text": "Potassium (K)"
        },
        {
          "key": "protein",
          "text": "Protein total"
        },
        {
          "key": "na",
          "text": "Sodium (Na)"
        },
        {
          "key": "urea",
          "text": "Urea"
        }
      ],
      "tableColumn": "8",
      "fqn": "labRequisitions.req_electrolytes"
    },
    {
      "elementKey": "req_liverFunction",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "alt",
          "text": "Alanine transaminase (ALT)"
        },
        {
          "key": "alp",
          "text": "Alkaline phosphatase (ALP)"
        },
        {
          "key": "ast",
          "text": "Aspartate transferase (AST)"
        },
        {
          "key": "bilirubinD",
          "text": "Bilirubin direct"
        },
        {
          "key": "bilirubinT",
          "text": "Bilirubin total"
        },
        {
          "key": "ggt",
          "text": "Gamma glutamyl transferase (GGT)"
        },
        {
          "key": "ldh",
          "text": "Lactate dehydrogenase (LDH)"
        },
        {
          "key": "lipase",
          "text": "Lipase"
        }
      ],
      "tableColumn": "9",
      "fqn": "labRequisitions.req_liverFunction"
    },
    {
      "elementKey": "req_renalProfile",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "bun",
          "text": "Blood urea nitrogen (BUN)"
        },
        {
          "key": "cr",
          "text": "Creatinine"
        }
      ],
      "tableColumn": "10",
      "fqn": "labRequisitions.req_renalProfile"
    },
    {
      "elementKey": "req_bloodGas",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "abg",
          "text": "Arterial blood gas (ABG)"
        },
        {
          "key": "fcbg",
          "text": "Venous blood gas (VBG)"
        },
        {
          "key": "vbg",
          "text": "Fetal cord blood gas (FCBG)"
        }
      ],
      "tableColumn": "11",
      "fqn": "labRequisitions.req_bloodGas"
    },
    {
      "elementKey": "req_urineAnalysis",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "acr",
          "text": "Albumin/creatinine ratio (ACR)"
        },
        {
          "key": "rm",
          "text": "Routine and microscopic (R&M)"
        },
        {
          "key": "preg",
          "text": "Pregnancy"
        },
        {
          "key": "u_el",
          "text": "Urine electrolytes"
        },
        {
          "key": "u_cr",
          "text": "Urine creatinine"
        }
      ],
      "tableColumn": "12",
      "fqn": "labRequisitions.req_urineAnalysis"
    },
    {
      "elementKey": "req_comments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "13",
      "fqn": "labRequisitions.req_comments"
    },
    {
      "elementKey": "histologyRequisitions_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.name"
    },
    {
      "elementKey": "histologyRequisitions_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "histologyRequisitions_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.profession"
    },
    {
      "elementKey": "histologyRequisitions_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labRequisitions.day"
    },
    {
      "elementKey": "histologyRequisitions_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labRequisitions.time"
    },
    {
      "elementKey": "reqH_id",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "labRequisitions.reqH_id"
    },
    {
      "elementKey": "reqH_dateRequired",
      "formIndex": "3",
      "inputType": "visitDay",
      "tableColumn": "2",
      "validation": "visitDay",
      "fqn": "labRequisitions.reqH_dateRequired"
    },
    {
      "elementKey": "reqH_schedule",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "stat",
          "text": "Stat"
        },
        {
          "key": "urgent",
          "text": "Urgent"
        },
        {
          "key": "routine",
          "text": "Routine"
        }
      ],
      "tableColumn": "3",
      "fqn": "labRequisitions.reqH_schedule"
    },
    {
      "elementKey": "reqH_OrderPhys",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "labRequisitions.reqH_OrderPhys"
    },
    {
      "elementKey": "reqH_CopyPhys",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "labRequisitions.reqH_CopyPhys"
    },
    {
      "elementKey": "reqH_reason",
      "formIndex": "3",
      "formCss": "grid-span-3-columns",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "labRequisitions.reqH_reason"
    },
    {
      "elementKey": "reqH_type",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "blood",
          "text": "Blood"
        },
        {
          "key": "organ",
          "text": "Organ"
        },
        {
          "key": "skin",
          "text": "Skin"
        }
      ],
      "tableColumn": "4",
      "fqn": "labRequisitions.reqH_type"
    },
    {
      "elementKey": "reqH_location",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "labRequisitions.reqH_location"
    },
    {
      "elementKey": "reqH_comments",
      "formIndex": "3",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "labRequisitions.reqH_comments"
    },
    {
      "elementKey": "med_image_req_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.name"
    },
    {
      "elementKey": "med_image_req_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "med_image_req_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.profession"
    },
    {
      "elementKey": "med_image_req_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labRequisitions.day"
    },
    {
      "elementKey": "med_image_req_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labRequisitions.time"
    },
    {
      "elementKey": "reqMi_id",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "labRequisitions.reqMi_id"
    },
    {
      "elementKey": "reqMi_dateRequired",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "2",
      "validation": "visitDay",
      "fqn": "labRequisitions.reqMi_dateRequired"
    },
    {
      "elementKey": "reqMi_schedule",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "stat",
          "text": "Stat"
        },
        {
          "key": "urgent",
          "text": "Urgent"
        },
        {
          "key": "routine",
          "text": "Routine"
        }
      ],
      "tableColumn": "3",
      "fqn": "labRequisitions.reqMi_schedule"
    },
    {
      "elementKey": "reqMi_OrderPhys",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "labRequisitions.reqMi_OrderPhys"
    },
    {
      "elementKey": "reqMi_CopyPhys",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "labRequisitions.reqMi_CopyPhys"
    },
    {
      "elementKey": "reqMi_reason",
      "formIndex": "4",
      "formCss": "grid-span-3-columns",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "labRequisitions.reqMi_reason"
    },
    {
      "elementKey": "reqMi_type",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "angio",
          "text": "Angiogram"
        },
        {
          "key": "ecg",
          "text": "Echocardiogram"
        },
        {
          "key": "ekg",
          "text": "Electrocardiogram"
        },
        {
          "key": "ct",
          "text": "CT scan"
        },
        {
          "key": "mri",
          "text": "MRI"
        },
        {
          "key": "nuclear",
          "text": "Nuclear medicine"
        },
        {
          "key": "ultra",
          "text": "Ultrasound"
        },
        {
          "key": "xray",
          "text": "X-ray"
        }
      ],
      "tableColumn": "2",
      "fqn": "labRequisitions.reqMi_type"
    },
    {
      "elementKey": "reqMi_Location",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "ab",
          "text": "Abdomen"
        },
        {
          "key": "armL",
          "text": "Arm left"
        },
        {
          "key": "armR",
          "text": "Arm right"
        },
        {
          "key": "chest",
          "text": "Chest"
        },
        {
          "key": "head",
          "text": "Head"
        },
        {
          "key": "heart",
          "text": "Heart"
        },
        {
          "key": "neck",
          "text": "Neck"
        },
        {
          "key": "legL",
          "text": "Leg left"
        },
        {
          "key": "legR",
          "text": "Leg right"
        },
        {
          "key": "pelvis",
          "text": "Pelvis"
        }
      ],
      "tableColumn": "3",
      "fqn": "labRequisitions.reqMi_Location"
    },
    {
      "elementKey": "reqMi_mobile",
      "formIndex": "4",
      "formCss": "grid-new-subsection",
      "inputType": "checkbox",
      "tableColumn": "4",
      "fqn": "labRequisitions.reqMi_mobile"
    },
    {
      "elementKey": "reqMi_comments",
      "formIndex": "4",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "5",
      "fqn": "labRequisitions.reqMi_comments"
    },
    {
      "elementKey": "microRequisitions_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.name"
    },
    {
      "elementKey": "microRequisitions_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "microRequisitions_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "labRequisitions.profession"
    },
    {
      "elementKey": "microRequisitions_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "labRequisitions.day"
    },
    {
      "elementKey": "microRequisitions_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "labRequisitions.time"
    },
    {
      "elementKey": "reqMicro_id",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "labRequisitions.reqMicro_id"
    },
    {
      "elementKey": "reqMicro_dateRequired",
      "formIndex": "5",
      "inputType": "visitDay",
      "tableColumn": "2",
      "validation": "visitDay",
      "fqn": "labRequisitions.reqMicro_dateRequired"
    },
    {
      "elementKey": "reqMicro_schedule",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "stat",
          "text": "Stat"
        },
        {
          "key": "urgent",
          "text": "Urgent"
        },
        {
          "key": "routine",
          "text": "Routine"
        }
      ],
      "tableColumn": "3",
      "fqn": "labRequisitions.reqMicro_schedule"
    },
    {
      "elementKey": "reqMicro_OrderPhys",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "labRequisitions.reqMicro_OrderPhys"
    },
    {
      "elementKey": "reqMicro_CopyPhys",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "labRequisitions.reqMicro_CopyPhys"
    },
    {
      "elementKey": "reqMicro_reason",
      "formIndex": "5",
      "formCss": "grid-span-3-columns",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "labRequisitions.reqMicro_reason"
    },
    {
      "elementKey": "reqMicro_culture",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "cs",
          "text": "Culture & sensitivity"
        },
        {
          "key": "parasite",
          "text": "Parisitology"
        }
      ],
      "tableColumn": "4",
      "fqn": "labRequisitions.reqMicro_culture"
    },
    {
      "elementKey": "reqMicro_location",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "blood",
          "text": "Blood"
        },
        {
          "key": "sputum",
          "text": "Sputum"
        },
        {
          "key": "stool",
          "text": "Stool"
        },
        {
          "key": "urine",
          "text": "Urine"
        },
        {
          "key": "wound",
          "text": "Wound"
        }
      ],
      "tableColumn": "5",
      "fqn": "labRequisitions.reqMicro_location"
    },
    {
      "elementKey": "reqMicro_comments",
      "formIndex": "5",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "labRequisitions.reqMicro_comments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "labRequisitions",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Lab requisitions v1",
        "addButtonText": "Add a lab requisition assessment",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labRequisitions.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "demographics",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.demographics",
            "gIndex": "2",
            "gChildren": [
              "patientName",
              "patientHealthNumber",
              "dateOfBirth",
              "location",
              "dateRequired",
              "reason",
              "hematology",
              "electrolytes",
              "bloodGas",
              "renalProfile",
              "liverFunction",
              "urineAnalysis"
            ]
          },
          {
            "elementKey": "microbiology",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.microbiology",
            "gIndex": "3",
            "gChildren": [
              "microbiologyCulture",
              "microbiologyLocation"
            ]
          },
          {
            "elementKey": "histology",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.histology",
            "gIndex": "4",
            "gChildren": [
              "histologyTissueType",
              "histologyLocation"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "patientName": "",
          "patientHealthNumber": "",
          "dateOfBirth": "",
          "location": "",
          "dateRequired": "",
          "reason": "",
          "hematology": "",
          "electrolytes": "",
          "bloodGas": "",
          "renalProfile": "",
          "liverFunction": "",
          "urineAnalysis": "",
          "microbiologyCulture": "",
          "microbiologyLocation": "",
          "histologyTissueType": "",
          "histologyLocation": ""
        }
      },
      "fqn": "labRequisitions.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "patientName",
        "patientHealthNumber",
        "dateOfBirth",
        "location",
        "dateRequired",
        "reason",
        "hematology",
        "electrolytes",
        "bloodGas",
        "renalProfile",
        "liverFunction",
        "urineAnalysis",
        "microbiologyCulture",
        "microbiologyLocation",
        "histologyTissueType",
        "histologyLocation",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "requisitions": {
      "elementKey": "requisitions",
      "pageElementIndex": "2",
      "pageKey": "labRequisitions",
      "tableKey": "requisitions",
      "isTable": true,
      "form": {
        "elementKey": "requisitions",
        "label": "Lab requisitions",
        "addButtonText": "Add a lab requisition",
        "formOption": "transpose",
        "formKey": "requisitions",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labRequisitions.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "requisitions_name",
              "requisitions_profession",
              "requisitions_day",
              "requisitions_time"
            ]
          },
          {
            "elementKey": "req_notice",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.req_notice",
            "gIndex": "2",
            "gChildren": [
              "req_id",
              "req_dateRequired",
              "req_schedule",
              "req_OrderPhys",
              "req_CopyPhys",
              "req_reason"
            ]
          },
          {
            "elementKey": "cGroup24-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-3",
            "gIndex": "3",
            "gChildren": [
              "req_hematology",
              "req_cogagulation"
            ]
          },
          {
            "elementKey": "cGroup24-4",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-4",
            "gIndex": "4",
            "gChildren": [
              "req_tropinin",
              "req_electrolytes",
              "req_liverFunction",
              "req_renalProfile"
            ]
          },
          {
            "elementKey": "cGroup24-5",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-5",
            "gIndex": "5",
            "gChildren": [
              "req_bloodGas"
            ]
          },
          {
            "elementKey": "cGroup24-6",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-6",
            "gIndex": "6",
            "gChildren": [
              "req_urineAnalysis"
            ]
          },
          {
            "elementKey": "cGroup24-7",
            "formCss": "grid-left-to-right-1",
            "fqn": "labRequisitions.cGroup24-7",
            "gIndex": "7",
            "gChildren": [
              "req_comments"
            ]
          }
        ],
        "ehr_data": {
          "requisitions_name": "",
          "requisitions_profession": "",
          "requisitions_day": "",
          "requisitions_time": "",
          "req_id": "",
          "req_dateRequired": "",
          "req_schedule": "",
          "req_OrderPhys": "",
          "req_CopyPhys": "",
          "req_reason": "",
          "req_hematology": "",
          "req_cogagulation": "",
          "req_tropinin": "",
          "req_electrolytes": "",
          "req_liverFunction": "",
          "req_renalProfile": "",
          "req_bloodGas": "",
          "req_urineAnalysis": "",
          "req_comments": ""
        }
      },
      "fqn": "labRequisitions.requisitions",
      "tableChildren": [
        "requisitions_id",
        "requisitions_day",
        "requisitions_time",
        "req_id",
        "req_dateRequired",
        "req_schedule",
        "req_OrderPhys",
        "req_CopyPhys",
        "req_reason",
        "req_hematology",
        "req_cogagulation",
        "req_tropinin",
        "req_electrolytes",
        "req_liverFunction",
        "req_renalProfile",
        "req_bloodGas",
        "req_urineAnalysis",
        "req_comments",
        "requisitions_name",
        "requisitions_profession"
      ],
      "hasRecHeader": true
    },
    "histologyRequisitions": {
      "elementKey": "histologyRequisitions",
      "pageElementIndex": "3",
      "pageKey": "labRequisitions",
      "tableKey": "histologyRequisitions",
      "isTable": true,
      "form": {
        "elementKey": "histologyRequisitions",
        "label": "Histology",
        "addButtonText": "Add a histology requisition",
        "formOption": "transpose",
        "formKey": "histologyRequisitions",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labRequisitions.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "histologyRequisitions_name",
              "histologyRequisitions_profession",
              "histologyRequisitions_day",
              "histologyRequisitions_time"
            ]
          },
          {
            "elementKey": "cGroup24-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-2",
            "gIndex": "2",
            "gChildren": [
              "reqH_id",
              "reqH_dateRequired",
              "reqH_schedule",
              "reqH_OrderPhys",
              "reqH_CopyPhys",
              "reqH_reason"
            ]
          },
          {
            "elementKey": "cGroup24-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-3",
            "gIndex": "3",
            "gChildren": [
              "reqH_type",
              "reqH_location",
              "reqH_comments"
            ]
          }
        ],
        "ehr_data": {
          "histologyRequisitions_name": "",
          "histologyRequisitions_profession": "",
          "histologyRequisitions_day": "",
          "histologyRequisitions_time": "",
          "reqH_id": "",
          "reqH_dateRequired": "",
          "reqH_schedule": "",
          "reqH_OrderPhys": "",
          "reqH_CopyPhys": "",
          "reqH_reason": "",
          "reqH_type": "",
          "reqH_location": "",
          "reqH_comments": ""
        }
      },
      "fqn": "labRequisitions.histologyRequisitions",
      "tableChildren": [
        "histologyRequisitions_id",
        "histologyRequisitions_day",
        "histologyRequisitions_time",
        "reqH_id",
        "reqH_dateRequired",
        "reqH_schedule",
        "reqH_OrderPhys",
        "reqH_CopyPhys",
        "reqH_reason",
        "reqH_type",
        "reqH_location",
        "reqH_comments",
        "histologyRequisitions_name",
        "histologyRequisitions_profession"
      ],
      "hasRecHeader": true
    },
    "med_image_req": {
      "elementKey": "med_image_req",
      "pageElementIndex": "4",
      "pageKey": "labRequisitions",
      "tableKey": "med_image_req",
      "isTable": true,
      "form": {
        "elementKey": "med_image_req",
        "label": "Medical Imaging",
        "addButtonText": "Add a medical imaging requisition",
        "formOption": "transpose",
        "formKey": "med_image_req",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labRequisitions.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "med_image_req_name",
              "med_image_req_profession",
              "med_image_req_day",
              "med_image_req_time"
            ]
          },
          {
            "elementKey": "reqMi_notice",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.reqMi_notice",
            "gIndex": "2",
            "gChildren": [
              "reqMi_id",
              "reqMi_dateRequired",
              "reqMi_schedule",
              "reqMi_OrderPhys",
              "reqMi_CopyPhys",
              "reqMi_reason"
            ]
          },
          {
            "elementKey": "cGroup24-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-3",
            "gIndex": "3",
            "gChildren": [
              "reqMi_type",
              "reqMi_Location",
              "reqMi_mobile",
              "reqMi_comments"
            ]
          }
        ],
        "ehr_data": {
          "med_image_req_name": "",
          "med_image_req_profession": "",
          "med_image_req_day": "",
          "med_image_req_time": "",
          "reqMi_id": "",
          "reqMi_dateRequired": "",
          "reqMi_schedule": "",
          "reqMi_OrderPhys": "",
          "reqMi_CopyPhys": "",
          "reqMi_reason": "",
          "reqMi_type": "",
          "reqMi_Location": "",
          "reqMi_mobile": "",
          "reqMi_comments": ""
        }
      },
      "fqn": "labRequisitions.med_image_req",
      "tableChildren": [
        "med_image_req_id",
        "med_image_req_day",
        "med_image_req_time",
        "reqMi_id",
        "reqMi_dateRequired",
        "reqMi_schedule",
        "reqMi_OrderPhys",
        "reqMi_CopyPhys",
        "reqMi_reason",
        "reqMi_type",
        "reqMi_Location",
        "reqMi_mobile",
        "reqMi_comments",
        "med_image_req_name",
        "med_image_req_profession"
      ],
      "hasRecHeader": true
    },
    "microRequisitions": {
      "elementKey": "microRequisitions",
      "pageElementIndex": "5",
      "pageKey": "labRequisitions",
      "tableKey": "microRequisitions",
      "isTable": true,
      "form": {
        "elementKey": "microRequisitions",
        "label": "Microbiology",
        "addButtonText": "Add a microbiology requisition",
        "formOption": "transpose",
        "formKey": "microRequisitions",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "labRequisitions.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "microRequisitions_name",
              "microRequisitions_profession",
              "microRequisitions_day",
              "microRequisitions_time"
            ]
          },
          {
            "elementKey": "cGroup24-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-2",
            "gIndex": "2",
            "gChildren": [
              "reqMicro_id",
              "reqMicro_dateRequired",
              "reqMicro_schedule",
              "reqMicro_OrderPhys",
              "reqMicro_CopyPhys",
              "reqMicro_reason"
            ]
          },
          {
            "elementKey": "cGroup24-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "labRequisitions.cGroup24-3",
            "gIndex": "3",
            "gChildren": [
              "reqMicro_culture",
              "reqMicro_location",
              "reqMicro_comments"
            ]
          }
        ],
        "ehr_data": {
          "microRequisitions_name": "",
          "microRequisitions_profession": "",
          "microRequisitions_day": "",
          "microRequisitions_time": "",
          "reqMicro_id": "",
          "reqMicro_dateRequired": "",
          "reqMicro_schedule": "",
          "reqMicro_OrderPhys": "",
          "reqMicro_CopyPhys": "",
          "reqMicro_reason": "",
          "reqMicro_culture": "",
          "reqMicro_location": "",
          "reqMicro_comments": ""
        }
      },
      "fqn": "labRequisitions.microRequisitions",
      "tableChildren": [
        "microRequisitions_id",
        "microRequisitions_day",
        "microRequisitions_time",
        "reqMicro_id",
        "reqMicro_dateRequired",
        "reqMicro_schedule",
        "reqMicro_OrderPhys",
        "reqMicro_CopyPhys",
        "reqMicro_reason",
        "reqMicro_culture",
        "reqMicro_location",
        "reqMicro_comments",
        "microRequisitions_name",
        "microRequisitions_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS