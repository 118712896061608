/* eslint-disable */
const DEFS = {
  "pageDataKey": "riskAssessment",
  "pIndex": "51",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "bradenList_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "riskAssessment.name"
    },
    {
      "elementKey": "bradenList_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "bradenList_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "riskAssessment.profession"
    },
    {
      "elementKey": "bradenList_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "riskAssessment.day"
    },
    {
      "elementKey": "bradenList_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "riskAssessment.time"
    },
    {
      "elementKey": "bradenLink1",
      "assetName": "https://www.fraserhealth.ca/-/media/Project/FraserHealth/FraserHealth/employees/clinical-resources/acute-care-orientation/Braden-Scale-and-Assessment-Flow-Sheet.pdf",
      "formIndex": "1",
      "inputType": "externalLink",
      "fqn": "riskAssessment.bradenLink1"
    },
    {
      "elementKey": "bradenLink2",
      "assetName": "https://wtcs.pressbooks.pub/nursingfundamentals/chapter/10-5-braden-scale/",
      "formIndex": "1",
      "inputType": "externalLink",
      "fqn": "riskAssessment.bradenLink2"
    },
    {
      "elementKey": "bradenSensory",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1 = Completely limited"
        },
        {
          "key": "2",
          "text": "2 = Very limited"
        },
        {
          "key": "3",
          "text": "3 = Slightly limited"
        },
        {
          "key": "4",
          "text": "4 = No impairment"
        }
      ],
      "passToFunction": "bradenTotal",
      "tableColumn": "2",
      "fqn": "riskAssessment.bradenSensory"
    },
    {
      "elementKey": "bradenMoisture",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1 = Completely moist"
        },
        {
          "key": "2",
          "text": "2 = Very moist"
        },
        {
          "key": "3",
          "text": "3 = Occasionally moist"
        },
        {
          "key": "4",
          "text": "4 = Rarely moist"
        }
      ],
      "passToFunction": "bradenTotal",
      "tableColumn": "3",
      "fqn": "riskAssessment.bradenMoisture"
    },
    {
      "elementKey": "bradenActivity",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1 = Bedfast"
        },
        {
          "key": "2",
          "text": "2 = Chairfast"
        },
        {
          "key": "3",
          "text": "3 = Walks occasionally"
        },
        {
          "key": "4",
          "text": "4 = Walks frequently"
        }
      ],
      "passToFunction": "bradenTotal",
      "tableColumn": "4",
      "fqn": "riskAssessment.bradenActivity"
    },
    {
      "elementKey": "bradenMobility",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1 = Completely immobile"
        },
        {
          "key": "2",
          "text": "2 = Very limited"
        },
        {
          "key": "3",
          "text": "3 = Slightly limited"
        },
        {
          "key": "4",
          "text": "4 = No impairments"
        }
      ],
      "passToFunction": "bradenTotal",
      "tableColumn": "5",
      "fqn": "riskAssessment.bradenMobility"
    },
    {
      "elementKey": "bradenNutrition",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1 = Very poor"
        },
        {
          "key": "2",
          "text": "2 = Probably inadequate"
        },
        {
          "key": "3",
          "text": "3 = Adequate"
        },
        {
          "key": "4",
          "text": "4 = Excellent"
        }
      ],
      "passToFunction": "bradenTotal",
      "tableColumn": "6",
      "fqn": "riskAssessment.bradenNutrition"
    },
    {
      "elementKey": "bradenFriction",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1 = Problem"
        },
        {
          "key": "2",
          "text": "2 = Potential problem"
        },
        {
          "key": "3",
          "text": "3 = No apparent problem"
        }
      ],
      "passToFunction": "bradenTotal",
      "tableColumn": "7",
      "fqn": "riskAssessment.bradenFriction"
    },
    {
      "elementKey": "bradenTotal",
      "calculationType": "sum",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "tableColumn": "8",
      "fqn": "riskAssessment.bradenTotal"
    },
    {
      "elementKey": "bradenRiskDesc",
      "calculationType": "sum",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "riskAssessment.bradenRiskDesc"
    },
    {
      "elementKey": "bradenRiskLevel",
      "calculationType": "sum",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "severe",
          "text": "SEVERE (9 or less)"
        },
        {
          "key": "high",
          "text": "HIGH (10-12)"
        },
        {
          "key": "moderate",
          "text": "MODERATE (13-14)"
        },
        {
          "key": "mild",
          "text": "MILD (15-18)"
        }
      ],
      "tableColumn": "9",
      "fqn": "riskAssessment.bradenRiskLevel"
    },
    {
      "elementKey": "bradenComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "10",
      "fqn": "riskAssessment.bradenComments"
    },
    {
      "elementKey": "bradenInternal",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "11",
      "fqn": "riskAssessment.bradenInternal"
    },
    {
      "elementKey": "bradenLink3",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "riskAssessment.bradenLink3"
    },
    {
      "elementKey": "morseList_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "riskAssessment.name"
    },
    {
      "elementKey": "morseList_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "morseList_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "riskAssessment.profession"
    },
    {
      "elementKey": "morseList_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "riskAssessment.day"
    },
    {
      "elementKey": "morseList_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "riskAssessment.time"
    },
    {
      "elementKey": "morseLink1",
      "assetName": "https://networkofcare.org/library/Morse%20Fall%20Scale.pdf",
      "formIndex": "2",
      "inputType": "externalLink",
      "fqn": "riskAssessment.morseLink1"
    },
    {
      "elementKey": "morseHistory",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "no_0",
          "text": "No (0)"
        },
        {
          "key": "yes_25",
          "text": "Yes (25)"
        }
      ],
      "passToFunction": "morseScore",
      "tableColumn": "2",
      "fqn": "riskAssessment.morseHistory"
    },
    {
      "elementKey": "morseSecondary",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "no_0",
          "text": "No (0)"
        },
        {
          "key": "yes_15",
          "text": "Yes (15)"
        }
      ],
      "passToFunction": "morseScore",
      "tableColumn": "3",
      "fqn": "riskAssessment.morseSecondary"
    },
    {
      "elementKey": "morseAmbulation",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "none_0",
          "text": "None / Bed Rest / Wheelchair / Nurse (0)"
        },
        {
          "key": "medium_15",
          "text": "Crutches / Cane / Walker (15)"
        },
        {
          "key": "high_20",
          "text": "Furniture (20)"
        }
      ],
      "passToFunction": "morseScore",
      "tableColumn": "4",
      "fqn": "riskAssessment.morseAmbulation"
    },
    {
      "elementKey": "morseIv",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "no_0",
          "text": "No (0)"
        },
        {
          "key": "yes_25",
          "text": "Yes (25)"
        }
      ],
      "passToFunction": "morseScore",
      "tableColumn": "5",
      "fqn": "riskAssessment.morseIv"
    },
    {
      "elementKey": "morseGait",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "none_0",
          "text": "Normal / bedrest / immobile (0)"
        },
        {
          "key": "medium_10",
          "text": "Weak (10)"
        },
        {
          "key": "high_20",
          "text": "Impaired (20)"
        }
      ],
      "passToFunction": "morseScore",
      "tableColumn": "6",
      "fqn": "riskAssessment.morseGait"
    },
    {
      "elementKey": "morseMental",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "none_0",
          "text": "Oriented to Own Ability (0)"
        },
        {
          "key": "high_15",
          "text": "Forgets Limitations (15)"
        }
      ],
      "passToFunction": "morseScore",
      "tableColumn": "7",
      "fqn": "riskAssessment.morseMental"
    },
    {
      "elementKey": "morseScore",
      "calculationType": "sumOptionValues",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "calculatedValue",
      "tableColumn": "8",
      "fqn": "riskAssessment.morseScore"
    },
    {
      "elementKey": "morseRisk",
      "formIndex": "2",
      "formCss": "form_label_15",
      "inputType": "radioset",
      "options": [
        {
          "key": "no",
          "text": "No risk"
        },
        {
          "key": "low",
          "text": "Low risk"
        },
        {
          "key": "high",
          "text": "High risk"
        }
      ],
      "tableColumn": "9",
      "fqn": "riskAssessment.morseRisk"
    },
    {
      "elementKey": "morseReason",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "admission",
          "text": "On admission"
        },
        {
          "key": "transfer",
          "text": "Transfer"
        },
        {
          "key": "change",
          "text": "Change in condition"
        },
        {
          "key": "fall",
          "text": "After a fall"
        }
      ],
      "tableColumn": "10",
      "fqn": "riskAssessment.morseReason"
    },
    {
      "elementKey": "morseComments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "11",
      "fqn": "riskAssessment.morseComments"
    },
    {
      "elementKey": "morseLink2",
      "assetName": "https://www.ppno.ca/wp-content/uploads/2021/02/GRH-FALL-RISK-ASSESSMENT-TOOL.pdf",
      "formIndex": "2",
      "inputType": "externalLink",
      "fqn": "riskAssessment.morseLink2"
    },
    {
      "elementKey": "morseLink3",
      "assetName": "https://en.wikipedia.org/wiki/Morse_Fall_Scale",
      "formIndex": "2",
      "inputType": "externalLink",
      "fqn": "riskAssessment.morseLink3"
    },
    {
      "elementKey": "morseResource",
      "assetBase": "standardized-assessment-tools",
      "assetName": "Post-FallDecisionTree-2010.pdf",
      "formIndex": "2",
      "inputType": "assetLink",
      "fqn": "riskAssessment.morseResource"
    },
    {
      "elementKey": "morseInternal",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "12",
      "fqn": "riskAssessment.morseInternal"
    }
  ],
  "pageElements": {
    "bradenList": {
      "elementKey": "bradenList",
      "pageElementIndex": "1",
      "pageKey": "riskAssessment",
      "tableKey": "bradenList",
      "isTable": true,
      "form": {
        "elementKey": "bradenList",
        "label": "Braden",
        "addButtonText": "Add Braden Scale assessment",
        "formKey": "bradenList",
        "ehr_groups": [
          {
            "elementKey": "bradenHdrGrp",
            "formCss": "record-header",
            "fqn": "riskAssessment.bradenHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "bradenList_name",
              "bradenList_profession",
              "bradenList_day",
              "bradenList_time"
            ]
          },
          {
            "elementKey": "bradenDataGroup1",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.bradenDataGroup1",
            "gIndex": "2",
            "gChildren": [
              "bradenLink1",
              "bradenLink2"
            ]
          },
          {
            "elementKey": "bradenDataGroup2",
            "formCss": "grid-left-to-right-3",
            "fqn": "riskAssessment.bradenDataGroup2",
            "gIndex": "3",
            "gChildren": [
              "bradenSensory",
              "bradenMoisture",
              "bradenActivity",
              "bradenMobility",
              "bradenNutrition",
              "bradenFriction",
              "bradenTotal"
            ]
          },
          {
            "elementKey": "bradenDataGroup3",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.bradenDataGroup3",
            "gIndex": "4",
            "gChildren": [
              "bradenRiskDesc",
              "bradenRiskLevel",
              "bradenComments"
            ]
          },
          {
            "elementKey": "bradenDataGroup4",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.bradenDataGroup4",
            "gIndex": "5",
            "gChildren": [
              "bradenInternal",
              "bradenLink3"
            ]
          }
        ],
        "ehr_data": {
          "bradenList_name": "",
          "bradenList_profession": "",
          "bradenList_day": "",
          "bradenList_time": "",
          "bradenSensory": "",
          "bradenMoisture": "",
          "bradenActivity": "",
          "bradenMobility": "",
          "bradenNutrition": "",
          "bradenFriction": "",
          "bradenTotal": "",
          "bradenRiskLevel": "",
          "bradenComments": "",
          "bradenInternal": ""
        }
      },
      "fqn": "riskAssessment.bradenList",
      "tableChildren": [
        "bradenList_id",
        "bradenList_day",
        "bradenList_time",
        "bradenSensory",
        "bradenMoisture",
        "bradenActivity",
        "bradenMobility",
        "bradenNutrition",
        "bradenFriction",
        "bradenTotal",
        "bradenRiskLevel",
        "bradenComments",
        "bradenInternal",
        "bradenList_name",
        "bradenList_profession"
      ],
      "hasRecHeader": true
    },
    "morseList": {
      "elementKey": "morseList",
      "pageElementIndex": "2",
      "pageKey": "riskAssessment",
      "tableKey": "morseList",
      "isTable": true,
      "form": {
        "elementKey": "morseList",
        "label": "Morse Fall",
        "addButtonText": "Add Morse Fall assessment",
        "formKey": "morseList",
        "ehr_groups": [
          {
            "elementKey": "morseHdrGrp",
            "formCss": "record-header",
            "fqn": "riskAssessment.morseHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "morseList_name",
              "morseList_profession",
              "morseList_day",
              "morseList_time"
            ]
          },
          {
            "elementKey": "morseDataGroup1",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.morseDataGroup1",
            "gIndex": "2",
            "gChildren": [
              "morseLink1"
            ]
          },
          {
            "elementKey": "morseDataGroup2",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.morseDataGroup2",
            "gIndex": "3",
            "gChildren": [
              "morseHistory",
              "morseSecondary",
              "morseAmbulation",
              "morseIv",
              "morseGait",
              "morseMental",
              "morseScore",
              "morseRisk"
            ]
          },
          {
            "elementKey": "morseDataGroup3",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.morseDataGroup3",
            "gIndex": "4",
            "gChildren": [
              "morseReason",
              "morseComments"
            ]
          },
          {
            "elementKey": "morseDataGroup4",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.morseDataGroup4",
            "gIndex": "5",
            "gChildren": [
              "morseLink2",
              "morseLink3",
              "morseResource"
            ]
          },
          {
            "elementKey": "morseDataGroup5",
            "formCss": "grid-left-to-right-1",
            "fqn": "riskAssessment.morseDataGroup5",
            "gIndex": "6",
            "gChildren": [
              "morseInternal"
            ]
          }
        ],
        "ehr_data": {
          "morseList_name": "",
          "morseList_profession": "",
          "morseList_day": "",
          "morseList_time": "",
          "morseHistory": "",
          "morseSecondary": "",
          "morseAmbulation": "",
          "morseIv": "",
          "morseGait": "",
          "morseMental": "",
          "morseScore": "",
          "morseRisk": "",
          "morseReason": "",
          "morseComments": "",
          "morseInternal": ""
        }
      },
      "fqn": "riskAssessment.morseList",
      "tableChildren": [
        "morseList_id",
        "morseList_day",
        "morseList_time",
        "morseHistory",
        "morseSecondary",
        "morseAmbulation",
        "morseIv",
        "morseGait",
        "morseMental",
        "morseScore",
        "morseRisk",
        "morseReason",
        "morseComments",
        "morseInternal",
        "morseList_name",
        "morseList_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS