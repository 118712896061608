/* eslint-disable */
import acpMost from '../ehr-definitions/pages/acpMost.js'
import allergies from '../ehr-definitions/pages/allergies.js'
import assessmentTools from '../ehr-definitions/pages/assessmentTools.js'
import billing from '../ehr-definitions/pages/billing.js'
import biopsychosocial from '../ehr-definitions/pages/biopsychosocial.js'
import cardiovascular from '../ehr-definitions/pages/cardiovascular.js'
import carePlan from '../ehr-definitions/pages/carePlan.js'
import careTeam from '../ehr-definitions/pages/careTeam.js'
import caseMixGroup from '../ehr-definitions/pages/caseMixGroup.js'
import checklistsPage from '../ehr-definitions/pages/checklistsPage.js'
import codeLookup from '../ehr-definitions/pages/codeLookup.js'
import consentsPage from '../ehr-definitions/pages/consentsPage.js'
import consults from '../ehr-definitions/pages/consults.js'
import demographics from '../ehr-definitions/pages/demographics.js'
import diagnosticCodes from '../ehr-definitions/pages/diagnosticCodes.js'
import dischargeSummary from '../ehr-definitions/pages/dischargeSummary.js'
import dishargeplan from '../ehr-definitions/pages/dishargeplan.js'
import familyHistory from '../ehr-definitions/pages/familyHistory.js'
import fluidBalance from '../ehr-definitions/pages/fluidBalance.js'
import gastrointestinal from '../ehr-definitions/pages/gastrointestinal.js'
import genitourinary from '../ehr-definitions/pages/genitourinary.js'
import hematology from '../ehr-definitions/pages/hematology.js'
import immunization from '../ehr-definitions/pages/immunization.js'
import integumentaryAssessment from '../ehr-definitions/pages/integumentaryAssessment.js'
import interventionCodes from '../ehr-definitions/pages/interventionCodes.js'
import labRequisitions from '../ehr-definitions/pages/labRequisitions.js'
import labResults from '../ehr-definitions/pages/labResults.js'
import leveyJennings from '../ehr-definitions/pages/leveyJennings.js'
import linesDevices from '../ehr-definitions/pages/linesDevices.js'
import medAdminRec from '../ehr-definitions/pages/medAdminRec.js'
import medLabAccession from '../ehr-definitions/pages/medLabAccession.js'
import medLabBody from '../ehr-definitions/pages/medLabBody.js'
import medLabChemistry from '../ehr-definitions/pages/medLabChemistry.js'
import medLabPhoneLog from '../ehr-definitions/pages/medLabPhoneLog.js'
import medLabUrinalysis from '../ehr-definitions/pages/medLabUrinalysis.js'
import medical from '../ehr-definitions/pages/medical.js'
import medicalImaging from '../ehr-definitions/pages/medicalImaging.js'
import medicationOrders from '../ehr-definitions/pages/medicationOrders.js'
import musculoskeletal from '../ehr-definitions/pages/musculoskeletal.js'
import neurological from '../ehr-definitions/pages/neurological.js'
import newBorn from '../ehr-definitions/pages/newBorn.js'
import newBornFeeding from '../ehr-definitions/pages/newBornFeeding.js'
import newBornRec from '../ehr-definitions/pages/newBornRec.js'
import nonmedOrders from '../ehr-definitions/pages/nonmedOrders.js'
import operationReports from '../ehr-definitions/pages/operationReports.js'
import orderSets from '../ehr-definitions/pages/orderSets.js'
import pain from '../ehr-definitions/pages/pain.js'
import pastAppointments from '../ehr-definitions/pages/pastAppointments.js'
import periPed from '../ehr-definitions/pages/periPed.js'
import progressNotes from '../ehr-definitions/pages/progressNotes.js'
import psychosocial from '../ehr-definitions/pages/psychosocial.js'
import referrals from '../ehr-definitions/pages/referrals.js'
import respiratory from '../ehr-definitions/pages/respiratory.js'
import riskAssessment from '../ehr-definitions/pages/riskAssessment.js'
import specialIndicators from '../ehr-definitions/pages/specialIndicators.js'
import surgical from '../ehr-definitions/pages/surgical.js'
import visit from '../ehr-definitions/pages/visit.js'
import vitals from '../ehr-definitions/pages/vitals.js'
const pageDefFileMap = {
   acpMost: acpMost,
   allergies: allergies,
   assessmentTools: assessmentTools,
   billing: billing,
   biopsychosocial: biopsychosocial,
   cardiovascular: cardiovascular,
   carePlan: carePlan,
   careTeam: careTeam,
   caseMixGroup: caseMixGroup,
   checklistsPage: checklistsPage,
   codeLookup: codeLookup,
   consentsPage: consentsPage,
   consults: consults,
   demographics: demographics,
   diagnosticCodes: diagnosticCodes,
   dischargeSummary: dischargeSummary,
   dishargeplan: dishargeplan,
   familyHistory: familyHistory,
   fluidBalance: fluidBalance,
   gastrointestinal: gastrointestinal,
   genitourinary: genitourinary,
   hematology: hematology,
   immunization: immunization,
   integumentaryAssessment: integumentaryAssessment,
   interventionCodes: interventionCodes,
   labRequisitions: labRequisitions,
   labResults: labResults,
   leveyJennings: leveyJennings,
   linesDevices: linesDevices,
   medAdminRec: medAdminRec,
   medLabAccession: medLabAccession,
   medLabBody: medLabBody,
   medLabChemistry: medLabChemistry,
   medLabPhoneLog: medLabPhoneLog,
   medLabUrinalysis: medLabUrinalysis,
   medical: medical,
   medicalImaging: medicalImaging,
   medicationOrders: medicationOrders,
   musculoskeletal: musculoskeletal,
   neurological: neurological,
   newBorn: newBorn,
   newBornFeeding: newBornFeeding,
   newBornRec: newBornRec,
   nonmedOrders: nonmedOrders,
   operationReports: operationReports,
   orderSets: orderSets,
   pain: pain,
   pastAppointments: pastAppointments,
   periPed: periPed,
   progressNotes: progressNotes,
   psychosocial: psychosocial,
   referrals: referrals,
   respiratory: respiratory,
   riskAssessment: riskAssessment,
   specialIndicators: specialIndicators,
   surgical: surgical,
   visit: visit,
   vitals: vitals,
}
export default pageDefFileMap