/* eslint-disable */
const DEFS = {
  "pageDataKey": "referrals",
  "pIndex": "23",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "referrals.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "referrals.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "referrals.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "referrals.time"
    },
    {
      "elementKey": "referralName",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "referrals.referralName"
    },
    {
      "elementKey": "referralProfession",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "referrals.referralProfession"
    },
    {
      "elementKey": "spacer23",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "referrals.spacer23"
    },
    {
      "elementKey": "appointmentDate",
      "formIndex": "1",
      "inputType": "date",
      "tableColumn": "4",
      "fqn": "referrals.appointmentDate"
    },
    {
      "elementKey": "appointmentTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "5",
      "validation": "time24",
      "fqn": "referrals.appointmentTime"
    },
    {
      "elementKey": "status",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Active",
          "text": "Active"
        },
        {
          "key": "Discharged",
          "text": "Discharged"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "6",
      "fqn": "referrals.status"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "referrals",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Referrals",
        "addButtonText": "Add a referral",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "referrals.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup23-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "referrals.cGroup23-2",
            "gIndex": "2",
            "gChildren": [
              "referralName",
              "referralProfession",
              "spacer23",
              "appointmentDate",
              "appointmentTime",
              "status"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "referralName": "",
          "referralProfession": "",
          "appointmentDate": "",
          "appointmentTime": "",
          "status": ""
        }
      },
      "fqn": "referrals.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "referralName",
        "referralProfession",
        "appointmentDate",
        "appointmentTime",
        "status",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS