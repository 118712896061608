/* eslint-disable */
const DEFS = {
  "pageDataKey": "medAdminRec",
  "pIndex": "26",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "medStatus",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Active",
          "text": "Active"
        },
        {
          "key": "Hold",
          "text": "Hold"
        },
        {
          "key": "Discontinued",
          "text": "Discontinued"
        }
      ],
      "tableColumn": "1",
      "fqn": "medAdminRec.medStatus"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "adminStatus",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Administered",
          "text": "Administered"
        },
        {
          "key": "Refused",
          "text": "Refused"
        },
        {
          "key": "Missed",
          "text": "Missed"
        },
        {
          "key": "Skipped",
          "text": "Skipped"
        }
      ],
      "tableColumn": "2",
      "fqn": "medAdminRec.adminStatus"
    },
    {
      "elementKey": "medication",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medAdminRec.medication"
    },
    {
      "elementKey": "route",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "medAdminRec.route"
    },
    {
      "elementKey": "todayPlan",
      "formIndex": "1",
      "inputType": "text",
      "options": [
        {
          "key": "[Today's day] plan",
          "text": "[Today's day] plan"
        }
      ],
      "tableColumn": "5",
      "fqn": "medAdminRec.todayPlan"
    },
    {
      "elementKey": "todayGiven",
      "formIndex": "1",
      "inputType": "text",
      "options": [
        {
          "key": "[Today's day] given",
          "text": "[Today's day] given"
        }
      ],
      "tableColumn": "6",
      "fqn": "medAdminRec.todayGiven"
    },
    {
      "elementKey": "administeredBy",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "medAdminRec.administeredBy"
    },
    {
      "elementKey": "adminProfession",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "medAdminRec.adminProfession"
    },
    {
      "elementKey": "administeredTime",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "medAdminRec.administeredTime"
    },
    {
      "elementKey": "marTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medAdminRec.name"
    },
    {
      "elementKey": "marTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "marTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medAdminRec.profession"
    },
    {
      "elementKey": "marTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medAdminRec.day"
    },
    {
      "elementKey": "marTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medAdminRec.time"
    },
    {
      "elementKey": "med_order_embedded",
      "formIndex": "2",
      "embedRef": "medicationOrders.medicationOrdersTable",
      "inputType": "ehr_embedded",
      "passToFunction": "[mo_medOrder mo_medDetails mo_alert mo_route mo_timing mo_medInstructions mo_medReason mo_id]",
      "tableColumn": "13",
      "fqn": "medAdminRec.med_order_embedded"
    },
    {
      "elementKey": "mo_medOrder",
      "calculationType": "medOrderSummary(medicationOrders,medicationOrdersTable)",
      "formIndex": "2",
      "formCss": "grid-span-4-columns",
      "inputType": "calculatedValue",
      "tableColumn": "2",
      "fqn": "medAdminRec.mo_medOrder"
    },
    {
      "elementKey": "mo_medDetails",
      "calculationType": "medOrderDetails(medicationOrders,medicationOrdersTable)",
      "formIndex": "2",
      "formCss": "grid-span-4-columns",
      "inputType": "calculatedValue",
      "fqn": "medAdminRec.mo_medDetails"
    },
    {
      "elementKey": "mo_medInstructions",
      "calculationType": "embedValue(medicationOrders, medicationOrdersTable, med_instructions)",
      "formIndex": "2",
      "formCss": "grid-span-4-columns",
      "inputType": "calculatedValue",
      "fqn": "medAdminRec.mo_medInstructions"
    },
    {
      "elementKey": "mo_medReason",
      "calculationType": "embedValue(medicationOrders, medicationOrdersTable, med_reason)",
      "formIndex": "2",
      "formCss": "grid-span-4-columns",
      "inputType": "calculatedValue",
      "fqn": "medAdminRec.mo_medReason"
    },
    {
      "elementKey": "mo_route",
      "calculationType": "medAdminRoute(medicationOrders, medicationOrdersTable, med_route)",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "3",
      "fqn": "medAdminRec.mo_route"
    },
    {
      "elementKey": "mo_timing",
      "calculationType": "medAdminTiming(medicationOrders, medicationOrdersTable, med_timing)",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "4",
      "fqn": "medAdminRec.mo_timing"
    },
    {
      "elementKey": "mo_schedDay",
      "formIndex": "2",
      "formCss": "visit-day",
      "formOption": "elementViewOnly",
      "inputType": "visitDay",
      "tableColumn": "7",
      "fqn": "medAdminRec.mo_schedDay"
    },
    {
      "elementKey": "mo_schedTime",
      "formIndex": "2",
      "formCss": "visit-time",
      "formOption": "elementViewOnly",
      "inputType": "visitTime",
      "tableColumn": "8",
      "fqn": "medAdminRec.mo_schedTime"
    },
    {
      "elementKey": "mo_id",
      "calculationType": "embedValue(medicationOrders, medicationOrdersTable, medicationOrdersTable_id)",
      "formIndex": "2",
      "formOption": "hideElement",
      "inputType": "calculatedValue",
      "fqn": "medAdminRec.mo_id"
    },
    {
      "elementKey": "mo_alert",
      "calculationType": "medAdminAlert(medicationOrders,medicationOrdersTable)",
      "formIndex": "2",
      "formCss": "grid-span-4-columns",
      "inputType": "calculatedValue",
      "tableColumn": "11",
      "fqn": "medAdminRec.mo_alert"
    },
    {
      "elementKey": "mar_status",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Administered",
          "text": "Administered"
        },
        {
          "key": "Held",
          "text": "Held"
        },
        {
          "key": "Refused",
          "text": "Refused"
        },
        {
          "key": "Missed",
          "text": "Missed"
        },
        {
          "key": "Skipped",
          "text": "Skipped"
        }
      ],
      "tableColumn": "4",
      "fqn": "medAdminRec.mar_status"
    },
    {
      "elementKey": "mar_dose",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "medAdminRec.mar_dose"
    },
    {
      "elementKey": "mar_spacer4",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "medAdminRec.mar_spacer4"
    },
    {
      "elementKey": "mar_route",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "oral",
          "text": "PO"
        },
        {
          "key": "bucc",
          "text": "Buccal"
        },
        {
          "key": "epidural",
          "text": "Epidural"
        },
        {
          "key": "impl",
          "text": "Implant"
        },
        {
          "key": "inha",
          "text": "Inhalation"
        },
        {
          "key": "inje",
          "text": "Injection (obsolete)"
        },
        {
          "key": "intradermal",
          "text": "Intradermal"
        },
        {
          "key": "intramuscular",
          "text": "Intramuscular"
        },
        {
          "key": "intraosseous",
          "text": "Intraosseous"
        },
        {
          "key": "intraperitoneal",
          "text": "Intraperitoneal"
        },
        {
          "key": "intrathecal",
          "text": "Intrathecal"
        },
        {
          "key": "intra",
          "text": "Intravenous"
        },
        {
          "key": "nasa",
          "text": "Nasal"
        },
        {
          "key": "nebu",
          "text": "Nebulization"
        },
        {
          "key": "ocul",
          "text": "Ocular"
        },
        {
          "key": "otic",
          "text": "Otic"
        },
        {
          "key": "rect",
          "text": "Rectal"
        },
        {
          "key": "subc",
          "text": "Subcutaneous"
        },
        {
          "key": "subl",
          "text": "Sublingual"
        },
        {
          "key": "topical",
          "text": "Topical"
        },
        {
          "key": "tran",
          "text": "Transdermal"
        },
        {
          "key": "vagi",
          "text": "Vaginal"
        }
      ],
      "tableColumn": "6",
      "fqn": "medAdminRec.mar_route"
    },
    {
      "elementKey": "mar_event_day",
      "formIndex": "2",
      "formCss": "visit-day",
      "inputType": "visitDay",
      "tableColumn": "9",
      "validation": "visitDay",
      "fqn": "medAdminRec.mar_event_day"
    },
    {
      "elementKey": "mar_event_time",
      "formIndex": "2",
      "formCss": "visit-time",
      "inputType": "visitTime",
      "tableColumn": "10",
      "validation": "time24",
      "fqn": "medAdminRec.mar_event_time"
    },
    {
      "elementKey": "mar_location",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "medAdminRec.mar_location"
    },
    {
      "elementKey": "mar_secSigName",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "medAdminRec.mar_secSigName"
    },
    {
      "elementKey": "mar_barCodeCheck",
      "formIndex": "2",
      "formOption": "elementViewOnly",
      "inputType": "checkbox",
      "tableColumn": "14",
      "fqn": "medAdminRec.mar_barCodeCheck"
    },
    {
      "elementKey": "mar_comments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "15",
      "fqn": "medAdminRec.mar_comments"
    },
    {
      "elementKey": "mar_internal",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "16",
      "fqn": "medAdminRec.mar_internal"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "medAdminRec",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Medication administration records v1",
        "addButtonText": "Administered",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "medGroup1",
            "formCss": "grid-left-to-right-3",
            "fqn": "medAdminRec.medGroup1",
            "gIndex": "1",
            "gChildren": [
              "medStatus",
              "adminStatus",
              "medication",
              "route",
              "todayPlan",
              "todayGiven",
              "administeredBy",
              "adminProfession",
              "administeredTime"
            ]
          }
        ],
        "ehr_data": {
          "medStatus": "",
          "adminStatus": "",
          "medication": "",
          "route": "",
          "todayPlan": "",
          "todayGiven": "",
          "administeredBy": "",
          "adminProfession": "",
          "administeredTime": ""
        }
      },
      "fqn": "medAdminRec.table",
      "tableChildren": [
        "table_id",
        "medication",
        "route",
        "todayPlan",
        "todayGiven",
        "administeredBy",
        "adminProfession",
        "administeredTime",
        "medStatus",
        "adminStatus"
      ]
    },
    "marTable": {
      "elementKey": "marTable",
      "pageElementIndex": "2",
      "pageKey": "medAdminRec",
      "tableKey": "marTable",
      "isTable": true,
      "form": {
        "elementKey": "marTable",
        "label": "Medication administration records",
        "addButtonText": "NONE",
        "formKey": "marTable",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medAdminRec.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "marTable_name",
              "marTable_profession",
              "marTable_day",
              "marTable_time"
            ]
          },
          {
            "elementKey": "medGroup1",
            "formOption": "hideGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "medAdminRec.medGroup1",
            "gIndex": "2",
            "gChildren": [
              "med_order_embedded"
            ]
          },
          {
            "elementKey": "medGroup2",
            "formCss": "embedded-data grid-left-to-right-4",
            "fqn": "medAdminRec.medGroup2",
            "gIndex": "3",
            "gChildren": [
              "mo_medOrder",
              "mo_medDetails",
              "mo_medInstructions",
              "mo_medReason",
              "mo_route",
              "mo_timing",
              "mo_schedDay",
              "mo_schedTime",
              "mo_id",
              "mo_alert"
            ]
          },
          {
            "elementKey": "medGroup3",
            "formCss": "grid-left-to-right-3",
            "fqn": "medAdminRec.medGroup3",
            "gIndex": "4",
            "gChildren": [
              "mar_status",
              "mar_dose",
              "mar_spacer4",
              "mar_route",
              "mar_event_day",
              "mar_event_time",
              "mar_location",
              "mar_secSigName",
              "mar_barCodeCheck"
            ]
          },
          {
            "elementKey": "mar_group_notes",
            "formCss": "grid-left-to-right-1",
            "fqn": "medAdminRec.mar_group_notes",
            "gIndex": "5",
            "gChildren": [
              "mar_comments",
              "mar_internal"
            ]
          }
        ],
        "ehr_data": {
          "marTable_name": "",
          "marTable_profession": "",
          "marTable_day": "",
          "marTable_time": "",
          "med_order_embedded": "",
          "mo_medOrder": "",
          "mo_medDetails": "",
          "mo_medInstructions": "",
          "mo_medReason": "",
          "mo_route": "",
          "mo_timing": "",
          "mo_schedDay": "",
          "mo_schedTime": "",
          "mo_id": "",
          "mo_alert": "",
          "mar_status": "",
          "mar_dose": "",
          "mar_route": "",
          "mar_event_day": "",
          "mar_event_time": "",
          "mar_location": "",
          "mar_secSigName": "",
          "mar_barCodeCheck": "",
          "mar_comments": "",
          "mar_internal": ""
        }
      },
      "fqn": "medAdminRec.marTable",
      "tableChildren": [
        "marTable_id",
        "marTable_day",
        "marTable_time",
        "med_order_embedded",
        "mo_medOrder",
        "mo_route",
        "mo_timing",
        "mo_schedDay",
        "mo_schedTime",
        "mo_alert",
        "mar_status",
        "mar_dose",
        "mar_route",
        "mar_event_day",
        "mar_event_time",
        "mar_location",
        "mar_secSigName",
        "mar_barCodeCheck",
        "mar_comments",
        "mar_internal",
        "marTable_name",
        "marTable_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS