/* eslint-disable */
const DEFS = {
  "pageDataKey": "caseMixGroup",
  "pIndex": "39",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "caseMixGroup.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "caseMixGroup.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "caseMixGroup.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "caseMixGroup.time"
    },
    {
      "elementKey": "clinicalCategory",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "caseMixGroup.clinicalCategory"
    },
    {
      "elementKey": "caseMixNumber",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "caseMixGroup.caseMixNumber"
    },
    {
      "elementKey": "resourceIntensity",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "caseMixGroup.resourceIntensity"
    },
    {
      "elementKey": "expectedStay",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "caseMixGroup.expectedStay"
    },
    {
      "elementKey": "analysis",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "caseMixGroup.analysis"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "caseMixGroup",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Case mix group",
        "addButtonText": "Add a case mix group",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "caseMixGroup.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup39-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "caseMixGroup.cGroup39-2",
            "gIndex": "2",
            "gChildren": [
              "clinicalCategory",
              "caseMixNumber",
              "resourceIntensity",
              "expectedStay",
              "analysis"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "clinicalCategory": "",
          "caseMixNumber": "",
          "resourceIntensity": "",
          "expectedStay": "",
          "analysis": ""
        }
      },
      "fqn": "caseMixGroup.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "clinicalCategory",
        "caseMixNumber",
        "resourceIntensity",
        "expectedStay",
        "analysis",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS