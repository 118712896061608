/* eslint-disable */
const DEFS = {
  "pageDataKey": "pastAppointments",
  "pIndex": "9",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "encounterDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "pastAppointments.encounterDate"
    },
    {
      "elementKey": "encounters_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "site",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "pastAppointments.site"
    },
    {
      "elementKey": "diagnosis",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "pastAppointments.diagnosis"
    },
    {
      "elementKey": "mrp",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "pastAppointments.mrp"
    },
    {
      "elementKey": "appointmentDate",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "pastAppointments.appointmentDate"
    },
    {
      "elementKey": "outpatientAppointments_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "site",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "pastAppointments.site"
    },
    {
      "elementKey": "reasonForVisit",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "pastAppointments.reasonForVisit"
    },
    {
      "elementKey": "diagnosis",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "pastAppointments.diagnosis"
    }
  ],
  "pageElements": {
    "encounters": {
      "elementKey": "encounters",
      "pageElementIndex": "1",
      "pageKey": "pastAppointments",
      "tableKey": "encounters",
      "isTable": true,
      "form": {
        "elementKey": "encounters",
        "label": "Past encounters",
        "addButtonText": "Add a past encounter",
        "formKey": "encounters",
        "ehr_groups": [
          {
            "elementKey": "encounters",
            "formCss": "grid-left-to-right-3",
            "fqn": "pastAppointments.encounters",
            "gIndex": "1",
            "gChildren": [
              "encounterDate",
              "site",
              "diagnosis",
              "mrp"
            ]
          }
        ],
        "ehr_data": {
          "encounterDate": "",
          "site": "",
          "diagnosis": "",
          "mrp": ""
        }
      },
      "fqn": "pastAppointments.encounters",
      "tableChildren": [
        "encounters_id",
        "diagnosis",
        "mrp",
        "encounterDate",
        "site"
      ]
    },
    "outpatientAppointments": {
      "elementKey": "outpatientAppointments",
      "pageElementIndex": "2",
      "pageKey": "pastAppointments",
      "tableKey": "outpatientAppointments",
      "isTable": true,
      "form": {
        "elementKey": "outpatientAppointments",
        "label": "Past outpatient appointments",
        "addButtonText": "Add an outpatient appointment",
        "formKey": "outpatientAppointments",
        "ehr_groups": [
          {
            "elementKey": "outpatientAppointments",
            "formCss": "grid-left-to-right-3",
            "fqn": "pastAppointments.outpatientAppointments",
            "gIndex": "1",
            "gChildren": [
              "appointmentDate",
              "site",
              "reasonForVisit",
              "diagnosis"
            ]
          }
        ],
        "ehr_data": {
          "appointmentDate": "",
          "site": "",
          "reasonForVisit": "",
          "diagnosis": ""
        }
      },
      "fqn": "pastAppointments.outpatientAppointments",
      "tableChildren": [
        "outpatientAppointments_id",
        "reasonForVisit",
        "diagnosis",
        "appointmentDate",
        "site"
      ]
    }
  }
}
export default DEFS