/* eslint-disable */
const DEFS = {
  "pageDataKey": "orderSets",
  "pIndex": "48",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "insulinOrderSets_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.name"
    },
    {
      "elementKey": "insulinOrderSets_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "insulinOrderSets_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.profession"
    },
    {
      "elementKey": "insulinOrderSets_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "orderSets.day"
    },
    {
      "elementKey": "insulinOrderSets_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "orderSets.time"
    },
    {
      "elementKey": "insulinOrderSetForm",
      "formIndex": "1",
      "formOption": "insulin-orders",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "orderSets.insulinOrderSetForm"
    },
    {
      "elementKey": "preprintOrderSet_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.name"
    },
    {
      "elementKey": "preprintOrderSet_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "preprintOrderSet_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.profession"
    },
    {
      "elementKey": "preprintOrderSet_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "orderSets.day"
    },
    {
      "elementKey": "preprintOrderSet_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "orderSets.time"
    },
    {
      "elementKey": "preOrderSetFile",
      "formIndex": "2",
      "inputType": "ehrFile",
      "tableColumn": "2",
      "fqn": "orderSets.preOrderSetFile"
    },
    {
      "elementKey": "pcaPpo_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.name"
    },
    {
      "elementKey": "pcaPpo_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pcaPpo_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.profession"
    },
    {
      "elementKey": "pcaPpo_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "orderSets.day"
    },
    {
      "elementKey": "pcaPpo_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "orderSets.time"
    },
    {
      "elementKey": "pcaPpoForm",
      "formIndex": "3",
      "formOption": "pca-ppo",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "orderSets.pcaPpoForm"
    },
    {
      "elementKey": "bpTable_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.name"
    },
    {
      "elementKey": "bpTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "bpTable_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.profession"
    },
    {
      "elementKey": "bpTable_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "orderSets.day"
    },
    {
      "elementKey": "bpTable_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "orderSets.time"
    },
    {
      "elementKey": "bpForm",
      "formIndex": "4",
      "formOption": "bowel-protocol",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "orderSets.bpForm"
    },
    {
      "elementKey": "bmTable_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.name"
    },
    {
      "elementKey": "bmTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "bmTable_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.profession"
    },
    {
      "elementKey": "bmTable_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "orderSets.day"
    },
    {
      "elementKey": "bmTable_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "orderSets.time"
    },
    {
      "elementKey": "bmForm",
      "formIndex": "5",
      "formOption": "bowel-management",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "orderSets.bmForm"
    },
    {
      "elementKey": "swallowTable_name",
      "formIndex": "6",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.name"
    },
    {
      "elementKey": "swallowTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "swallowTable_profession",
      "formIndex": "6",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "orderSets.profession"
    },
    {
      "elementKey": "swallowTable_day",
      "formIndex": "6",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "orderSets.day"
    },
    {
      "elementKey": "swallowTable_time",
      "formIndex": "6",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "orderSets.time"
    },
    {
      "elementKey": "swallowForm",
      "formIndex": "6",
      "formOption": "swallow",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "orderSets.swallowForm"
    }
  ],
  "pageElements": {
    "insulinOrderSets": {
      "elementKey": "insulinOrderSets",
      "pageElementIndex": "1",
      "pageKey": "orderSets",
      "tableKey": "insulinOrderSets",
      "isTable": true,
      "form": {
        "elementKey": "insulinOrderSets",
        "label": "Insulin",
        "addButtonText": "Add an Insulin Order Set",
        "formKey": "insulinOrderSets",
        "ehr_groups": [
          {
            "elementKey": "cGroup48-1",
            "formCss": "record-header",
            "fqn": "orderSets.cGroup48-1",
            "gIndex": "1",
            "gChildren": [
              "insulinOrderSets_name",
              "insulinOrderSets_profession",
              "insulinOrderSets_day",
              "insulinOrderSets_time"
            ]
          },
          {
            "elementKey": "cGroup48-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "orderSets.cGroup48-2",
            "gIndex": "2",
            "gChildren": [
              "insulinOrderSetForm"
            ]
          }
        ],
        "ehr_data": {
          "insulinOrderSets_name": "",
          "insulinOrderSets_profession": "",
          "insulinOrderSets_day": "",
          "insulinOrderSets_time": "",
          "insulinOrderSetForm": ""
        }
      },
      "fqn": "orderSets.insulinOrderSets",
      "tableChildren": [
        "insulinOrderSets_id",
        "insulinOrderSets_day",
        "insulinOrderSets_time",
        "insulinOrderSetForm",
        "insulinOrderSets_name",
        "insulinOrderSets_profession"
      ],
      "hasRecHeader": true
    },
    "preprintOrderSet": {
      "elementKey": "preprintOrderSet",
      "pageElementIndex": "2",
      "pageKey": "orderSets",
      "tableKey": "preprintOrderSet",
      "isTable": true,
      "form": {
        "elementKey": "preprintOrderSet",
        "label": "Preprinted",
        "addButtonText": "Add a preprinted order set",
        "formKey": "preprintOrderSet",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "orderSets.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "preprintOrderSet_name",
              "preprintOrderSet_profession",
              "preprintOrderSet_day",
              "preprintOrderSet_time"
            ]
          },
          {
            "elementKey": "cGroup48-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "orderSets.cGroup48-2",
            "gIndex": "2",
            "gChildren": [
              "preOrderSetFile"
            ]
          }
        ],
        "ehr_data": {
          "preprintOrderSet_name": "",
          "preprintOrderSet_profession": "",
          "preprintOrderSet_day": "",
          "preprintOrderSet_time": "",
          "preOrderSetFile": ""
        }
      },
      "fqn": "orderSets.preprintOrderSet",
      "tableChildren": [
        "preprintOrderSet_id",
        "preprintOrderSet_day",
        "preprintOrderSet_time",
        "preOrderSetFile",
        "preprintOrderSet_name",
        "preprintOrderSet_profession"
      ],
      "hasRecHeader": true
    },
    "pcaPpo": {
      "elementKey": "pcaPpo",
      "pageElementIndex": "3",
      "pageKey": "orderSets",
      "tableKey": "pcaPpo",
      "isTable": true,
      "form": {
        "elementKey": "pcaPpo",
        "label": "PCA",
        "addButtonText": "Add Patient Controlled Analgesia",
        "formKey": "pcaPpo",
        "ehr_groups": [
          {
            "elementKey": "pcaPpo-1",
            "formCss": "record-header",
            "fqn": "orderSets.pcaPpo-1",
            "gIndex": "1",
            "gChildren": [
              "pcaPpo_name",
              "pcaPpo_profession",
              "pcaPpo_day",
              "pcaPpo_time"
            ]
          },
          {
            "elementKey": "pcaPpo-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "orderSets.pcaPpo-2",
            "gIndex": "2",
            "gChildren": [
              "pcaPpoForm"
            ]
          }
        ],
        "ehr_data": {
          "pcaPpo_name": "",
          "pcaPpo_profession": "",
          "pcaPpo_day": "",
          "pcaPpo_time": "",
          "pcaPpoForm": ""
        }
      },
      "fqn": "orderSets.pcaPpo",
      "tableChildren": [
        "pcaPpo_id",
        "pcaPpo_day",
        "pcaPpo_time",
        "pcaPpoForm",
        "pcaPpo_name",
        "pcaPpo_profession"
      ],
      "hasRecHeader": true
    },
    "bpTable": {
      "elementKey": "bpTable",
      "pageElementIndex": "4",
      "pageKey": "orderSets",
      "tableKey": "bpTable",
      "isTable": true,
      "form": {
        "elementKey": "bpTable",
        "label": "Bowel Protocol",
        "addButtonText": "Add Bowel Protocol Order Set",
        "formKey": "bpTable",
        "ehr_groups": [
          {
            "elementKey": "bpGrp1",
            "formCss": "record-header",
            "fqn": "orderSets.bpGrp1",
            "gIndex": "1",
            "gChildren": [
              "bpTable_name",
              "bpTable_profession",
              "bpTable_day",
              "bpTable_time"
            ]
          },
          {
            "elementKey": "bpGrp2",
            "formCss": "grid-left-to-right-1",
            "fqn": "orderSets.bpGrp2",
            "gIndex": "2",
            "gChildren": [
              "bpForm"
            ]
          }
        ],
        "ehr_data": {
          "bpTable_name": "",
          "bpTable_profession": "",
          "bpTable_day": "",
          "bpTable_time": "",
          "bpForm": ""
        }
      },
      "fqn": "orderSets.bpTable",
      "tableChildren": [
        "bpTable_id",
        "bpTable_day",
        "bpTable_time",
        "bpForm",
        "bpTable_name",
        "bpTable_profession"
      ],
      "hasRecHeader": true
    },
    "bmTable": {
      "elementKey": "bmTable",
      "pageElementIndex": "5",
      "pageKey": "orderSets",
      "tableKey": "bmTable",
      "isTable": true,
      "form": {
        "elementKey": "bmTable",
        "label": "Bowel Management",
        "addButtonText": "Add Bowel Management Order Set",
        "formKey": "bmTable",
        "ehr_groups": [
          {
            "elementKey": "bmGrp1",
            "formCss": "record-header",
            "fqn": "orderSets.bmGrp1",
            "gIndex": "1",
            "gChildren": [
              "bmTable_name",
              "bmTable_profession",
              "bmTable_day",
              "bmTable_time"
            ]
          },
          {
            "elementKey": "bmGrp2",
            "formCss": "grid-left-to-right-1",
            "fqn": "orderSets.bmGrp2",
            "gIndex": "2",
            "gChildren": [
              "bmForm"
            ]
          }
        ],
        "ehr_data": {
          "bmTable_name": "",
          "bmTable_profession": "",
          "bmTable_day": "",
          "bmTable_time": "",
          "bmForm": ""
        }
      },
      "fqn": "orderSets.bmTable",
      "tableChildren": [
        "bmTable_id",
        "bmTable_day",
        "bmTable_time",
        "bmForm",
        "bmTable_name",
        "bmTable_profession"
      ],
      "hasRecHeader": true
    },
    "swallowTable": {
      "elementKey": "swallowTable",
      "pageElementIndex": "6",
      "pageKey": "orderSets",
      "tableKey": "swallowTable",
      "isTable": true,
      "form": {
        "elementKey": "swallowTable",
        "label": "Swallow Screening",
        "addButtonText": "Add Swallow Screening Order Set",
        "formKey": "swallowTable",
        "ehr_groups": [
          {
            "elementKey": "swallowGrp1",
            "formCss": "record-header",
            "fqn": "orderSets.swallowGrp1",
            "gIndex": "1",
            "gChildren": [
              "swallowTable_name",
              "swallowTable_profession",
              "swallowTable_day",
              "swallowTable_time"
            ]
          },
          {
            "elementKey": "swallowGrp2",
            "formCss": "grid-left-to-right-1",
            "fqn": "orderSets.swallowGrp2",
            "gIndex": "2",
            "gChildren": [
              "swallowForm"
            ]
          }
        ],
        "ehr_data": {
          "swallowTable_name": "",
          "swallowTable_profession": "",
          "swallowTable_day": "",
          "swallowTable_time": "",
          "swallowForm": ""
        }
      },
      "fqn": "orderSets.swallowTable",
      "tableChildren": [
        "swallowTable_id",
        "swallowTable_day",
        "swallowTable_time",
        "swallowForm",
        "swallowTable_name",
        "swallowTable_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS