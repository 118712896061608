/* eslint-disable */
const DEFS = {
  "pageDataKey": "nonmedOrders",
  "pIndex": "22",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "nonmedOrders.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "nonmedOrders.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "nonmedOrders.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "nonmedOrders.time"
    },
    {
      "elementKey": "order",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "nonmedOrders.order"
    },
    {
      "elementKey": "orderedBy",
      "formIndex": "1",
      "inputType": "text",
      "mandatory": "TRUE",
      "tableColumn": "3",
      "fqn": "nonmedOrders.orderedBy"
    },
    {
      "elementKey": "reason",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "nonmedOrders.reason"
    },
    {
      "elementKey": "status",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Ordered",
          "text": "Ordered"
        },
        {
          "key": "In process",
          "text": "In process"
        },
        {
          "key": "Cancelled",
          "text": "Cancelled"
        },
        {
          "key": "Completed",
          "text": "Completed"
        },
        {
          "key": "Discontinued",
          "text": "Discontinued"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "5",
      "fqn": "nonmedOrders.status"
    },
    {
      "elementKey": "comment",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "6",
      "fqn": "nonmedOrders.comment"
    },
    {
      "elementKey": "dietOrders_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "nonmedOrders.name"
    },
    {
      "elementKey": "dietOrders_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "dietOrders_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "nonmedOrders.profession"
    },
    {
      "elementKey": "dietOrders_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "nonmedOrders.day"
    },
    {
      "elementKey": "dietOrders_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "nonmedOrders.time"
    },
    {
      "elementKey": "diet_regular",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "2",
      "fqn": "nonmedOrders.diet_regular"
    },
    {
      "elementKey": "diet_npo",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "3",
      "fqn": "nonmedOrders.diet_npo"
    },
    {
      "elementKey": "diet_heart",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "4",
      "fqn": "nonmedOrders.diet_heart"
    },
    {
      "elementKey": "diet_diabetic",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "5",
      "fqn": "nonmedOrders.diet_diabetic"
    },
    {
      "elementKey": "diet_fluid",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "6",
      "fqn": "nonmedOrders.diet_fluid"
    },
    {
      "elementKey": "diet_fluid_amount",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "suffix": "mL/24h",
      "tableColumn": "7",
      "fqn": "nonmedOrders.diet_fluid_amount",
      "suffixText": "mL/24h"
    },
    {
      "elementKey": "diet_other",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "8",
      "fqn": "nonmedOrders.diet_other"
    },
    {
      "elementKey": "diet_other_explain",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "nonmedOrders.diet_other_explain"
    },
    {
      "elementKey": "diet_status",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Ordered",
          "text": "Ordered"
        },
        {
          "key": "In process",
          "text": "In process"
        },
        {
          "key": "Cancelled",
          "text": "Cancelled"
        },
        {
          "key": "Completed",
          "text": "Completed"
        },
        {
          "key": "Discontinued",
          "text": "Discontinued"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "10",
      "fqn": "nonmedOrders.diet_status"
    },
    {
      "elementKey": "diet_orderedBy",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "nonmedOrders.diet_orderedBy"
    },
    {
      "elementKey": "diet_comment",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "12",
      "fqn": "nonmedOrders.diet_comment"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "nonmedOrders",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Nonmedication orders",
        "addButtonText": "Add an order",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "nonmedOrders.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup22-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "nonmedOrders.cGroup22-2",
            "gIndex": "2",
            "gChildren": [
              "order",
              "orderedBy",
              "reason",
              "status",
              "comment"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "order": "",
          "orderedBy": "",
          "reason": "",
          "status": "",
          "comment": ""
        }
      },
      "fqn": "nonmedOrders.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "order",
        "orderedBy",
        "reason",
        "status",
        "comment",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "dietOrders": {
      "elementKey": "dietOrders",
      "pageElementIndex": "2",
      "pageKey": "nonmedOrders",
      "tableKey": "dietOrders",
      "isTable": true,
      "form": {
        "elementKey": "dietOrders",
        "label": "Diet",
        "addButtonText": "Add a diet order",
        "formKey": "dietOrders",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "nonmedOrders.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "dietOrders_name",
              "dietOrders_profession",
              "dietOrders_day",
              "dietOrders_time"
            ]
          },
          {
            "elementKey": "cGroup22-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "nonmedOrders.cGroup22-2",
            "gIndex": "2",
            "gChildren": [
              "diet_regular",
              "diet_npo",
              "diet_heart",
              "diet_diabetic"
            ]
          },
          {
            "elementKey": "cGroup22-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "nonmedOrders.cGroup22-3",
            "gIndex": "3",
            "gChildren": [
              "diet_fluid",
              "diet_fluid_amount"
            ]
          },
          {
            "elementKey": "cGroup22-4",
            "formCss": "grid-left-to-right-3",
            "fqn": "nonmedOrders.cGroup22-4",
            "gIndex": "4",
            "gChildren": [
              "diet_other",
              "diet_other_explain"
            ]
          },
          {
            "elementKey": "cGroup22-5",
            "formCss": "grid-left-to-right-3",
            "fqn": "nonmedOrders.cGroup22-5",
            "gIndex": "5",
            "gChildren": [
              "diet_status",
              "diet_orderedBy",
              "diet_comment"
            ]
          }
        ],
        "ehr_data": {
          "dietOrders_name": "",
          "dietOrders_profession": "",
          "dietOrders_day": "",
          "dietOrders_time": "",
          "diet_regular": "",
          "diet_npo": "",
          "diet_heart": "",
          "diet_diabetic": "",
          "diet_fluid": "",
          "diet_fluid_amount": "",
          "diet_other": "",
          "diet_other_explain": "",
          "diet_status": "",
          "diet_orderedBy": "",
          "diet_comment": ""
        }
      },
      "fqn": "nonmedOrders.dietOrders",
      "tableChildren": [
        "dietOrders_id",
        "dietOrders_day",
        "dietOrders_time",
        "diet_regular",
        "diet_npo",
        "diet_heart",
        "diet_diabetic",
        "diet_fluid",
        "diet_fluid_amount",
        "diet_other",
        "diet_other_explain",
        "diet_status",
        "diet_orderedBy",
        "diet_comment",
        "dietOrders_name",
        "dietOrders_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS