/* eslint-disable */
const DEFS = {
  "pageDataKey": "musculoskeletal",
  "pIndex": "19",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "musculoskeletal.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "musculoskeletal.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "musculoskeletal.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "musculoskeletal.time"
    },
    {
      "elementKey": "swelling",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Present",
          "text": "Present"
        },
        {
          "key": "Absent",
          "text": "Absent"
        }
      ],
      "tableColumn": "2",
      "fqn": "musculoskeletal.swelling"
    },
    {
      "elementKey": "pain",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Present",
          "text": "Present"
        },
        {
          "key": "Absent",
          "text": "Absent"
        }
      ],
      "tableColumn": "3",
      "fqn": "musculoskeletal.pain"
    },
    {
      "elementKey": "deformity",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Present",
          "text": "Present"
        },
        {
          "key": "Absent",
          "text": "Absent"
        }
      ],
      "tableColumn": "4",
      "fqn": "musculoskeletal.deformity"
    },
    {
      "elementKey": "movement",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Normal"
        },
        {
          "key": "Decreased",
          "text": "Decreased"
        },
        {
          "key": "Absent",
          "text": "Absent"
        }
      ],
      "tableColumn": "5",
      "fqn": "musculoskeletal.movement"
    },
    {
      "elementKey": "sensation",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Present",
          "text": "Present"
        },
        {
          "key": "Absent",
          "text": "Absent"
        },
        {
          "key": "Numb",
          "text": "Numb"
        }
      ],
      "tableColumn": "6",
      "fqn": "musculoskeletal.sensation"
    },
    {
      "elementKey": "colour",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Flesh",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "Pale",
          "text": "Pale"
        },
        {
          "key": "Cynanotic",
          "text": "Cyanotic"
        }
      ],
      "tableColumn": "7",
      "fqn": "musculoskeletal.colour"
    },
    {
      "elementKey": "temp",
      "assetBase": "?",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Hot",
          "text": "Hot"
        },
        {
          "key": "Warm",
          "text": "Warm"
        },
        {
          "key": "Cold",
          "text": "Cold"
        }
      ],
      "tableColumn": "8",
      "fqn": "musculoskeletal.temp"
    },
    {
      "elementKey": "mAssistance",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "pa1",
          "text": "1PA"
        },
        {
          "key": "pa2",
          "text": "2PA"
        },
        {
          "key": "lift",
          "text": "Ceiling Lift"
        },
        {
          "key": "ind",
          "text": "Independent"
        }
      ],
      "tableColumn": "9",
      "fqn": "musculoskeletal.mAssistance"
    },
    {
      "elementKey": "useOfAmbulatoryAid",
      "assetBase": "?",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "10",
      "fqn": "musculoskeletal.useOfAmbulatoryAid"
    },
    {
      "elementKey": "type",
      "assetBase": "?",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Walker",
          "text": "Walker"
        },
        {
          "key": "Cane",
          "text": "Cane"
        },
        {
          "key": "One crutch",
          "text": "One crutch"
        },
        {
          "key": "Two crutches",
          "text": "Two crutches"
        },
        {
          "key": "Wheelchair",
          "text": "Wheelchair"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "tableColumn": "11",
      "fqn": "musculoskeletal.type"
    },
    {
      "elementKey": "comments",
      "assetBase": "?",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "12",
      "fqn": "musculoskeletal.comments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "musculoskeletal",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Musculoskeletal assessment",
        "addButtonText": "Add a musculoskeletal assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "musculoskeletal.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup19-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "musculoskeletal.cGroup19-2",
            "gIndex": "2",
            "gChildren": [
              "swelling",
              "pain",
              "deformity",
              "movement",
              "sensation",
              "colour",
              "temp"
            ]
          },
          {
            "elementKey": "gAmbulation",
            "formCss": "grid-left-to-right-3",
            "fqn": "musculoskeletal.gAmbulation",
            "gIndex": "3",
            "gChildren": [
              "mAssistance",
              "useOfAmbulatoryAid",
              "type"
            ]
          },
          {
            "elementKey": "cGroup3",
            "formCss": "grid-left-to-right-1",
            "fqn": "musculoskeletal.cGroup3",
            "gIndex": "4",
            "gChildren": [
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "swelling": "",
          "pain": "",
          "deformity": "",
          "movement": "",
          "sensation": "",
          "colour": "",
          "temp": "",
          "mAssistance": "",
          "useOfAmbulatoryAid": "",
          "type": "",
          "comments": ""
        }
      },
      "fqn": "musculoskeletal.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "swelling",
        "pain",
        "deformity",
        "movement",
        "sensation",
        "colour",
        "temp",
        "mAssistance",
        "useOfAmbulatoryAid",
        "type",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS