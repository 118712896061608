/* eslint-disable */
const DEFS = {
  "pageDataKey": "hematology",
  "pIndex": "43",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "tableCbcAnalysis_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "hematology.name"
    },
    {
      "elementKey": "tableCbcAnalysis_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableCbcAnalysis_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "hematology.profession"
    },
    {
      "elementKey": "tableCbcAnalysis_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "hematology.day"
    },
    {
      "elementKey": "tableCbcAnalysis_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "hematology.time"
    },
    {
      "elementKey": "wbc",
      "formIndex": "1",
      "inputType": "number",
      "mandatory": "TRUE",
      "suffix": "10^9/L",
      "tableColumn": "2",
      "validation": "numeric",
      "fqn": "hematology.wbc",
      "suffixText": "10^9/L"
    },
    {
      "elementKey": "wbc-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "3",
      "fqn": "hematology.wbc-interp"
    },
    {
      "elementKey": "spacer1",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer1"
    },
    {
      "elementKey": "rbc",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "10^12/L",
      "tableColumn": "4",
      "validation": "numeric",
      "fqn": "hematology.rbc",
      "suffixText": "10^12/L"
    },
    {
      "elementKey": "rbc-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "5",
      "fqn": "hematology.rbc-interp"
    },
    {
      "elementKey": "spacer2",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer2"
    },
    {
      "elementKey": "hgb",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "g/L",
      "tableColumn": "6",
      "validation": "numeric",
      "fqn": "hematology.hgb",
      "suffixText": "g/L"
    },
    {
      "elementKey": "hgb-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "7",
      "fqn": "hematology.hgb-interp"
    },
    {
      "elementKey": "spacer3",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer3"
    },
    {
      "elementKey": "HCT",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "L/L",
      "tableColumn": "8",
      "validation": "numeric",
      "fqn": "hematology.HCT",
      "suffixText": "L/L"
    },
    {
      "elementKey": "HCT-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "9",
      "fqn": "hematology.HCT-interp"
    },
    {
      "elementKey": "spacer4",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer4"
    },
    {
      "elementKey": "MCV",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "fL",
      "tableColumn": "10",
      "validation": "numeric",
      "fqn": "hematology.MCV",
      "suffixText": "fL"
    },
    {
      "elementKey": "MCV-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "11",
      "fqn": "hematology.MCV-interp"
    },
    {
      "elementKey": "spacer5",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer5"
    },
    {
      "elementKey": "MCH",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "pg",
      "tableColumn": "12",
      "validation": "numeric",
      "fqn": "hematology.MCH",
      "suffixText": "pg"
    },
    {
      "elementKey": "MCH-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "13",
      "fqn": "hematology.MCH-interp"
    },
    {
      "elementKey": "spacer6",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer6"
    },
    {
      "elementKey": "MCHC",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "g/L",
      "tableColumn": "14",
      "validation": "numeric",
      "fqn": "hematology.MCHC",
      "suffixText": "g/L"
    },
    {
      "elementKey": "MCHC-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "15",
      "fqn": "hematology.MCHC-interp"
    },
    {
      "elementKey": "spacer7",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer7"
    },
    {
      "elementKey": "RDW",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "16",
      "validation": "numeric",
      "fqn": "hematology.RDW",
      "suffixText": "%"
    },
    {
      "elementKey": "RDW-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "17",
      "fqn": "hematology.RDW-interp"
    },
    {
      "elementKey": "spacer8",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer8"
    },
    {
      "elementKey": "PLT",
      "formIndex": "1",
      "inputType": "number",
      "mandatory": "TRUE",
      "suffix": "10^9/L",
      "tableColumn": "18",
      "validation": "numeric",
      "fqn": "hematology.PLT",
      "suffixText": "10^9/L"
    },
    {
      "elementKey": "PLT-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "19",
      "fqn": "hematology.PLT-interp"
    },
    {
      "elementKey": "spacer9",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer9"
    },
    {
      "elementKey": "MPV",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "fL",
      "tableColumn": "20",
      "validation": "numeric",
      "fqn": "hematology.MPV",
      "suffixText": "fL"
    },
    {
      "elementKey": "MPV-interp",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "low",
          "text": "low"
        },
        {
          "key": "normal",
          "text": "normal"
        },
        {
          "key": "high",
          "text": "high"
        }
      ],
      "tableColumn": "21",
      "fqn": "hematology.MPV-interp"
    },
    {
      "elementKey": "spacer10",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "hematology.spacer10"
    },
    {
      "elementKey": "cbcScatterPlot",
      "formIndex": "1",
      "formCss": "grid-new-subsection",
      "inputType": "ehrFile",
      "tableColumn": "22",
      "fqn": "hematology.cbcScatterPlot"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "textarea",
      "tableColumn": "23",
      "fqn": "hematology.comments"
    },
    {
      "elementKey": "tablePbfReview_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "hematology.name"
    },
    {
      "elementKey": "tablePbfReview_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tablePbfReview_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "hematology.profession"
    },
    {
      "elementKey": "tablePbfReview_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "hematology.day"
    },
    {
      "elementKey": "tablePbfReview_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "hematology.time"
    },
    {
      "elementKey": "bloodAnalysis",
      "formIndex": "2",
      "embedRef": "hematology.tableCbcAnalysis",
      "inputType": "ehr_embedded",
      "passToFunction": "[wbcAnalysis, pltAnalysis, cellCntSegNeutrophilAbs, cellCntBandCellsAbs, cellCntLymphAbs, cellCntMonoAbs, cellCntEosinAbs, cellCntBasoAbs, cellCntMetaAbs, cellCntMyeloAbs, cellCntPromyeloAbs, cellCntBlastAbs, cellCntReactiveLymphsAbs, cellCntOtherAbs]",
      "tableColumn": "2",
      "fqn": "hematology.bloodAnalysis"
    },
    {
      "elementKey": "actionsTaken",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "hematology.actionsTaken"
    },
    {
      "elementKey": "scanComments",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "hematology.scanComments"
    },
    {
      "elementKey": "wbc-1",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "5",
      "validation": "numeric",
      "fqn": "hematology.wbc-1"
    },
    {
      "elementKey": "wbc-2",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "6",
      "validation": "numeric",
      "fqn": "hematology.wbc-2"
    },
    {
      "elementKey": "wbc-3",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "7",
      "validation": "numeric",
      "fqn": "hematology.wbc-3"
    },
    {
      "elementKey": "wbc-4",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "8",
      "validation": "numeric",
      "fqn": "hematology.wbc-4"
    },
    {
      "elementKey": "wbc-5",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "9",
      "validation": "numeric",
      "fqn": "hematology.wbc-5"
    },
    {
      "elementKey": "wbc-6",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "10",
      "validation": "numeric",
      "fqn": "hematology.wbc-6"
    },
    {
      "elementKey": "wbc-7",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "11",
      "validation": "numeric",
      "fqn": "hematology.wbc-7"
    },
    {
      "elementKey": "wbc-8",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "12",
      "validation": "numeric",
      "fqn": "hematology.wbc-8"
    },
    {
      "elementKey": "wbc-9",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "13",
      "validation": "numeric",
      "fqn": "hematology.wbc-9"
    },
    {
      "elementKey": "wbc-10",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcAverage",
      "tableColumn": "14",
      "validation": "numeric",
      "fqn": "hematology.wbc-10"
    },
    {
      "elementKey": "wbcFieldFactor",
      "defaultValue": "--wbcFieldFactor--",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "wbcEstimate",
      "tableColumn": "15",
      "validation": "numeric",
      "fqn": "hematology.wbcFieldFactor"
    },
    {
      "elementKey": "wbcAverage",
      "calculationType": "average",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "wbcEstimate",
      "tableColumn": "16",
      "validation": "numeric",
      "fqn": "hematology.wbcAverage"
    },
    {
      "elementKey": "wbcEstimate",
      "calculationType": "product",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "17",
      "validation": "numeric",
      "fqn": "hematology.wbcEstimate"
    },
    {
      "elementKey": "wbcAnalysis",
      "calculationType": "embedValue(hematology,tableCbcAnalysis,wbc)",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "[wbcLowRange, wbcHighRange]",
      "tableColumn": "18",
      "validation": "numeric",
      "fqn": "hematology.wbcAnalysis"
    },
    {
      "elementKey": "wbcLowRange",
      "calculationType": "multiplyBy(0.75, 1)",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "19",
      "validation": "numeric",
      "fqn": "hematology.wbcLowRange"
    },
    {
      "elementKey": "wbcHighRange",
      "calculationType": "multiplyBy(1.25, 1)",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "20",
      "validation": "numeric",
      "fqn": "hematology.wbcHighRange"
    },
    {
      "elementKey": "wbcComment",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "hematology.wbcComment"
    },
    {
      "elementKey": "wbcCorrectedWbc",
      "formIndex": "2",
      "inputType": "number",
      "tableColumn": "22",
      "fqn": "hematology.wbcCorrectedWbc"
    },
    {
      "elementKey": "plt-1",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "22",
      "validation": "numeric",
      "fqn": "hematology.plt-1"
    },
    {
      "elementKey": "plt-2",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "23",
      "validation": "numeric",
      "fqn": "hematology.plt-2"
    },
    {
      "elementKey": "plt-3",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "24",
      "validation": "numeric",
      "fqn": "hematology.plt-3"
    },
    {
      "elementKey": "plt-4",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "25",
      "validation": "numeric",
      "fqn": "hematology.plt-4"
    },
    {
      "elementKey": "plt-5",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "26",
      "validation": "numeric",
      "fqn": "hematology.plt-5"
    },
    {
      "elementKey": "plt-6",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "27",
      "validation": "numeric",
      "fqn": "hematology.plt-6"
    },
    {
      "elementKey": "plt-7",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "28",
      "validation": "numeric",
      "fqn": "hematology.plt-7"
    },
    {
      "elementKey": "plt-8",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "29",
      "validation": "numeric",
      "fqn": "hematology.plt-8"
    },
    {
      "elementKey": "plt-9",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "30",
      "validation": "numeric",
      "fqn": "hematology.plt-9"
    },
    {
      "elementKey": "plt-10",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltAverage",
      "tableColumn": "31",
      "validation": "numeric",
      "fqn": "hematology.plt-10"
    },
    {
      "elementKey": "pltFieldFactor",
      "defaultValue": "--pltFieldFactor--",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "pltEstimate",
      "tableColumn": "32",
      "validation": "numeric",
      "fqn": "hematology.pltFieldFactor"
    },
    {
      "elementKey": "pltAverage",
      "calculationType": "average",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "pltEstimate",
      "tableColumn": "33",
      "validation": "numeric",
      "fqn": "hematology.pltAverage"
    },
    {
      "elementKey": "pltEstimate",
      "calculationType": "product",
      "decimals": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "34",
      "validation": "numeric",
      "fqn": "hematology.pltEstimate"
    },
    {
      "elementKey": "pltAnalysis",
      "calculationType": "embedValue(hematology,tableCbcAnalysis,PLT)",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "[pltLowRange, pltHighRange]",
      "tableColumn": "35",
      "validation": "numeric",
      "fqn": "hematology.pltAnalysis"
    },
    {
      "elementKey": "pltLowRange",
      "calculationType": "multiplyBy(0.75, 0)",
      "decimals": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "36",
      "validation": "numeric",
      "fqn": "hematology.pltLowRange"
    },
    {
      "elementKey": "pltHighRange",
      "calculationType": "multiplyBy(1.25, 0)",
      "decimals": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "37",
      "validation": "numeric",
      "fqn": "hematology.pltHighRange"
    },
    {
      "elementKey": "pltComment",
      "formIndex": "2",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "tableColumn": "38",
      "fqn": "hematology.pltComment"
    },
    {
      "elementKey": "pltNormalMorphology",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "39",
      "fqn": "hematology.pltNormalMorphology"
    },
    {
      "elementKey": "pltMorphology",
      "formIndex": "2",
      "formCss": "form_label_short",
      "inputType": "boxcheckset",
      "options": [
        {
          "key": "Hypogranular",
          "text": "Hypogranular"
        },
        {
          "key": "Large or Giant Forms",
          "text": "Large or Giant Forms"
        },
        {
          "key": "Megakaryocytic Fragments",
          "text": "Megakaryocytic Fragments"
        },
        {
          "key": "Platelet Clumps",
          "text": "Platelet Clumps"
        },
        {
          "key": "Platelet Satellitism",
          "text": "Platelet Satellitism"
        },
        {
          "key": "Dysplastic",
          "text": "Dysplastic"
        }
      ],
      "tableColumn": "40",
      "fqn": "hematology.pltMorphology"
    },
    {
      "elementKey": "label43-7-1",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "validation": "numeric",
      "fqn": "hematology.label43-7-1"
    },
    {
      "elementKey": "label43-7-2",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "validation": "numeric",
      "fqn": "hematology.label43-7-2"
    },
    {
      "elementKey": "label43-7-3",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "validation": "numeric",
      "fqn": "hematology.label43-7-3"
    },
    {
      "elementKey": "label43-7-4",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-4"
    },
    {
      "elementKey": "cellCntSegNeutrophil",
      "formIndex": "2",
      "inputType": "number",
      "mandatory": "TRUE",
      "passToFunction": "[ cellCntTotal cellCntSegNeutrophilAbs]",
      "tableColumn": "41",
      "validation": "numeric",
      "fqn": "hematology.cellCntSegNeutrophil"
    },
    {
      "elementKey": "cellCntSegNeutrophilAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "42",
      "validation": "numeric",
      "fqn": "hematology.cellCntSegNeutrophilAbs"
    },
    {
      "elementKey": "label43-7-5",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-5"
    },
    {
      "elementKey": "cellCntBandCells",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntBandCellsAbs ]",
      "tableColumn": "43",
      "validation": "numeric",
      "fqn": "hematology.cellCntBandCells"
    },
    {
      "elementKey": "cellCntBandCellsAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "44",
      "validation": "numeric",
      "fqn": "hematology.cellCntBandCellsAbs"
    },
    {
      "elementKey": "label43-7-6",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-6"
    },
    {
      "elementKey": "cellCntLymph",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntLymphAbs ]",
      "tableColumn": "45",
      "validation": "numeric",
      "fqn": "hematology.cellCntLymph"
    },
    {
      "elementKey": "cellCntLymphAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "46",
      "validation": "numeric",
      "fqn": "hematology.cellCntLymphAbs"
    },
    {
      "elementKey": "label43-7-7",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-7"
    },
    {
      "elementKey": "cellCntMono",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntMonoAbs ]",
      "tableColumn": "47",
      "validation": "numeric",
      "fqn": "hematology.cellCntMono"
    },
    {
      "elementKey": "cellCntMonoAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "48",
      "validation": "numeric",
      "fqn": "hematology.cellCntMonoAbs"
    },
    {
      "elementKey": "label43-7-8",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-8"
    },
    {
      "elementKey": "cellCntEosin",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntEosinAbs ]",
      "tableColumn": "49",
      "validation": "numeric",
      "fqn": "hematology.cellCntEosin"
    },
    {
      "elementKey": "cellCntEosinAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "50",
      "validation": "numeric",
      "fqn": "hematology.cellCntEosinAbs"
    },
    {
      "elementKey": "label43-7-9",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-9"
    },
    {
      "elementKey": "cellCntBaso",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntBasoAbs ]",
      "tableColumn": "51",
      "validation": "numeric",
      "fqn": "hematology.cellCntBaso"
    },
    {
      "elementKey": "cellCntBasoAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "52",
      "validation": "numeric",
      "fqn": "hematology.cellCntBasoAbs"
    },
    {
      "elementKey": "label43-7-10",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-10"
    },
    {
      "elementKey": "cellCntMeta",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntMetaAbs ]",
      "tableColumn": "53",
      "validation": "numeric",
      "fqn": "hematology.cellCntMeta"
    },
    {
      "elementKey": "cellCntMetaAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "54",
      "validation": "numeric",
      "fqn": "hematology.cellCntMetaAbs"
    },
    {
      "elementKey": "label43-7-11",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-11"
    },
    {
      "elementKey": "cellCntMyelo",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntMyeloAbs ]",
      "tableColumn": "55",
      "validation": "numeric",
      "fqn": "hematology.cellCntMyelo"
    },
    {
      "elementKey": "cellCntMyeloAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "56",
      "validation": "numeric",
      "fqn": "hematology.cellCntMyeloAbs"
    },
    {
      "elementKey": "label43-7-12",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-12"
    },
    {
      "elementKey": "cellCntPromyelo",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntPromyeloAbs ]",
      "tableColumn": "57",
      "validation": "numeric",
      "fqn": "hematology.cellCntPromyelo"
    },
    {
      "elementKey": "cellCntPromyeloAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "58",
      "validation": "numeric",
      "fqn": "hematology.cellCntPromyeloAbs"
    },
    {
      "elementKey": "label43-7-13",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-13"
    },
    {
      "elementKey": "cellCntBlast",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntBlastAbs ]",
      "tableColumn": "59",
      "validation": "numeric",
      "fqn": "hematology.cellCntBlast"
    },
    {
      "elementKey": "cellCntBlastAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "defaultValue": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "60",
      "validation": "numeric",
      "fqn": "hematology.cellCntBlastAbs"
    },
    {
      "elementKey": "label43-7-15",
      "formIndex": "2",
      "inputType": "formTableText",
      "validation": "numeric",
      "fqn": "hematology.label43-7-15"
    },
    {
      "elementKey": "cellCntOther",
      "formIndex": "2",
      "inputType": "number",
      "passToFunction": "[ cellCntTotal cellCntOtherAbs ]",
      "tableColumn": "61",
      "validation": "numeric",
      "fqn": "hematology.cellCntOther"
    },
    {
      "elementKey": "cellCntOtherAbs",
      "calculationType": "wbcAbs",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "passToFunction": "cellCntAbsTotal",
      "tableColumn": "62",
      "validation": "numeric",
      "fqn": "hematology.cellCntOtherAbs"
    },
    {
      "elementKey": "cellCntOtherSpecify",
      "formIndex": "2",
      "formCss": "form-table-span-2",
      "inputType": "text",
      "tableColumn": "63",
      "fqn": "hematology.cellCntOtherSpecify"
    },
    {
      "elementKey": "spacer11",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "hematology.spacer11"
    },
    {
      "elementKey": "label43-7-16",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "hematology.label43-7-16"
    },
    {
      "elementKey": "cellCntTotal",
      "calculationType": "sum",
      "decimals": "0",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "64",
      "fqn": "hematology.cellCntTotal"
    },
    {
      "elementKey": "cellCntAbsTotal",
      "calculationType": "sum",
      "decimals": "1",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "65",
      "fqn": "hematology.cellCntAbsTotal"
    },
    {
      "elementKey": "cellCntNRBCs",
      "formIndex": "2",
      "formCss": "field_full_line input-element-small",
      "inputType": "number",
      "tableColumn": "66",
      "fqn": "hematology.cellCntNRBCs"
    },
    {
      "elementKey": "cellCntComment",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "67",
      "fqn": "hematology.cellCntComment"
    },
    {
      "elementKey": "wbcmNormalMorphology",
      "formIndex": "2",
      "formCss": "wbcmNormalMorphology",
      "inputType": "checkbox",
      "tableColumn": "68",
      "fqn": "hematology.wbcmNormalMorphology"
    },
    {
      "elementKey": "wbcmAbnormalMorphology",
      "formIndex": "2",
      "formCss": "wbcmAbnormalMorphology",
      "inputType": "checkbox",
      "tableColumn": "69",
      "fqn": "hematology.wbcmAbnormalMorphology"
    },
    {
      "elementKey": "wbcmAbnormalTypeMorphology",
      "formIndex": "2",
      "formCss": "wbcmAbnormalTypeMorphology",
      "inputType": "boxcheckset",
      "options": [
        {
          "key": "Immature Cells",
          "text": "Immature Cells"
        },
        {
          "key": "Hypersegmented Neutrophils",
          "text": "Hypersegmented Neutrophils"
        },
        {
          "key": "Dysplastic Cells",
          "text": "Dysplastic Cells"
        },
        {
          "key": "Pelger-Huët Cells",
          "text": "Pelger-Huët Cells"
        },
        {
          "key": "Auer Rods",
          "text": "Auer Rods"
        },
        {
          "key": "Smudge Cells",
          "text": "Smudge Cells"
        },
        {
          "key": "Reactive Lymphocytes",
          "text": "Reactive Lymphocytes"
        },
        {
          "key": "Disintegrated Cells",
          "text": "Disintegrated Cells"
        }
      ],
      "tableColumn": "70",
      "fqn": "hematology.wbcmAbnormalTypeMorphology"
    },
    {
      "elementKey": "wbcmToxicTypeMorphology",
      "formIndex": "2",
      "formCss": "wbcmToxicTypeMorphology",
      "inputType": "boxcheckset",
      "options": [
        {
          "key": "Döhle Bodies",
          "text": "Döhle Bodies"
        },
        {
          "key": "Toxic Granulation",
          "text": "Toxic Granulation"
        },
        {
          "key": "Toxic Vacuolation",
          "text": "Toxic Vacuolation"
        }
      ],
      "tableColumn": "71",
      "fqn": "hematology.wbcmToxicTypeMorphology"
    },
    {
      "elementKey": "rcmNormal",
      "formIndex": "2",
      "formCss": "rcm-one-part",
      "inputType": "checkbox",
      "tableColumn": "72",
      "fqn": "hematology.rcmNormal"
    },
    {
      "elementKey": "rcmConsistent",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "73",
      "fqn": "hematology.rcmConsistent"
    },
    {
      "elementKey": "rcmMcvMchc",
      "formIndex": "2",
      "formCss": "rcm-two-part-2 rcmMcvMchc",
      "inputType": "text",
      "tableColumn": "74",
      "fqn": "hematology.rcmMcvMchc"
    },
    {
      "elementKey": "rcmAnisocytosis",
      "formIndex": "2",
      "formCss": "rcm-one-part-indented",
      "inputType": "checkbox",
      "tableColumn": "75",
      "fqn": "hematology.rcmAnisocytosis"
    },
    {
      "elementKey": "rcmPolychromasia",
      "formIndex": "2",
      "formCss": "rcm-one-part-indented",
      "inputType": "checkbox",
      "tableColumn": "76",
      "fqn": "hematology.rcmPolychromasia"
    },
    {
      "elementKey": "rcmRouleaux",
      "formIndex": "2",
      "formCss": "rcm-one-part-indented",
      "inputType": "checkbox",
      "tableColumn": "77",
      "fqn": "hematology.rcmRouleaux"
    },
    {
      "elementKey": "rcmAcanthocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "78",
      "fqn": "hematology.rcmAcanthocytes"
    },
    {
      "elementKey": "rcmAcanthocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "79",
      "fqn": "hematology.rcmAcanthocytesGrading"
    },
    {
      "elementKey": "rcmCodocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "80",
      "fqn": "hematology.rcmCodocytes"
    },
    {
      "elementKey": "rcmCodocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "81",
      "fqn": "hematology.rcmCodocytesGrading"
    },
    {
      "elementKey": "rcmDacryocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "82",
      "fqn": "hematology.rcmDacryocytes"
    },
    {
      "elementKey": "rcmDacryocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "83",
      "fqn": "hematology.rcmDacryocytesGrading"
    },
    {
      "elementKey": "rcmDrepanocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "84",
      "fqn": "hematology.rcmDrepanocytes"
    },
    {
      "elementKey": "rcmDrepanocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "85",
      "fqn": "hematology.rcmDrepanocytesGrading"
    },
    {
      "elementKey": "rcmEchinocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "86",
      "fqn": "hematology.rcmEchinocytes"
    },
    {
      "elementKey": "rcmEchinocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "87",
      "fqn": "hematology.rcmEchinocytesGrading"
    },
    {
      "elementKey": "rcmOvalocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "88",
      "fqn": "hematology.rcmOvalocytes"
    },
    {
      "elementKey": "rcmOvalocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "89",
      "fqn": "hematology.rcmOvalocytesGrading"
    },
    {
      "elementKey": "rcmMacroOvalocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "90",
      "fqn": "hematology.rcmMacroOvalocytes"
    },
    {
      "elementKey": "rcmMacroOvalocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "91",
      "fqn": "hematology.rcmMacroOvalocytesGrading"
    },
    {
      "elementKey": "rcmStomatocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "92",
      "fqn": "hematology.rcmStomatocytes"
    },
    {
      "elementKey": "rcmStomatocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "93",
      "fqn": "hematology.rcmStomatocytesGrading"
    },
    {
      "elementKey": "rcmSpherocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "94",
      "fqn": "hematology.rcmSpherocytes"
    },
    {
      "elementKey": "rcmSpherocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "95",
      "fqn": "hematology.rcmSpherocytesGrading"
    },
    {
      "elementKey": "rcmICC",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "96",
      "fqn": "hematology.rcmICC"
    },
    {
      "elementKey": "rcmICCGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "97",
      "fqn": "hematology.rcmICCGrading"
    },
    {
      "elementKey": "rcmICCSchistocytes",
      "formIndex": "2",
      "formCss": "rcm-two-part-1",
      "inputType": "checkbox",
      "tableColumn": "98",
      "fqn": "hematology.rcmICCSchistocytes"
    },
    {
      "elementKey": "rcmICCSchistocytesGrading",
      "formIndex": "2",
      "formCss": "rcm-two-part-2",
      "inputType": "select",
      "options": [
        {
          "key": "1+",
          "text": "1+"
        },
        {
          "key": "2+",
          "text": "2+"
        },
        {
          "key": "3+",
          "text": "3+"
        },
        {
          "key": "4+",
          "text": "4+"
        }
      ],
      "tableColumn": "99",
      "fqn": "hematology.rcmICCSchistocytesGrading"
    },
    {
      "elementKey": "rcmBasophilicStippling",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "100",
      "fqn": "hematology.rcmBasophilicStippling"
    },
    {
      "elementKey": "rcmHowellJollyBodies",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "101",
      "fqn": "hematology.rcmHowellJollyBodies"
    },
    {
      "elementKey": "rcmPappenheimerBodies",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "102",
      "fqn": "hematology.rcmPappenheimerBodies"
    },
    {
      "elementKey": "rcmMalariaType",
      "formIndex": "2",
      "formCss": "form_label_short, rcmMalariaType",
      "inputType": "boxcheckset",
      "options": [
        {
          "key": "Gametocyte",
          "text": "Gametocyte"
        },
        {
          "key": "Ring Form/Trophozoite",
          "text": "Ring Form/Trophozoite"
        },
        {
          "key": "Schizont",
          "text": "Schizont"
        }
      ],
      "tableColumn": "103",
      "fqn": "hematology.rcmMalariaType"
    },
    {
      "elementKey": "rbcComments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "104",
      "fqn": "hematology.rbcComments"
    },
    {
      "elementKey": "referralToHema",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "105",
      "fqn": "hematology.referralToHema"
    },
    {
      "elementKey": "explain",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "106",
      "fqn": "hematology.explain"
    }
  ],
  "pageElements": {
    "tableCbcAnalysis": {
      "elementKey": "tableCbcAnalysis",
      "pageElementIndex": "1",
      "pageKey": "hematology",
      "tableKey": "tableCbcAnalysis",
      "isTable": true,
      "tableAction": "[\"openDialog\",  \"hematology.tablePbfReview\"]",
      "form": {
        "elementKey": "tableCbcAnalysis",
        "label": "CBC analysis",
        "addButtonText": "Add a CBC Report",
        "formKey": "tableCbcAnalysis",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "hematology.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableCbcAnalysis_name",
              "tableCbcAnalysis_profession",
              "tableCbcAnalysis_day",
              "tableCbcAnalysis_time"
            ]
          },
          {
            "elementKey": "cGroup43-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "hematology.cGroup43-2",
            "gIndex": "2",
            "gChildren": [
              "wbc",
              "wbc-interp",
              "spacer1",
              "rbc",
              "rbc-interp",
              "spacer2",
              "hgb",
              "hgb-interp",
              "spacer3",
              "HCT",
              "HCT-interp",
              "spacer4",
              "MCV",
              "MCV-interp",
              "spacer5",
              "MCH",
              "MCH-interp",
              "spacer6",
              "MCHC",
              "MCHC-interp",
              "spacer7",
              "RDW",
              "RDW-interp",
              "spacer8",
              "PLT",
              "PLT-interp",
              "spacer9",
              "MPV",
              "MPV-interp",
              "spacer10"
            ]
          },
          {
            "elementKey": "cGroup43-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "hematology.cGroup43-3",
            "gIndex": "3",
            "gChildren": [
              "cbcScatterPlot",
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "tableCbcAnalysis_name": "",
          "tableCbcAnalysis_profession": "",
          "tableCbcAnalysis_day": "",
          "tableCbcAnalysis_time": "",
          "wbc": "",
          "wbc-interp": "",
          "rbc": "",
          "rbc-interp": "",
          "hgb": "",
          "hgb-interp": "",
          "HCT": "",
          "HCT-interp": "",
          "MCV": "",
          "MCV-interp": "",
          "MCH": "",
          "MCH-interp": "",
          "MCHC": "",
          "MCHC-interp": "",
          "RDW": "",
          "RDW-interp": "",
          "PLT": "",
          "PLT-interp": "",
          "MPV": "",
          "MPV-interp": "",
          "cbcScatterPlot": "",
          "comments": ""
        }
      },
      "fqn": "hematology.tableCbcAnalysis",
      "tableActionType": "openDialog",
      "tableActionRef": "hematology.tablePbfReview",
      "taTargetPageKey": "hematology",
      "taTargetTableKey": "tablePbfReview",
      "taSourcePageKey": "hematology",
      "taSourceTableKey": "tableCbcAnalysis",
      "tableChildren": [
        "tableCbcAnalysis_id",
        "tableCbcAnalysis_day",
        "tableCbcAnalysis_time",
        "wbc",
        "wbc-interp",
        "rbc",
        "rbc-interp",
        "hgb",
        "hgb-interp",
        "HCT",
        "HCT-interp",
        "MCV",
        "MCV-interp",
        "MCH",
        "MCH-interp",
        "MCHC",
        "MCHC-interp",
        "RDW",
        "RDW-interp",
        "PLT",
        "PLT-interp",
        "MPV",
        "MPV-interp",
        "cbcScatterPlot",
        "comments",
        "tableCbcAnalysis_name",
        "tableCbcAnalysis_profession"
      ],
      "hasRecHeader": true
    },
    "tablePbfReview": {
      "elementKey": "tablePbfReview",
      "pageElementIndex": "2",
      "pageKey": "hematology",
      "tableKey": "tablePbfReview",
      "isTable": true,
      "form": {
        "elementKey": "tablePbfReview",
        "label": "PBF Review",
        "addButtonText": "NONE",
        "formKey": "tablePbfReview",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "hematology.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tablePbfReview_name",
              "tablePbfReview_profession",
              "tablePbfReview_day",
              "tablePbfReview_time"
            ]
          },
          {
            "elementKey": "cGroup43-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "hematology.cGroup43-2",
            "gIndex": "2",
            "gChildren": [
              "bloodAnalysis"
            ]
          },
          {
            "elementKey": "cGroup43-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "hematology.cGroup43-3",
            "gIndex": "3",
            "gChildren": [
              "actionsTaken",
              "scanComments"
            ]
          },
          {
            "elementKey": "cGroup43-4",
            "formCss": "grid-left-to-right-4",
            "fqn": "hematology.cGroup43-4",
            "gIndex": "4",
            "gChildren": [
              "wbc-1",
              "wbc-2",
              "wbc-3",
              "wbc-4",
              "wbc-5",
              "wbc-6",
              "wbc-7",
              "wbc-8",
              "wbc-9",
              "wbc-10",
              "wbcFieldFactor",
              "wbcAverage",
              "wbcEstimate",
              "wbcAnalysis",
              "wbcLowRange",
              "wbcHighRange",
              "wbcComment",
              "wbcCorrectedWbc"
            ]
          },
          {
            "elementKey": "cGroup43-5",
            "formCss": "grid-left-to-right-4",
            "fqn": "hematology.cGroup43-5",
            "gIndex": "5",
            "gChildren": [
              "plt-1",
              "plt-2",
              "plt-3",
              "plt-4",
              "plt-5",
              "plt-6",
              "plt-7",
              "plt-8",
              "plt-9",
              "plt-10",
              "pltFieldFactor",
              "pltAverage",
              "pltEstimate",
              "pltAnalysis",
              "pltLowRange",
              "pltHighRange",
              "pltComment"
            ]
          },
          {
            "elementKey": "cGroup43-6",
            "formCss": "grid-left-to-right-3",
            "fqn": "hematology.cGroup43-6",
            "gIndex": "6",
            "gChildren": [
              "pltNormalMorphology",
              "pltMorphology"
            ]
          },
          {
            "elementKey": "cGroup43-7",
            "formCss": "form-table-group wbc-differential-group",
            "fqn": "hematology.cGroup43-7",
            "gIndex": "7",
            "gChildren": [
              "label43-7-1",
              "label43-7-2",
              "label43-7-3",
              "label43-7-4",
              "cellCntSegNeutrophil",
              "cellCntSegNeutrophilAbs",
              "label43-7-5",
              "cellCntBandCells",
              "cellCntBandCellsAbs",
              "label43-7-6",
              "cellCntLymph",
              "cellCntLymphAbs",
              "label43-7-7",
              "cellCntMono",
              "cellCntMonoAbs",
              "label43-7-8",
              "cellCntEosin",
              "cellCntEosinAbs",
              "label43-7-9",
              "cellCntBaso",
              "cellCntBasoAbs",
              "label43-7-10",
              "cellCntMeta",
              "cellCntMetaAbs",
              "label43-7-11",
              "cellCntMyelo",
              "cellCntMyeloAbs",
              "label43-7-12",
              "cellCntPromyelo",
              "cellCntPromyeloAbs",
              "label43-7-13",
              "cellCntBlast",
              "cellCntBlastAbs",
              "label43-7-15",
              "cellCntOther",
              "cellCntOtherAbs",
              "cellCntOtherSpecify",
              "spacer11",
              "label43-7-16",
              "cellCntTotal",
              "cellCntAbsTotal"
            ]
          },
          {
            "elementKey": "cGroup43-8",
            "formCss": "grid-left-to-right-1",
            "fqn": "hematology.cGroup43-8",
            "gIndex": "8",
            "gChildren": [
              "cellCntNRBCs",
              "cellCntComment"
            ]
          },
          {
            "elementKey": "cGroup43-9",
            "formCss": "grid-left-to-right-custom wbcMorphologyGroup",
            "fqn": "hematology.cGroup43-9",
            "gIndex": "9",
            "gChildren": [
              "wbcmNormalMorphology",
              "wbcmAbnormalMorphology",
              "wbcmAbnormalTypeMorphology",
              "wbcmToxicTypeMorphology"
            ]
          },
          {
            "elementKey": "cGroup43-10",
            "formCss": "rcm-morphology-group",
            "fqn": "hematology.cGroup43-10",
            "gIndex": "10",
            "gChildren": [
              "rcmNormal",
              "rcmConsistent",
              "rcmMcvMchc",
              "rcmAnisocytosis",
              "rcmPolychromasia",
              "rcmRouleaux",
              "rcmAcanthocytes",
              "rcmAcanthocytesGrading",
              "rcmCodocytes",
              "rcmCodocytesGrading",
              "rcmDacryocytes",
              "rcmDacryocytesGrading",
              "rcmDrepanocytes",
              "rcmDrepanocytesGrading",
              "rcmEchinocytes",
              "rcmEchinocytesGrading",
              "rcmOvalocytes",
              "rcmOvalocytesGrading",
              "rcmMacroOvalocytes",
              "rcmMacroOvalocytesGrading",
              "rcmStomatocytes",
              "rcmStomatocytesGrading",
              "rcmSpherocytes",
              "rcmSpherocytesGrading",
              "rcmICC",
              "rcmICCGrading",
              "rcmICCSchistocytes",
              "rcmICCSchistocytesGrading"
            ]
          },
          {
            "elementKey": "cGroup43-11",
            "formCss": "grid-left-to-right-3",
            "fqn": "hematology.cGroup43-11",
            "gIndex": "11",
            "gChildren": [
              "rcmBasophilicStippling",
              "rcmHowellJollyBodies",
              "rcmPappenheimerBodies",
              "rcmMalariaType"
            ]
          },
          {
            "elementKey": "cGroup43-12",
            "formCss": "grid-left-to-right-1",
            "fqn": "hematology.cGroup43-12",
            "gIndex": "12",
            "gChildren": [
              "rbcComments"
            ]
          },
          {
            "elementKey": "cGroup43-13",
            "formCss": "grid-left-to-right-1",
            "fqn": "hematology.cGroup43-13",
            "gIndex": "13",
            "gChildren": [
              "referralToHema",
              "explain"
            ]
          }
        ],
        "ehr_data": {
          "tablePbfReview_name": "",
          "tablePbfReview_profession": "",
          "tablePbfReview_day": "",
          "tablePbfReview_time": "",
          "bloodAnalysis": "",
          "actionsTaken": "",
          "scanComments": "",
          "wbc-1": "",
          "wbc-2": "",
          "wbc-3": "",
          "wbc-4": "",
          "wbc-5": "",
          "wbc-6": "",
          "wbc-7": "",
          "wbc-8": "",
          "wbc-9": "",
          "wbc-10": "",
          "wbcFieldFactor": "--wbcFieldFactor--",
          "wbcAverage": "",
          "wbcEstimate": "",
          "wbcAnalysis": "",
          "wbcLowRange": "",
          "wbcHighRange": "",
          "wbcComment": "",
          "wbcCorrectedWbc": "",
          "plt-1": "",
          "plt-2": "",
          "plt-3": "",
          "plt-4": "",
          "plt-5": "",
          "plt-6": "",
          "plt-7": "",
          "plt-8": "",
          "plt-9": "",
          "plt-10": "",
          "pltFieldFactor": "--pltFieldFactor--",
          "pltAverage": "",
          "pltEstimate": "",
          "pltAnalysis": "",
          "pltLowRange": "",
          "pltHighRange": "",
          "pltComment": "",
          "pltNormalMorphology": "",
          "pltMorphology": "",
          "cellCntSegNeutrophil": "",
          "cellCntSegNeutrophilAbs": "",
          "cellCntBandCells": "",
          "cellCntBandCellsAbs": "",
          "cellCntLymph": "",
          "cellCntLymphAbs": "",
          "cellCntMono": "",
          "cellCntMonoAbs": "",
          "cellCntEosin": "",
          "cellCntEosinAbs": "",
          "cellCntBaso": "",
          "cellCntBasoAbs": "",
          "cellCntMeta": "0",
          "cellCntMetaAbs": "0",
          "cellCntMyelo": "0",
          "cellCntMyeloAbs": "0",
          "cellCntPromyelo": "0",
          "cellCntPromyeloAbs": "0",
          "cellCntBlast": "0",
          "cellCntBlastAbs": "0",
          "cellCntOther": "",
          "cellCntOtherAbs": "",
          "cellCntOtherSpecify": "",
          "cellCntTotal": "",
          "cellCntAbsTotal": "",
          "cellCntNRBCs": "",
          "cellCntComment": "",
          "wbcmNormalMorphology": "",
          "wbcmAbnormalMorphology": "",
          "wbcmAbnormalTypeMorphology": "",
          "wbcmToxicTypeMorphology": "",
          "rcmNormal": "",
          "rcmConsistent": "",
          "rcmMcvMchc": "",
          "rcmAnisocytosis": "",
          "rcmPolychromasia": "",
          "rcmRouleaux": "",
          "rcmAcanthocytes": "",
          "rcmAcanthocytesGrading": "",
          "rcmCodocytes": "",
          "rcmCodocytesGrading": "",
          "rcmDacryocytes": "",
          "rcmDacryocytesGrading": "",
          "rcmDrepanocytes": "",
          "rcmDrepanocytesGrading": "",
          "rcmEchinocytes": "",
          "rcmEchinocytesGrading": "",
          "rcmOvalocytes": "",
          "rcmOvalocytesGrading": "",
          "rcmMacroOvalocytes": "",
          "rcmMacroOvalocytesGrading": "",
          "rcmStomatocytes": "",
          "rcmStomatocytesGrading": "",
          "rcmSpherocytes": "",
          "rcmSpherocytesGrading": "",
          "rcmICC": "",
          "rcmICCGrading": "",
          "rcmICCSchistocytes": "",
          "rcmICCSchistocytesGrading": "",
          "rcmBasophilicStippling": "",
          "rcmHowellJollyBodies": "",
          "rcmPappenheimerBodies": "",
          "rcmMalariaType": "",
          "rbcComments": "",
          "referralToHema": "",
          "explain": ""
        }
      },
      "fqn": "hematology.tablePbfReview",
      "tableChildren": [
        "tablePbfReview_id",
        "tablePbfReview_day",
        "tablePbfReview_time",
        "bloodAnalysis",
        "actionsTaken",
        "scanComments",
        "wbc-1",
        "wbc-2",
        "wbc-3",
        "wbc-4",
        "wbc-5",
        "wbc-6",
        "wbc-7",
        "wbc-8",
        "wbc-9",
        "wbc-10",
        "wbcFieldFactor",
        "wbcAverage",
        "wbcEstimate",
        "wbcAnalysis",
        "wbcLowRange",
        "wbcHighRange",
        "wbcComment",
        "wbcCorrectedWbc",
        "plt-1",
        "plt-2",
        "plt-3",
        "plt-4",
        "plt-5",
        "plt-6",
        "plt-7",
        "plt-8",
        "plt-9",
        "plt-10",
        "pltFieldFactor",
        "pltAverage",
        "pltEstimate",
        "pltAnalysis",
        "pltLowRange",
        "pltHighRange",
        "pltComment",
        "pltNormalMorphology",
        "pltMorphology",
        "cellCntSegNeutrophil",
        "cellCntSegNeutrophilAbs",
        "cellCntBandCells",
        "cellCntBandCellsAbs",
        "cellCntLymph",
        "cellCntLymphAbs",
        "cellCntMono",
        "cellCntMonoAbs",
        "cellCntEosin",
        "cellCntEosinAbs",
        "cellCntBaso",
        "cellCntBasoAbs",
        "cellCntMeta",
        "cellCntMetaAbs",
        "cellCntMyelo",
        "cellCntMyeloAbs",
        "cellCntPromyelo",
        "cellCntPromyeloAbs",
        "cellCntBlast",
        "cellCntBlastAbs",
        "cellCntOther",
        "cellCntOtherAbs",
        "cellCntOtherSpecify",
        "cellCntTotal",
        "cellCntAbsTotal",
        "cellCntNRBCs",
        "cellCntComment",
        "wbcmNormalMorphology",
        "wbcmAbnormalMorphology",
        "wbcmAbnormalTypeMorphology",
        "wbcmToxicTypeMorphology",
        "rcmNormal",
        "rcmConsistent",
        "rcmMcvMchc",
        "rcmAnisocytosis",
        "rcmPolychromasia",
        "rcmRouleaux",
        "rcmAcanthocytes",
        "rcmAcanthocytesGrading",
        "rcmCodocytes",
        "rcmCodocytesGrading",
        "rcmDacryocytes",
        "rcmDacryocytesGrading",
        "rcmDrepanocytes",
        "rcmDrepanocytesGrading",
        "rcmEchinocytes",
        "rcmEchinocytesGrading",
        "rcmOvalocytes",
        "rcmOvalocytesGrading",
        "rcmMacroOvalocytes",
        "rcmMacroOvalocytesGrading",
        "rcmStomatocytes",
        "rcmStomatocytesGrading",
        "rcmSpherocytes",
        "rcmSpherocytesGrading",
        "rcmICC",
        "rcmICCGrading",
        "rcmICCSchistocytes",
        "rcmICCSchistocytesGrading",
        "rcmBasophilicStippling",
        "rcmHowellJollyBodies",
        "rcmPappenheimerBodies",
        "rcmMalariaType",
        "rbcComments",
        "referralToHema",
        "explain",
        "tablePbfReview_name",
        "tablePbfReview_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS