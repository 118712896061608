/* eslint-disable */
const DEFS = {
  "pageDataKey": "medLabUrinalysis",
  "pIndex": "46",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "tableUrinalysis_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabUrinalysis.name"
    },
    {
      "elementKey": "tableUrinalysis_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableUrinalysis_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabUrinalysis.profession"
    },
    {
      "elementKey": "tableUrinalysis_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabUrinalysis.day"
    },
    {
      "elementKey": "tableUrinalysis_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabUrinalysis.time"
    },
    {
      "elementKey": "urinAccession",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "medLabUrinalysis.urinAccession"
    },
    {
      "elementKey": "urinCollDay",
      "defaultValue": "Today",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabUrinalysis.urinCollDay"
    },
    {
      "elementKey": "urinCollTime",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "medLabUrinalysis.urinCollTime"
    },
    {
      "elementKey": "urinOrderPhys",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "medLabUrinalysis.urinOrderPhys"
    },
    {
      "elementKey": "urinTransparency",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Clear",
          "text": "Clear"
        },
        {
          "key": "Hazy",
          "text": "Hazy"
        },
        {
          "key": "Cloudy",
          "text": "Cloudy"
        },
        {
          "key": "Turbid",
          "text": "Turbid"
        }
      ],
      "tableColumn": "5",
      "fqn": "medLabUrinalysis.urinTransparency"
    },
    {
      "elementKey": "urinColour",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Colorless",
          "text": "Colorless"
        },
        {
          "key": "Pale yellow",
          "text": "Pale yellow"
        },
        {
          "key": "Yellow",
          "text": "Yellow"
        },
        {
          "key": "Amber",
          "text": "Amber"
        },
        {
          "key": "Orange",
          "text": "Orange"
        },
        {
          "key": "Red",
          "text": "Red"
        },
        {
          "key": "Pink",
          "text": "Pink"
        },
        {
          "key": "Brown",
          "text": "Brown"
        },
        {
          "key": "Green",
          "text": "Green"
        }
      ],
      "tableColumn": "6",
      "fqn": "medLabUrinalysis.urinColour"
    },
    {
      "elementKey": "label55-25",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-25"
    },
    {
      "elementKey": "label55-26",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-26"
    },
    {
      "elementKey": "label55-27",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-27"
    },
    {
      "elementKey": "label55-28",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-28"
    },
    {
      "elementKey": "label55-29",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-29"
    },
    {
      "elementKey": "uchemManGlucose",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "7",
      "fqn": "medLabUrinalysis.uchemManGlucose",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "uchemAutoGlucose",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "8",
      "fqn": "medLabUrinalysis.uchemAutoGlucose",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "label55-30",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-30"
    },
    {
      "elementKey": "label55-31",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-31"
    },
    {
      "elementKey": "uchemManBilirubin",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "medLabUrinalysis.uchemManBilirubin"
    },
    {
      "elementKey": "uchemAutoBilirubin",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "medLabUrinalysis.uchemAutoBilirubin"
    },
    {
      "elementKey": "label55-32",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-32"
    },
    {
      "elementKey": "label55-33",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-33"
    },
    {
      "elementKey": "uchemManKetone",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "11",
      "fqn": "medLabUrinalysis.uchemManKetone",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "uchemAutoKetone",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "12",
      "fqn": "medLabUrinalysis.uchemAutoKetone",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "label55-34",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-34"
    },
    {
      "elementKey": "label55-35",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-35"
    },
    {
      "elementKey": "uchemManSpecificGravity",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "medLabUrinalysis.uchemManSpecificGravity"
    },
    {
      "elementKey": "uchemAutoSpecificGravity",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "medLabUrinalysis.uchemAutoSpecificGravity"
    },
    {
      "elementKey": "label55-36",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-36"
    },
    {
      "elementKey": "label55-37",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-37"
    },
    {
      "elementKey": "uchemManBlood",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "medLabUrinalysis.uchemManBlood"
    },
    {
      "elementKey": "uchemAutoBlood",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "Ery/μL",
      "tableColumn": "16",
      "fqn": "medLabUrinalysis.uchemAutoBlood",
      "suffixText": "Ery/μL"
    },
    {
      "elementKey": "label55-38",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-38"
    },
    {
      "elementKey": "label55-39",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-39"
    },
    {
      "elementKey": "uchemManpH",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "medLabUrinalysis.uchemManpH"
    },
    {
      "elementKey": "uchemAutopH",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "medLabUrinalysis.uchemAutopH"
    },
    {
      "elementKey": "label55-40",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-40"
    },
    {
      "elementKey": "label55-41",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-41"
    },
    {
      "elementKey": "uchemManProtein",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "g/L",
      "tableColumn": "19",
      "fqn": "medLabUrinalysis.uchemManProtein",
      "suffixText": "g/L"
    },
    {
      "elementKey": "uchemAutoProtein",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "g/L",
      "tableColumn": "20",
      "fqn": "medLabUrinalysis.uchemAutoProtein",
      "suffixText": "g/L"
    },
    {
      "elementKey": "label55-42",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-42"
    },
    {
      "elementKey": "label55-43",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-43"
    },
    {
      "elementKey": "uchemManUrobilinogen",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "μmol/L",
      "tableColumn": "21",
      "fqn": "medLabUrinalysis.uchemManUrobilinogen",
      "suffixText": "μmol/L"
    },
    {
      "elementKey": "uchemAutoUrobilinogen",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "μmol/L",
      "tableColumn": "22",
      "fqn": "medLabUrinalysis.uchemAutoUrobilinogen",
      "suffixText": "μmol/L"
    },
    {
      "elementKey": "label55-44",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-44"
    },
    {
      "elementKey": "label55-45",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-45"
    },
    {
      "elementKey": "uchemManNitrite",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "23",
      "fqn": "medLabUrinalysis.uchemManNitrite"
    },
    {
      "elementKey": "uchemAutoNitrite",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "medLabUrinalysis.uchemAutoNitrite"
    },
    {
      "elementKey": "label55-46",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-46"
    },
    {
      "elementKey": "label55-47",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-47"
    },
    {
      "elementKey": "uchemManLeukocytes",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "medLabUrinalysis.uchemManLeukocytes"
    },
    {
      "elementKey": "uchemAutoLeukocytes",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "Leu/μL",
      "tableColumn": "26",
      "fqn": "medLabUrinalysis.uchemAutoLeukocytes",
      "suffixText": "Leu/μL"
    },
    {
      "elementKey": "label55-48",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-48"
    },
    {
      "elementKey": "uchemMicroIndicater",
      "defaultValue": "TRUE",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "27",
      "fqn": "medLabUrinalysis.uchemMicroIndicater"
    },
    {
      "elementKey": "urnDiscMA",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "28",
      "fqn": "medLabUrinalysis.urnDiscMA"
    },
    {
      "elementKey": "uchemInstructorlComment",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "29",
      "fqn": "medLabUrinalysis.uchemInstructorlComment"
    },
    {
      "elementKey": "uchemSulfosalicylic",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "negative",
          "text": "negative"
        },
        {
          "key": "trace",
          "text": "trace"
        },
        {
          "key": "+1",
          "text": "+1"
        },
        {
          "key": "+2",
          "text": "+2"
        },
        {
          "key": "+3",
          "text": "+3"
        },
        {
          "key": "+4",
          "text": "+4"
        }
      ],
      "tableColumn": "30",
      "fqn": "medLabUrinalysis.uchemSulfosalicylic"
    },
    {
      "elementKey": "uchemIcotest",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "negative",
          "text": "negative"
        },
        {
          "key": "positive",
          "text": "positive"
        }
      ],
      "tableColumn": "31",
      "fqn": "medLabUrinalysis.uchemIcotest"
    },
    {
      "elementKey": "label55-49",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-49"
    },
    {
      "elementKey": "label55-50",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-50"
    },
    {
      "elementKey": "label55-51",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-51"
    },
    {
      "elementKey": "label55-52",
      "formIndex": "2",
      "inputType": "formTableHeader",
      "fqn": "medLabUrinalysis.label55-52"
    },
    {
      "elementKey": "label55-53",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-53"
    },
    {
      "elementKey": "umicroWBC",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "0-1",
          "text": "0-1"
        },
        {
          "key": "2-5",
          "text": "2-5"
        },
        {
          "key": "6-10",
          "text": "6-10"
        },
        {
          "key": "11-20",
          "text": "11-20"
        },
        {
          "key": "21-50",
          "text": "21-50"
        },
        {
          "key": "50+",
          "text": "50+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "32",
      "fqn": "medLabUrinalysis.umicroWBC"
    },
    {
      "elementKey": "spacer12",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer12"
    },
    {
      "elementKey": "label55-54",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-54"
    },
    {
      "elementKey": "label55-55",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-55"
    },
    {
      "elementKey": "umicroRBC",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "0-1"
        },
        {
          "key": "5",
          "text": "2-5"
        },
        {
          "key": "10",
          "text": "6-10"
        },
        {
          "key": "20",
          "text": "11-20"
        },
        {
          "key": "50",
          "text": "21-50"
        },
        {
          "key": "plus",
          "text": "50+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "33",
      "fqn": "medLabUrinalysis.umicroRBC"
    },
    {
      "elementKey": "spacer13",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer13"
    },
    {
      "elementKey": "label55-56",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-56"
    },
    {
      "elementKey": "label55-57",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-57"
    },
    {
      "elementKey": "umicroEpithelial",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "2",
          "text": "0-2"
        },
        {
          "key": "5",
          "text": "3-5"
        },
        {
          "key": "10",
          "text": "6-10"
        },
        {
          "key": "plus",
          "text": "10+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "34",
      "fqn": "medLabUrinalysis.umicroEpithelial"
    },
    {
      "elementKey": "umicroTypesEpithelial Cells",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "squamous",
          "text": "Squamous"
        },
        {
          "key": "transitional",
          "text": "Transitional"
        },
        {
          "key": "rte",
          "text": "RTE"
        }
      ],
      "tableColumn": "35",
      "fqn": "medLabUrinalysis.umicroTypesEpithelial Cells"
    },
    {
      "elementKey": "label55-58",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-58"
    },
    {
      "elementKey": "label55-59",
      "formIndex": "2",
      "formCss": "form-table-span-4-rows",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-59"
    },
    {
      "elementKey": "umicrocCastsResults1",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "2",
          "text": "0-2"
        },
        {
          "key": "5",
          "text": "3-5"
        },
        {
          "key": "10",
          "text": "6-10"
        },
        {
          "key": "plus",
          "text": "10+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "36",
      "fqn": "medLabUrinalysis.umicrocCastsResults1"
    },
    {
      "elementKey": "umicrocCastsType1",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Hyaline",
          "text": "Hyaline"
        },
        {
          "key": "Granular",
          "text": "Granular"
        },
        {
          "key": "Epithelial",
          "text": "Epithelial"
        },
        {
          "key": "RBC",
          "text": "RBC"
        },
        {
          "key": "WBC",
          "text": "WBC"
        },
        {
          "key": "Waxy",
          "text": "Waxy"
        },
        {
          "key": "Fatty",
          "text": "Fatty"
        }
      ],
      "tableColumn": "37",
      "fqn": "medLabUrinalysis.umicrocCastsType1"
    },
    {
      "elementKey": "label55-60",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-60"
    },
    {
      "elementKey": "umicrocCastsResults2",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "2",
          "text": "0-2"
        },
        {
          "key": "5",
          "text": "3-5"
        },
        {
          "key": "10",
          "text": "6-10"
        },
        {
          "key": "plus",
          "text": "10+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "38",
      "fqn": "medLabUrinalysis.umicrocCastsResults2"
    },
    {
      "elementKey": "umicrocCastsType2",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Hyaline",
          "text": "Hyaline"
        },
        {
          "key": "Granular",
          "text": "Granular"
        },
        {
          "key": "Epithelial",
          "text": "Epithelial"
        },
        {
          "key": "RBC",
          "text": "RBC"
        },
        {
          "key": "WBC",
          "text": "WBC"
        },
        {
          "key": "Waxy",
          "text": "Waxy"
        },
        {
          "key": "Fatty",
          "text": "Fatty"
        }
      ],
      "tableColumn": "39",
      "fqn": "medLabUrinalysis.umicrocCastsType2"
    },
    {
      "elementKey": "label55-61",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-61"
    },
    {
      "elementKey": "umicrocCastsResults3",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "2",
          "text": "0-2"
        },
        {
          "key": "5",
          "text": "3-5"
        },
        {
          "key": "10",
          "text": "6-10"
        },
        {
          "key": "plus",
          "text": "10+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "40",
      "fqn": "medLabUrinalysis.umicrocCastsResults3"
    },
    {
      "elementKey": "umicrocCastsType3",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Hyaline",
          "text": "Hyaline"
        },
        {
          "key": "Granular",
          "text": "Granular"
        },
        {
          "key": "Epithelial",
          "text": "Epithelial"
        },
        {
          "key": "RBC",
          "text": "RBC"
        },
        {
          "key": "WBC",
          "text": "WBC"
        },
        {
          "key": "Waxy",
          "text": "Waxy"
        },
        {
          "key": "Fatty",
          "text": "Fatty"
        }
      ],
      "tableColumn": "41",
      "fqn": "medLabUrinalysis.umicrocCastsType3"
    },
    {
      "elementKey": "label55-62",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-62"
    },
    {
      "elementKey": "umicrocCastsResults4",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "2",
          "text": "0-2"
        },
        {
          "key": "5",
          "text": "3-5"
        },
        {
          "key": "10",
          "text": "6-10"
        },
        {
          "key": "plus",
          "text": "10+"
        },
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "42",
      "fqn": "medLabUrinalysis.umicrocCastsResults4"
    },
    {
      "elementKey": "umicrocCastsType4",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Hyaline",
          "text": "Hyaline"
        },
        {
          "key": "Granular",
          "text": "Granular"
        },
        {
          "key": "Epithelial",
          "text": "Epithelial"
        },
        {
          "key": "RBC",
          "text": "RBC"
        },
        {
          "key": "WBC",
          "text": "WBC"
        },
        {
          "key": "Waxy",
          "text": "Waxy"
        },
        {
          "key": "Fatty",
          "text": "Fatty"
        }
      ],
      "tableColumn": "43",
      "fqn": "medLabUrinalysis.umicrocCastsType4"
    },
    {
      "elementKey": "label55-63",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-63"
    },
    {
      "elementKey": "label55-64",
      "formIndex": "2",
      "formCss": "form-table-span-2-rows",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-64"
    },
    {
      "elementKey": "umicroCrystalsNonePresent",
      "formIndex": "2",
      "formCss": "form-table-span-3",
      "inputType": "checkbox",
      "tableColumn": "44",
      "fqn": "medLabUrinalysis.umicroCrystalsNonePresent"
    },
    {
      "elementKey": "umicroCrystalsPresent",
      "formIndex": "2",
      "inputType": "boxcheckset",
      "options": [
        {
          "key": "calciumOxalate",
          "text": "Calcium Oxalate"
        },
        {
          "key": "calciumCarbonate",
          "text": "Calcium carbonate"
        },
        {
          "key": "uricAcid",
          "text": "Uric Acid"
        },
        {
          "key": "calciumPhosphate",
          "text": "Calcium Phosphate"
        },
        {
          "key": "triplePhosphate",
          "text": "Triple Phosphate"
        },
        {
          "key": "amorphousUrates",
          "text": "Amorphous Urates"
        },
        {
          "key": "amorphousPhosphates",
          "text": "Amorphous Phosphates"
        },
        {
          "key": "cystine",
          "text": "Cystine"
        },
        {
          "key": "tyrosine",
          "text": "Tyrosine"
        },
        {
          "key": "cholesterol",
          "text": "Cholesterol"
        },
        {
          "key": "ammoniumBiurate",
          "text": "Ammonium Biurate"
        },
        {
          "key": "hippuricAcid",
          "text": "Hippuric Acid"
        },
        {
          "key": "bilirubin",
          "text": "Bilirubin"
        },
        {
          "key": "leucine",
          "text": "Leucine"
        },
        {
          "key": "sulfonamides",
          "text": "Sulfonamides"
        }
      ],
      "tableColumn": "45",
      "fqn": "medLabUrinalysis.umicroCrystalsPresent"
    },
    {
      "elementKey": "umicroCrystalsObscures",
      "formIndex": "2",
      "inputType": "boxcheckset",
      "options": [
        {
          "key": "calciumOxalate",
          "text": "Calcium Oxalate"
        },
        {
          "key": "calciumCarbonate",
          "text": "Calcium carbonate"
        },
        {
          "key": "uricAcid",
          "text": "Uric Acid"
        },
        {
          "key": "calciumPhosphate",
          "text": "Calcium Phosphate"
        },
        {
          "key": "triplePhosphate",
          "text": "Triple Phosphate"
        },
        {
          "key": "amorphousUrates",
          "text": "Amorphous Urates"
        },
        {
          "key": "amorphousPhosphates",
          "text": "Amorphous Phosphates"
        },
        {
          "key": "cystine",
          "text": "Cystine"
        },
        {
          "key": "tyrosine",
          "text": "Tyrosine"
        },
        {
          "key": "cholesterol",
          "text": "Cholesterol"
        },
        {
          "key": "ammoniumBiurate",
          "text": "Ammonium Biurate"
        },
        {
          "key": "hippuricAcid",
          "text": "Hippuric Acid"
        },
        {
          "key": "bilirubin",
          "text": "Bilirubin"
        },
        {
          "key": "leucine",
          "text": "Leucine"
        },
        {
          "key": "sulfonamides",
          "text": "Sulfonamides"
        }
      ],
      "tableColumn": "46",
      "fqn": "medLabUrinalysis.umicroCrystalsObscures"
    },
    {
      "elementKey": "label55-65",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-65"
    },
    {
      "elementKey": "label55-66",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-66"
    },
    {
      "elementKey": "umicroYeast",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "47",
      "fqn": "medLabUrinalysis.umicroYeast"
    },
    {
      "elementKey": "label55-67",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-67"
    },
    {
      "elementKey": "label55-68",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-68"
    },
    {
      "elementKey": "label55-69",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-69"
    },
    {
      "elementKey": "umicroBacteria",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "none",
          "text": "None seen"
        },
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "field",
          "text": "Obscures field"
        }
      ],
      "tableColumn": "48",
      "fqn": "medLabUrinalysis.umicroBacteria"
    },
    {
      "elementKey": "label55-70",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-70"
    },
    {
      "elementKey": "label55-71",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-71"
    },
    {
      "elementKey": "label55-72",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-72"
    },
    {
      "elementKey": "umicroOther",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "mucous",
          "text": "Mucous"
        },
        {
          "key": "sperm",
          "text": "Sperm"
        }
      ],
      "tableColumn": "49",
      "fqn": "medLabUrinalysis.umicroOther"
    },
    {
      "elementKey": "spacer14",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer14"
    },
    {
      "elementKey": "label55-73",
      "formIndex": "2",
      "inputType": "formTableText",
      "fqn": "medLabUrinalysis.label55-73"
    },
    {
      "elementKey": "urnInternal",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "50",
      "fqn": "medLabUrinalysis.urnInternal"
    },
    {
      "elementKey": "urnDiscCE",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "51",
      "fqn": "medLabUrinalysis.urnDiscCE"
    },
    {
      "elementKey": "urnInstructor2",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "52",
      "fqn": "medLabUrinalysis.urnInstructor2"
    },
    {
      "elementKey": "tableQcUrinalysis_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabUrinalysis.name"
    },
    {
      "elementKey": "tableQcUrinalysis_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableQcUrinalysis_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabUrinalysis.profession"
    },
    {
      "elementKey": "tableQcUrinalysis_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabUrinalysis.day"
    },
    {
      "elementKey": "tableQcUrinalysis_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabUrinalysis.time"
    },
    {
      "elementKey": "uqcManOrClinitek",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "radioset",
      "options": [
        {
          "key": "Manual",
          "text": "Manual"
        },
        {
          "key": "Clinitek",
          "text": "Clinitek"
        }
      ],
      "passToFunction": "[uqcQcType uqcAbnormalRefRangeGlucose  uqcAbnormalRefRangeBilirubin uqcAbnormalRefRangeKetone uqcAbnormalRefRangeBlood uqcAbnormalRefRangeProtein uqcAbnormalRefRangeLeukocytes]",
      "tableColumn": "2",
      "fqn": "medLabUrinalysis.uqcManOrClinitek"
    },
    {
      "elementKey": "uqcMultistixLotNum",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabUrinalysis.uqcMultistixLotNum"
    },
    {
      "elementKey": "uqcMultistixExp",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "medLabUrinalysis.uqcMultistixExp"
    },
    {
      "elementKey": "uqcQcType",
      "calculationType": "text",
      "formIndex": "1",
      "formCss": "form-table-span-7 form-table-center-content header-3",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": "Manual reading of QC using Multistix 10G"
        },
        {
          "key": "clinitek",
          "text": "Clinitek reading of QC using Multistix 10G"
        }
      ],
      "tableColumn": "5",
      "fqn": "medLabUrinalysis.uqcQcType"
    },
    {
      "elementKey": "label55-74",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-74"
    },
    {
      "elementKey": "label55-75",
      "formIndex": "1",
      "formCss": "form-table-span-3 form-table-center-content",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-75"
    },
    {
      "elementKey": "label55-76",
      "formIndex": "1",
      "formCss": "form-table-span-3 form-table-center-content",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-76"
    },
    {
      "elementKey": "label55-77",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-77"
    },
    {
      "elementKey": "uqcNormalLotNum",
      "formIndex": "1",
      "formCss": "form-table-span-3",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "medLabUrinalysis.uqcNormalLotNum"
    },
    {
      "elementKey": "uqcAbnormalLotNum",
      "formIndex": "1",
      "formCss": "form-table-span-3",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "medLabUrinalysis.uqcAbnormalLotNum"
    },
    {
      "elementKey": "label55-78",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-78"
    },
    {
      "elementKey": "uqcNormalExpDate",
      "formIndex": "1",
      "formCss": "form-table-span-3",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "medLabUrinalysis.uqcNormalExpDate"
    },
    {
      "elementKey": "uqcAbnormalExpDate",
      "formIndex": "1",
      "formCss": "form-table-span-3",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "medLabUrinalysis.uqcAbnormalExpDate"
    },
    {
      "elementKey": "spacer15",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer15"
    },
    {
      "elementKey": "label55-79",
      "formIndex": "1",
      "formCss": "form-table-span-2 form-table-center-content",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-79"
    },
    {
      "elementKey": "label55-80",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-80"
    },
    {
      "elementKey": "label55-81",
      "formIndex": "1",
      "formCss": "form-table-span-2 form-table-center-content",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-81"
    },
    {
      "elementKey": "label55-82",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-82"
    },
    {
      "elementKey": "spacer16",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer16"
    },
    {
      "elementKey": "label55-83",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-83"
    },
    {
      "elementKey": "label55-84",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-84"
    },
    {
      "elementKey": "spacer17",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer17"
    },
    {
      "elementKey": "label55-85",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-85"
    },
    {
      "elementKey": "label55-86",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-86"
    },
    {
      "elementKey": "spacer18",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "medLabUrinalysis.spacer18"
    },
    {
      "elementKey": "label55-87",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-87"
    },
    {
      "elementKey": "uqcNormalRunGlucose",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "medLabUrinalysis.uqcNormalRunGlucose"
    },
    {
      "elementKey": "uqcNormalRepeatGlucose",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "medLabUrinalysis.uqcNormalRepeatGlucose"
    },
    {
      "elementKey": "label55-88",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-88"
    },
    {
      "elementKey": "uqcAbnormalRunGlucose",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "medLabUrinalysis.uqcAbnormalRunGlucose"
    },
    {
      "elementKey": "uqcAbnormalRepeatGlucose",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatGlucose"
    },
    {
      "elementKey": "uqcAbnormalRefRangeGlucose",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": ">= 5.5 mmol/L"
        },
        {
          "key": "clinitek",
          "text": ">= 3 mmol/L"
        }
      ],
      "fqn": "medLabUrinalysis.uqcAbnormalRefRangeGlucose"
    },
    {
      "elementKey": "label55-89",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-89"
    },
    {
      "elementKey": "uqcNormalRunBilirubin",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "medLabUrinalysis.uqcNormalRunBilirubin"
    },
    {
      "elementKey": "uqcNormalRepeatBilirubin",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "medLabUrinalysis.uqcNormalRepeatBilirubin"
    },
    {
      "elementKey": "label55-90",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-90"
    },
    {
      "elementKey": "uqcAbnormalRunBilirubin",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "medLabUrinalysis.uqcAbnormalRunBilirubin"
    },
    {
      "elementKey": "uqcAbnormalRepeatBilirubin",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatBilirubin"
    },
    {
      "elementKey": "uqcAbnormalRefRangeBilirubin",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": "Small to Large"
        },
        {
          "key": "clinitek",
          "text": ">= Small"
        }
      ],
      "fqn": "medLabUrinalysis.uqcAbnormalRefRangeBilirubin"
    },
    {
      "elementKey": "label55-91",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-91"
    },
    {
      "elementKey": "uqcNormalRunKetone",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "medLabUrinalysis.uqcNormalRunKetone"
    },
    {
      "elementKey": "uqcNormalRepeatKetone",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "medLabUrinalysis.uqcNormalRepeatKetone"
    },
    {
      "elementKey": "label55-92",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-92"
    },
    {
      "elementKey": "uqcAbnormalRunKetone",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "medLabUrinalysis.uqcAbnormalRunKetone"
    },
    {
      "elementKey": "uqcAbnormalRepeatKetone",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatKetone"
    },
    {
      "elementKey": "uqcAbnormalRefRangeKetone",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": ">= 0.5 mmol/L"
        },
        {
          "key": "clinitek",
          "text": ">= trace mmol/L"
        }
      ],
      "fqn": "medLabUrinalysis.uqcAbnormalRefRangeKetone"
    },
    {
      "elementKey": "label55-93",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-93"
    },
    {
      "elementKey": "uqcNormalRunSpecific Gravity",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "22",
      "fqn": "medLabUrinalysis.uqcNormalRunSpecific Gravity"
    },
    {
      "elementKey": "uqcNormalRepeatSpecific Gravity",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "23",
      "fqn": "medLabUrinalysis.uqcNormalRepeatSpecific Gravity"
    },
    {
      "elementKey": "label55-94",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-94"
    },
    {
      "elementKey": "uqcAbnormalRunSpecific Gravity",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "medLabUrinalysis.uqcAbnormalRunSpecific Gravity"
    },
    {
      "elementKey": "uqcAbnormalRepeatSpecific Gravity",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatSpecific Gravity"
    },
    {
      "elementKey": "label55-95",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-95"
    },
    {
      "elementKey": "label55-96",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-96"
    },
    {
      "elementKey": "uqcNormalRunBlood",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "medLabUrinalysis.uqcNormalRunBlood"
    },
    {
      "elementKey": "uqcNormalRepeatBlood",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "27",
      "fqn": "medLabUrinalysis.uqcNormalRepeatBlood"
    },
    {
      "elementKey": "label55-97",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-97"
    },
    {
      "elementKey": "uqcAbnormalRunBlood",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "28",
      "fqn": "medLabUrinalysis.uqcAbnormalRunBlood"
    },
    {
      "elementKey": "uqcAbnormalRepeatBlood",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "29",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatBlood"
    },
    {
      "elementKey": "uqcAbnormalRefRangeBlood",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": ">= Small"
        },
        {
          "key": "clinitek",
          "text": ">= 25 Ery/μL"
        }
      ],
      "fqn": "medLabUrinalysis.uqcAbnormalRefRangeBlood"
    },
    {
      "elementKey": "label55-98",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-98"
    },
    {
      "elementKey": "uqcNormalRunpH",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "30",
      "fqn": "medLabUrinalysis.uqcNormalRunpH"
    },
    {
      "elementKey": "uqcNormalRepeatpH",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "31",
      "fqn": "medLabUrinalysis.uqcNormalRepeatpH"
    },
    {
      "elementKey": "label55-99",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-99"
    },
    {
      "elementKey": "uqcAbnormalRunpH",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "32",
      "fqn": "medLabUrinalysis.uqcAbnormalRunpH"
    },
    {
      "elementKey": "uqcAbnormalRepeatpH",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "33",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatpH"
    },
    {
      "elementKey": "label55-100",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-100"
    },
    {
      "elementKey": "label55-101",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-101"
    },
    {
      "elementKey": "uqcNormalRunProtein",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "34",
      "fqn": "medLabUrinalysis.uqcNormalRunProtein"
    },
    {
      "elementKey": "uqcNormalRepeatProtein",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "35",
      "fqn": "medLabUrinalysis.uqcNormalRepeatProtein"
    },
    {
      "elementKey": "label55-102",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-102"
    },
    {
      "elementKey": "uqcAbnormalRunProtein",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "36",
      "fqn": "medLabUrinalysis.uqcAbnormalRunProtein"
    },
    {
      "elementKey": "uqcAbnormalRepeatProtein",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "37",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatProtein"
    },
    {
      "elementKey": "uqcAbnormalRefRangeProtein",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": ">= 0.3 g/L"
        },
        {
          "key": "clinitek",
          "text": ">= 1.0 g/L"
        }
      ],
      "fqn": "medLabUrinalysis.uqcAbnormalRefRangeProtein"
    },
    {
      "elementKey": "label55-103",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-103"
    },
    {
      "elementKey": "uqcNormalRunUrobilinogen",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "38",
      "fqn": "medLabUrinalysis.uqcNormalRunUrobilinogen"
    },
    {
      "elementKey": "uqcNormalRepeatUrobilinogen",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "39",
      "fqn": "medLabUrinalysis.uqcNormalRepeatUrobilinogen"
    },
    {
      "elementKey": "label55-104",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-104"
    },
    {
      "elementKey": "uqcAbnormalRunUrobilinogen",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "40",
      "fqn": "medLabUrinalysis.uqcAbnormalRunUrobilinogen"
    },
    {
      "elementKey": "uqcAbnormalRepeatUrobilinogen",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "41",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatUrobilinogen"
    },
    {
      "elementKey": "label55-105",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-105"
    },
    {
      "elementKey": "label55-106",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-106"
    },
    {
      "elementKey": "uqcNormalRunNitrite",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "42",
      "fqn": "medLabUrinalysis.uqcNormalRunNitrite"
    },
    {
      "elementKey": "uqcNormalRepeatNitrite",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "43",
      "fqn": "medLabUrinalysis.uqcNormalRepeatNitrite"
    },
    {
      "elementKey": "label55-107",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-107"
    },
    {
      "elementKey": "uqcAbnormalRunNitrite",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "44",
      "fqn": "medLabUrinalysis.uqcAbnormalRunNitrite"
    },
    {
      "elementKey": "uqcAbnormalRepeatNitrite",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "45",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatNitrite"
    },
    {
      "elementKey": "label55-108",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-108"
    },
    {
      "elementKey": "label55-109",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-109"
    },
    {
      "elementKey": "uqcNormalRunLeukocytes",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "46",
      "fqn": "medLabUrinalysis.uqcNormalRunLeukocytes"
    },
    {
      "elementKey": "uqcNormalRepeatLeukocytes",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "47",
      "fqn": "medLabUrinalysis.uqcNormalRepeatLeukocytes"
    },
    {
      "elementKey": "label55-110",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-110"
    },
    {
      "elementKey": "uqcAbnormalRunLeukocytes",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "48",
      "fqn": "medLabUrinalysis.uqcAbnormalRunLeukocytes"
    },
    {
      "elementKey": "uqcAbnormalRepeatLeukocytes",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "49",
      "fqn": "medLabUrinalysis.uqcAbnormalRepeatLeukocytes"
    },
    {
      "elementKey": "uqcAbnormalRefRangeLeukocytes",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "manual",
          "text": ">= Small"
        },
        {
          "key": "clinitek",
          "text": ">= 70 Leu/μL"
        }
      ],
      "fqn": "medLabUrinalysis.uqcAbnormalRefRangeLeukocytes"
    },
    {
      "elementKey": "uqcPerformedBy",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "50",
      "fqn": "medLabUrinalysis.uqcPerformedBy"
    },
    {
      "elementKey": "label55-111",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-111"
    },
    {
      "elementKey": "uqcSomeQcUnacceptable",
      "formIndex": "1",
      "formCss": "uqcSomeQcUnacceptable",
      "inputType": "radioset",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "51",
      "fqn": "medLabUrinalysis.uqcSomeQcUnacceptable"
    },
    {
      "elementKey": "label55-112",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-112"
    },
    {
      "elementKey": "label55-113",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-113"
    },
    {
      "elementKey": "label55-114",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "medLabUrinalysis.label55-114"
    },
    {
      "elementKey": "uqcPendAnal_1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "52",
      "fqn": "medLabUrinalysis.uqcPendAnal_1"
    },
    {
      "elementKey": "uqcPendResult_1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "53",
      "fqn": "medLabUrinalysis.uqcPendResult_1"
    },
    {
      "elementKey": "uqcPendAnal_2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "54",
      "fqn": "medLabUrinalysis.uqcPendAnal_2"
    },
    {
      "elementKey": "uqcPendResult_2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "55",
      "fqn": "medLabUrinalysis.uqcPendResult_2"
    },
    {
      "elementKey": "uqcPendAnal_3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "56",
      "fqn": "medLabUrinalysis.uqcPendAnal_3"
    },
    {
      "elementKey": "uqcPendResult_3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "57",
      "fqn": "medLabUrinalysis.uqcPendResult_3"
    },
    {
      "elementKey": "uqcPendAnal_4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "58",
      "fqn": "medLabUrinalysis.uqcPendAnal_4"
    },
    {
      "elementKey": "uqcPendResult_4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "59",
      "fqn": "medLabUrinalysis.uqcPendResult_4"
    }
  ],
  "pageElements": {
    "tableQcUrinalysis": {
      "elementKey": "tableQcUrinalysis",
      "pageElementIndex": "1",
      "pageKey": "medLabUrinalysis",
      "tableKey": "tableQcUrinalysis",
      "isTable": true,
      "form": {
        "elementKey": "tableQcUrinalysis",
        "label": "Urinalysis QC",
        "addButtonText": "Create a urinalysis QC report",
        "formKey": "tableQcUrinalysis",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabUrinalysis.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableQcUrinalysis_name",
              "tableQcUrinalysis_profession",
              "tableQcUrinalysis_day",
              "tableQcUrinalysis_time"
            ]
          },
          {
            "elementKey": "cGroup46-2",
            "formCss": "grid-left-to-right-2",
            "fqn": "medLabUrinalysis.cGroup46-2",
            "gIndex": "2",
            "gChildren": [
              "uqcManOrClinitek",
              "uqcMultistixLotNum",
              "uqcMultistixExp"
            ]
          },
          {
            "elementKey": "cGroup46-3",
            "formCss": "med-lab-urin-qc-table form-table-group",
            "fqn": "medLabUrinalysis.cGroup46-3",
            "gIndex": "3",
            "gChildren": [
              "uqcQcType",
              "label55-74",
              "label55-75",
              "label55-76",
              "label55-77",
              "uqcNormalLotNum",
              "uqcAbnormalLotNum",
              "label55-78",
              "uqcNormalExpDate",
              "uqcAbnormalExpDate",
              "spacer15",
              "label55-79",
              "label55-80",
              "label55-81",
              "label55-82",
              "spacer16",
              "label55-83",
              "label55-84",
              "spacer17",
              "label55-85",
              "label55-86",
              "spacer18",
              "label55-87",
              "uqcNormalRunGlucose",
              "uqcNormalRepeatGlucose",
              "label55-88",
              "uqcAbnormalRunGlucose",
              "uqcAbnormalRepeatGlucose",
              "uqcAbnormalRefRangeGlucose",
              "label55-89",
              "uqcNormalRunBilirubin",
              "uqcNormalRepeatBilirubin",
              "label55-90",
              "uqcAbnormalRunBilirubin",
              "uqcAbnormalRepeatBilirubin",
              "uqcAbnormalRefRangeBilirubin",
              "label55-91",
              "uqcNormalRunKetone",
              "uqcNormalRepeatKetone",
              "label55-92",
              "uqcAbnormalRunKetone",
              "uqcAbnormalRepeatKetone",
              "uqcAbnormalRefRangeKetone",
              "label55-93",
              "uqcNormalRunSpecific Gravity",
              "uqcNormalRepeatSpecific Gravity",
              "label55-94",
              "uqcAbnormalRunSpecific Gravity",
              "uqcAbnormalRepeatSpecific Gravity",
              "label55-95",
              "label55-96",
              "uqcNormalRunBlood",
              "uqcNormalRepeatBlood",
              "label55-97",
              "uqcAbnormalRunBlood",
              "uqcAbnormalRepeatBlood",
              "uqcAbnormalRefRangeBlood",
              "label55-98",
              "uqcNormalRunpH",
              "uqcNormalRepeatpH",
              "label55-99",
              "uqcAbnormalRunpH",
              "uqcAbnormalRepeatpH",
              "label55-100",
              "label55-101",
              "uqcNormalRunProtein",
              "uqcNormalRepeatProtein",
              "label55-102",
              "uqcAbnormalRunProtein",
              "uqcAbnormalRepeatProtein",
              "uqcAbnormalRefRangeProtein",
              "label55-103",
              "uqcNormalRunUrobilinogen",
              "uqcNormalRepeatUrobilinogen",
              "label55-104",
              "uqcAbnormalRunUrobilinogen",
              "uqcAbnormalRepeatUrobilinogen",
              "label55-105",
              "label55-106",
              "uqcNormalRunNitrite",
              "uqcNormalRepeatNitrite",
              "label55-107",
              "uqcAbnormalRunNitrite",
              "uqcAbnormalRepeatNitrite",
              "label55-108",
              "label55-109",
              "uqcNormalRunLeukocytes",
              "uqcNormalRepeatLeukocytes",
              "label55-110",
              "uqcAbnormalRunLeukocytes",
              "uqcAbnormalRepeatLeukocytes",
              "uqcAbnormalRefRangeLeukocytes"
            ]
          },
          {
            "elementKey": "cGroup46-4",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabUrinalysis.cGroup46-4",
            "gIndex": "4",
            "gChildren": [
              "uqcPerformedBy",
              "label55-111",
              "uqcSomeQcUnacceptable"
            ]
          },
          {
            "elementKey": "cGroup46-5",
            "formCss": "med-lab-urin-qc-pending-table form-table-group",
            "fqn": "medLabUrinalysis.cGroup46-5",
            "gIndex": "5",
            "gChildren": [
              "label55-112",
              "label55-113",
              "label55-114",
              "uqcPendAnal_1",
              "uqcPendResult_1",
              "uqcPendAnal_2",
              "uqcPendResult_2",
              "uqcPendAnal_3",
              "uqcPendResult_3",
              "uqcPendAnal_4",
              "uqcPendResult_4"
            ]
          }
        ],
        "ehr_data": {
          "tableQcUrinalysis_name": "",
          "tableQcUrinalysis_profession": "",
          "tableQcUrinalysis_day": "",
          "tableQcUrinalysis_time": "",
          "uqcManOrClinitek": "",
          "uqcMultistixLotNum": "",
          "uqcMultistixExp": "",
          "uqcQcType": "",
          "uqcNormalLotNum": "",
          "uqcAbnormalLotNum": "",
          "uqcNormalExpDate": "",
          "uqcAbnormalExpDate": "",
          "uqcNormalRunGlucose": "",
          "uqcNormalRepeatGlucose": "",
          "uqcAbnormalRunGlucose": "",
          "uqcAbnormalRepeatGlucose": "",
          "uqcAbnormalRefRangeGlucose": "",
          "uqcNormalRunBilirubin": "",
          "uqcNormalRepeatBilirubin": "",
          "uqcAbnormalRunBilirubin": "",
          "uqcAbnormalRepeatBilirubin": "",
          "uqcAbnormalRefRangeBilirubin": "",
          "uqcNormalRunKetone": "",
          "uqcNormalRepeatKetone": "",
          "uqcAbnormalRunKetone": "",
          "uqcAbnormalRepeatKetone": "",
          "uqcAbnormalRefRangeKetone": "",
          "uqcNormalRunSpecific Gravity": "",
          "uqcNormalRepeatSpecific Gravity": "",
          "uqcAbnormalRunSpecific Gravity": "",
          "uqcAbnormalRepeatSpecific Gravity": "",
          "uqcNormalRunBlood": "",
          "uqcNormalRepeatBlood": "",
          "uqcAbnormalRunBlood": "",
          "uqcAbnormalRepeatBlood": "",
          "uqcAbnormalRefRangeBlood": "",
          "uqcNormalRunpH": "",
          "uqcNormalRepeatpH": "",
          "uqcAbnormalRunpH": "",
          "uqcAbnormalRepeatpH": "",
          "uqcNormalRunProtein": "",
          "uqcNormalRepeatProtein": "",
          "uqcAbnormalRunProtein": "",
          "uqcAbnormalRepeatProtein": "",
          "uqcAbnormalRefRangeProtein": "",
          "uqcNormalRunUrobilinogen": "",
          "uqcNormalRepeatUrobilinogen": "",
          "uqcAbnormalRunUrobilinogen": "",
          "uqcAbnormalRepeatUrobilinogen": "",
          "uqcNormalRunNitrite": "",
          "uqcNormalRepeatNitrite": "",
          "uqcAbnormalRunNitrite": "",
          "uqcAbnormalRepeatNitrite": "",
          "uqcNormalRunLeukocytes": "",
          "uqcNormalRepeatLeukocytes": "",
          "uqcAbnormalRunLeukocytes": "",
          "uqcAbnormalRepeatLeukocytes": "",
          "uqcAbnormalRefRangeLeukocytes": "",
          "uqcPerformedBy": "",
          "uqcSomeQcUnacceptable": "",
          "uqcPendAnal_1": "",
          "uqcPendResult_1": "",
          "uqcPendAnal_2": "",
          "uqcPendResult_2": "",
          "uqcPendAnal_3": "",
          "uqcPendResult_3": "",
          "uqcPendAnal_4": "",
          "uqcPendResult_4": ""
        }
      },
      "fqn": "medLabUrinalysis.tableQcUrinalysis",
      "tableChildren": [
        "tableQcUrinalysis_id",
        "tableQcUrinalysis_day",
        "tableQcUrinalysis_time",
        "uqcManOrClinitek",
        "uqcMultistixLotNum",
        "uqcMultistixExp",
        "uqcQcType",
        "uqcNormalLotNum",
        "uqcAbnormalLotNum",
        "uqcNormalExpDate",
        "uqcAbnormalExpDate",
        "uqcNormalRunGlucose",
        "uqcNormalRepeatGlucose",
        "uqcAbnormalRunGlucose",
        "uqcAbnormalRepeatGlucose",
        "uqcNormalRunBilirubin",
        "uqcNormalRepeatBilirubin",
        "uqcAbnormalRunBilirubin",
        "uqcAbnormalRepeatBilirubin",
        "uqcNormalRunKetone",
        "uqcNormalRepeatKetone",
        "uqcAbnormalRunKetone",
        "uqcAbnormalRepeatKetone",
        "uqcNormalRunSpecific Gravity",
        "uqcNormalRepeatSpecific Gravity",
        "uqcAbnormalRunSpecific Gravity",
        "uqcAbnormalRepeatSpecific Gravity",
        "uqcNormalRunBlood",
        "uqcNormalRepeatBlood",
        "uqcAbnormalRunBlood",
        "uqcAbnormalRepeatBlood",
        "uqcNormalRunpH",
        "uqcNormalRepeatpH",
        "uqcAbnormalRunpH",
        "uqcAbnormalRepeatpH",
        "uqcNormalRunProtein",
        "uqcNormalRepeatProtein",
        "uqcAbnormalRunProtein",
        "uqcAbnormalRepeatProtein",
        "uqcNormalRunUrobilinogen",
        "uqcNormalRepeatUrobilinogen",
        "uqcAbnormalRunUrobilinogen",
        "uqcAbnormalRepeatUrobilinogen",
        "uqcNormalRunNitrite",
        "uqcNormalRepeatNitrite",
        "uqcAbnormalRunNitrite",
        "uqcAbnormalRepeatNitrite",
        "uqcNormalRunLeukocytes",
        "uqcNormalRepeatLeukocytes",
        "uqcAbnormalRunLeukocytes",
        "uqcAbnormalRepeatLeukocytes",
        "uqcPerformedBy",
        "uqcSomeQcUnacceptable",
        "uqcPendAnal_1",
        "uqcPendResult_1",
        "uqcPendAnal_2",
        "uqcPendResult_2",
        "uqcPendAnal_3",
        "uqcPendResult_3",
        "uqcPendAnal_4",
        "uqcPendResult_4",
        "tableQcUrinalysis_name",
        "tableQcUrinalysis_profession"
      ],
      "hasRecHeader": true
    },
    "tableUrinalysis": {
      "elementKey": "tableUrinalysis",
      "pageElementIndex": "2",
      "pageKey": "medLabUrinalysis",
      "tableKey": "tableUrinalysis",
      "isTable": true,
      "form": {
        "elementKey": "tableUrinalysis",
        "label": "Urinalysis",
        "addButtonText": "Create a urinalysis report",
        "formKey": "tableUrinalysis",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabUrinalysis.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableUrinalysis_name",
              "tableUrinalysis_profession",
              "tableUrinalysis_day",
              "tableUrinalysis_time"
            ]
          },
          {
            "elementKey": "cGroup46-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabUrinalysis.cGroup46-2",
            "gIndex": "2",
            "gChildren": [
              "urinAccession",
              "urinCollDay",
              "urinCollTime",
              "urinOrderPhys"
            ]
          },
          {
            "elementKey": "cGroup46-3",
            "formCss": "grid-left-to-right-2",
            "fqn": "medLabUrinalysis.cGroup46-3",
            "gIndex": "3",
            "gChildren": [
              "urinTransparency",
              "urinColour"
            ]
          },
          {
            "elementKey": "cGroup46-4",
            "formCss": "urin-macro-exam form-table-group",
            "fqn": "medLabUrinalysis.cGroup46-4",
            "gIndex": "4",
            "gChildren": [
              "label55-25",
              "label55-26",
              "label55-27",
              "label55-28",
              "label55-29",
              "uchemManGlucose",
              "uchemAutoGlucose",
              "label55-30",
              "label55-31",
              "uchemManBilirubin",
              "uchemAutoBilirubin",
              "label55-32",
              "label55-33",
              "uchemManKetone",
              "uchemAutoKetone",
              "label55-34",
              "label55-35",
              "uchemManSpecificGravity",
              "uchemAutoSpecificGravity",
              "label55-36",
              "label55-37",
              "uchemManBlood",
              "uchemAutoBlood",
              "label55-38",
              "label55-39",
              "uchemManpH",
              "uchemAutopH",
              "label55-40",
              "label55-41",
              "uchemManProtein",
              "uchemAutoProtein",
              "label55-42",
              "label55-43",
              "uchemManUrobilinogen",
              "uchemAutoUrobilinogen",
              "label55-44",
              "label55-45",
              "uchemManNitrite",
              "uchemAutoNitrite",
              "label55-46",
              "label55-47",
              "uchemManLeukocytes",
              "uchemAutoLeukocytes",
              "label55-48"
            ]
          },
          {
            "elementKey": "cGroup46-5",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabUrinalysis.cGroup46-5",
            "gIndex": "5",
            "gChildren": [
              "uchemMicroIndicater",
              "urnDiscMA",
              "uchemInstructorlComment"
            ]
          },
          {
            "elementKey": "cGroup46-6",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabUrinalysis.cGroup46-6",
            "gIndex": "6",
            "gChildren": [
              "uchemSulfosalicylic",
              "uchemIcotest"
            ]
          },
          {
            "elementKey": "cGroup46-7",
            "formCss": "med-lab-urin-micro form-table-group",
            "fqn": "medLabUrinalysis.cGroup46-7",
            "gIndex": "7",
            "gChildren": [
              "label55-49",
              "label55-50",
              "label55-51",
              "label55-52",
              "label55-53",
              "umicroWBC",
              "spacer12",
              "label55-54",
              "label55-55",
              "umicroRBC",
              "spacer13",
              "label55-56",
              "label55-57",
              "umicroEpithelial",
              "umicroTypesEpithelial Cells",
              "label55-58",
              "label55-59",
              "umicrocCastsResults1",
              "umicrocCastsType1",
              "label55-60",
              "umicrocCastsResults2",
              "umicrocCastsType2",
              "label55-61",
              "umicrocCastsResults3",
              "umicrocCastsType3",
              "label55-62",
              "umicrocCastsResults4",
              "umicrocCastsType4",
              "label55-63",
              "label55-64",
              "umicroCrystalsNonePresent",
              "umicroCrystalsPresent",
              "umicroCrystalsObscures",
              "label55-65",
              "label55-66",
              "umicroYeast",
              "label55-67",
              "label55-68",
              "label55-69",
              "umicroBacteria",
              "label55-70",
              "label55-71",
              "label55-72",
              "umicroOther",
              "spacer14",
              "label55-73"
            ]
          },
          {
            "elementKey": "cGroup46-8",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabUrinalysis.cGroup46-8",
            "gIndex": "8",
            "gChildren": [
              "urnInternal",
              "urnDiscCE",
              "urnInstructor2"
            ]
          }
        ],
        "ehr_data": {
          "tableUrinalysis_name": "",
          "tableUrinalysis_profession": "",
          "tableUrinalysis_day": "",
          "tableUrinalysis_time": "",
          "urinAccession": "",
          "urinCollDay": "Today",
          "urinCollTime": "",
          "urinOrderPhys": "",
          "urinTransparency": "",
          "urinColour": "",
          "uchemManGlucose": "",
          "uchemAutoGlucose": "",
          "uchemManBilirubin": "",
          "uchemAutoBilirubin": "",
          "uchemManKetone": "",
          "uchemAutoKetone": "",
          "uchemManSpecificGravity": "",
          "uchemAutoSpecificGravity": "",
          "uchemManBlood": "",
          "uchemAutoBlood": "",
          "uchemManpH": "",
          "uchemAutopH": "",
          "uchemManProtein": "",
          "uchemAutoProtein": "",
          "uchemManUrobilinogen": "",
          "uchemAutoUrobilinogen": "",
          "uchemManNitrite": "",
          "uchemAutoNitrite": "",
          "uchemManLeukocytes": "",
          "uchemAutoLeukocytes": "",
          "uchemMicroIndicater": "TRUE",
          "urnDiscMA": "",
          "uchemInstructorlComment": "",
          "uchemSulfosalicylic": "",
          "uchemIcotest": "",
          "umicroWBC": "",
          "umicroRBC": "",
          "umicroEpithelial": "",
          "umicroTypesEpithelial Cells": "",
          "umicrocCastsResults1": "",
          "umicrocCastsType1": "",
          "umicrocCastsResults2": "",
          "umicrocCastsType2": "",
          "umicrocCastsResults3": "",
          "umicrocCastsType3": "",
          "umicrocCastsResults4": "",
          "umicrocCastsType4": "",
          "umicroCrystalsNonePresent": "",
          "umicroCrystalsPresent": "",
          "umicroCrystalsObscures": "",
          "umicroYeast": "",
          "umicroBacteria": "",
          "umicroOther": "",
          "urnInternal": "",
          "urnDiscCE": "",
          "urnInstructor2": ""
        }
      },
      "fqn": "medLabUrinalysis.tableUrinalysis",
      "tableChildren": [
        "tableUrinalysis_id",
        "tableUrinalysis_day",
        "tableUrinalysis_time",
        "urinAccession",
        "urinCollDay",
        "urinCollTime",
        "urinOrderPhys",
        "urinTransparency",
        "urinColour",
        "uchemManGlucose",
        "uchemAutoGlucose",
        "uchemManBilirubin",
        "uchemAutoBilirubin",
        "uchemManKetone",
        "uchemAutoKetone",
        "uchemManSpecificGravity",
        "uchemAutoSpecificGravity",
        "uchemManBlood",
        "uchemAutoBlood",
        "uchemManpH",
        "uchemAutopH",
        "uchemManProtein",
        "uchemAutoProtein",
        "uchemManUrobilinogen",
        "uchemAutoUrobilinogen",
        "uchemManNitrite",
        "uchemAutoNitrite",
        "uchemManLeukocytes",
        "uchemAutoLeukocytes",
        "uchemMicroIndicater",
        "urnDiscMA",
        "uchemInstructorlComment",
        "uchemSulfosalicylic",
        "uchemIcotest",
        "umicroWBC",
        "umicroRBC",
        "umicroEpithelial",
        "umicroTypesEpithelial Cells",
        "umicrocCastsResults1",
        "umicrocCastsType1",
        "umicrocCastsResults2",
        "umicrocCastsType2",
        "umicrocCastsResults3",
        "umicrocCastsType3",
        "umicrocCastsResults4",
        "umicrocCastsType4",
        "umicroCrystalsNonePresent",
        "umicroCrystalsPresent",
        "umicroCrystalsObscures",
        "umicroYeast",
        "umicroBacteria",
        "umicroOther",
        "urnInternal",
        "urnDiscCE",
        "urnInstructor2",
        "tableUrinalysis_name",
        "tableUrinalysis_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS