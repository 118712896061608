/* eslint-disable */
const DEFS = {
  "pageDataKey": "medicalImaging",
  "pIndex": "33",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medicalImaging.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medicalImaging.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medicalImaging.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medicalImaging.time"
    },
    {
      "elementKey": "mImg_type",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "angio",
          "text": "Angiogram"
        },
        {
          "key": "ecg",
          "text": "Echocardiogram"
        },
        {
          "key": "ekg",
          "text": "Electrocardiogram"
        },
        {
          "key": "ct",
          "text": "CT scan"
        },
        {
          "key": "mri",
          "text": "MRI"
        },
        {
          "key": "nuclear",
          "text": "Nuclear medicine"
        },
        {
          "key": "ultra",
          "text": "Ultrasound"
        },
        {
          "key": "xray",
          "text": "X-ray"
        }
      ],
      "tableColumn": "1",
      "fqn": "medicalImaging.mImg_type"
    },
    {
      "elementKey": "mImg_Location",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "ab",
          "text": "Abdomen"
        },
        {
          "key": "armL",
          "text": "Arm left"
        },
        {
          "key": "armR",
          "text": "Arm right"
        },
        {
          "key": "chest",
          "text": "Chest"
        },
        {
          "key": "head",
          "text": "Head"
        },
        {
          "key": "heart",
          "text": "Heart"
        },
        {
          "key": "neck",
          "text": "Neck"
        },
        {
          "key": "legL",
          "text": "Leg left"
        },
        {
          "key": "legR",
          "text": "Leg right"
        },
        {
          "key": "pelvis",
          "text": "Pelvis"
        }
      ],
      "tableColumn": "2",
      "fqn": "medicalImaging.mImg_Location"
    },
    {
      "elementKey": "mImg_history",
      "formIndex": "1",
      "formCss": "form_label_15",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "medicalImaging.mImg_history"
    },
    {
      "elementKey": "mImg_comparision",
      "formIndex": "1",
      "formCss": "form_label_15",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "medicalImaging.mImg_comparision"
    },
    {
      "elementKey": "mImg_technique",
      "formIndex": "1",
      "formCss": "form_label_15",
      "inputType": "textarea",
      "tableColumn": "5",
      "fqn": "medicalImaging.mImg_technique"
    },
    {
      "elementKey": "mImg_findings",
      "formIndex": "1",
      "formCss": "form_label_15",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "medicalImaging.mImg_findings"
    },
    {
      "elementKey": "mImg_impression",
      "formIndex": "1",
      "formCss": "form_label_15",
      "inputType": "textarea",
      "tableColumn": "7",
      "fqn": "medicalImaging.mImg_impression"
    },
    {
      "elementKey": "mImg_recommendation",
      "formIndex": "1",
      "formCss": "form_label_15",
      "inputType": "textarea",
      "tableColumn": "8",
      "fqn": "medicalImaging.mImg_recommendation"
    },
    {
      "elementKey": "laboratoryReport",
      "formIndex": "1",
      "inputType": "ehrFile",
      "tableColumn": "9",
      "fqn": "medicalImaging.laboratoryReport"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "10",
      "fqn": "medicalImaging.comments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "medicalImaging",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Medical imaging",
        "addButtonText": "Add a report or image",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medicalImaging.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup33-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medicalImaging.cGroup33-2",
            "gIndex": "2",
            "gChildren": [
              "mImg_type",
              "mImg_Location"
            ]
          },
          {
            "elementKey": "cGroup33-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "medicalImaging.cGroup33-3",
            "gIndex": "3",
            "gChildren": [
              "mImg_history",
              "mImg_comparision",
              "mImg_technique",
              "mImg_findings",
              "mImg_impression",
              "mImg_recommendation"
            ]
          },
          {
            "elementKey": "cGroup33-4",
            "formCss": "grid-left-to-right-1",
            "fqn": "medicalImaging.cGroup33-4",
            "gIndex": "4",
            "gChildren": [
              "laboratoryReport",
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "mImg_type": "",
          "mImg_Location": "",
          "mImg_history": "",
          "mImg_comparision": "",
          "mImg_technique": "",
          "mImg_findings": "",
          "mImg_impression": "",
          "mImg_recommendation": "",
          "laboratoryReport": "",
          "comments": ""
        }
      },
      "fqn": "medicalImaging.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "mImg_type",
        "mImg_Location",
        "mImg_history",
        "mImg_comparision",
        "mImg_technique",
        "mImg_findings",
        "mImg_impression",
        "mImg_recommendation",
        "laboratoryReport",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS