/* eslint-disable */
const DEFS = {
  "pageDataKey": "checklistsPage",
  "pIndex": "57",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "PersonalCareChecklist_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "checklistsPage.name"
    },
    {
      "elementKey": "PersonalCareChecklist_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "PersonalCareChecklist_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "checklistsPage.profession"
    },
    {
      "elementKey": "PersonalCareChecklist_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "checklistsPage.day"
    },
    {
      "elementKey": "PersonalCareChecklist_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "checklistsPage.time"
    },
    {
      "elementKey": "pccChecklistForm",
      "formIndex": "1",
      "formOption": "personal-care",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "checklistsPage.pccChecklistForm"
    },
    {
      "elementKey": "preopChecklistTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "checklistsPage.name"
    },
    {
      "elementKey": "preopChecklistTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "preopChecklistTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "checklistsPage.profession"
    },
    {
      "elementKey": "preopChecklistTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "checklistsPage.day"
    },
    {
      "elementKey": "preopChecklistTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "checklistsPage.time"
    },
    {
      "elementKey": "preopChecklistForm",
      "formIndex": "2",
      "formOption": "preop-checklist",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "checklistsPage.preopChecklistForm"
    }
  ],
  "pageElements": {
    "PersonalCareChecklist": {
      "elementKey": "PersonalCareChecklist",
      "pageElementIndex": "1",
      "pageKey": "checklistsPage",
      "tableKey": "PersonalCareChecklist",
      "isTable": true,
      "form": {
        "elementKey": "PersonalCareChecklist",
        "label": "Personal Care",
        "addButtonText": "Add a personal care checklist",
        "formOption": "checkListForm",
        "formKey": "PersonalCareChecklist",
        "ehr_groups": [
          {
            "elementKey": "pccGroup1",
            "formCss": "record-header",
            "fqn": "checklistsPage.pccGroup1",
            "gIndex": "1",
            "gChildren": [
              "PersonalCareChecklist_name",
              "PersonalCareChecklist_profession",
              "PersonalCareChecklist_day",
              "PersonalCareChecklist_time"
            ]
          },
          {
            "elementKey": "pccGroup2",
            "formCss": "grid-left-to-right-1",
            "fqn": "checklistsPage.pccGroup2",
            "gIndex": "2",
            "gChildren": [
              "pccChecklistForm"
            ]
          }
        ],
        "ehr_data": {
          "PersonalCareChecklist_name": "",
          "PersonalCareChecklist_profession": "",
          "PersonalCareChecklist_day": "",
          "PersonalCareChecklist_time": "",
          "pccChecklistForm": ""
        }
      },
      "fqn": "checklistsPage.PersonalCareChecklist",
      "tableChildren": [
        "PersonalCareChecklist_id",
        "PersonalCareChecklist_day",
        "PersonalCareChecklist_time",
        "pccChecklistForm",
        "PersonalCareChecklist_name",
        "PersonalCareChecklist_profession"
      ],
      "hasRecHeader": true
    },
    "preopChecklistTable": {
      "elementKey": "preopChecklistTable",
      "pageElementIndex": "2",
      "pageKey": "checklistsPage",
      "tableKey": "preopChecklistTable",
      "isTable": true,
      "form": {
        "elementKey": "preopChecklistTable",
        "label": "Pre-op Checklist",
        "addButtonText": "Add a pre-op checklist",
        "formOption": "checkListForm",
        "formKey": "preopChecklistTable",
        "ehr_groups": [
          {
            "elementKey": "cGroup48-1",
            "formCss": "record-header",
            "fqn": "checklistsPage.cGroup48-1",
            "gIndex": "1",
            "gChildren": [
              "preopChecklistTable_name",
              "preopChecklistTable_profession",
              "preopChecklistTable_day",
              "preopChecklistTable_time"
            ]
          },
          {
            "elementKey": "cGroup48-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "checklistsPage.cGroup48-2",
            "gIndex": "2",
            "gChildren": [
              "preopChecklistForm"
            ]
          }
        ],
        "ehr_data": {
          "preopChecklistTable_name": "",
          "preopChecklistTable_profession": "",
          "preopChecklistTable_day": "",
          "preopChecklistTable_time": "",
          "preopChecklistForm": ""
        }
      },
      "fqn": "checklistsPage.preopChecklistTable",
      "tableChildren": [
        "preopChecklistTable_id",
        "preopChecklistTable_day",
        "preopChecklistTable_time",
        "preopChecklistForm",
        "preopChecklistTable_name",
        "preopChecklistTable_profession"
      ],
      "hasRecHeader": true
    }
  }
}
export default DEFS